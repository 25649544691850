import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { saturate, desaturate, lighten } from 'polished';
import { compose } from 'ramda';

import { baseTheme } from './nesto';

import type { Theme as NestoThemeType } from './types';

const toneDisabled = compose(lighten(0.5), desaturate(0.5));

const brandColor = '#E31835';

const colors = {
    brand: brandColor,
    brandTextColor: brandColor,
    cardTextColor: '#fff',
    portGore: '#212243',
    white: '#FFFFFF',
    'accent-1': '#212243',
    'dark-1': '#212243',
    'gray-5': '#E3E3E3',
    'gray-4': '#F0F0F0',
    'gray-3': '#C9CACF',
    'gray-2': '#949599',
    'gray-1': '#343434',
    'light-2': '#f6f6f6',
    'neutral-3': '#3D66B0',
    highlight: 'rgba(66, 153, 225, 0.5)',
    overlay: 'rgba(13, 13, 26, 0.6)',
    'status-warning': '#F8CD46',
    'status-critical': '#FF4040',
    'status-error': '#FF4040',
    'status-ok': '#00C781',
    focus: 'transparent',
    text: {
        light: '#212243',
    },
    placeholder: '#9B9B9B',
    green: 'var(--color-green-500)',
};

const myTheme: NestoThemeType = {
    name: 'proprioDirect',
    whiteLabel: true,
    colors,
    logoPath: {
        en: `/co-branding/realtor_en.svg`,
        fr: `/co-branding/realtor_fr.svg`,
    },
    global: {
        colors,
    },
    // Grommet button styles
    button: {
        primary: { background: { color: brandColor } },
        hover: {
            primary: {
                extend: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    boxShadow: `0px 0px 0px 2px ${brandColor}`,
                },
            },
        },
    },
    // Starting here are not GROMMET theme spec
    // Nesto buttons styles
    buttons: {
        primary: {
            backgroundColor: brandColor,
            color: 'white',
            borderColor: brandColor,
            ':hover:not(.disabled), :focus:not(.disabled)': {
                backgroundColor: saturate(0.2, brandColor),
                borderColor: saturate(0.2, brandColor),
            },
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                backgroundColor: toneDisabled(brandColor),
                borderColor: toneDisabled(brandColor),
            },
        },
    },
};

export const theme = deepMerge<
    NestoThemeType,
    [NestoThemeType, NestoThemeType]
>(grommet, baseTheme, myTheme);

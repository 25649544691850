import type {
    GroupedDocumentsEntity,
    DocState,
    ApplicantDocument,
} from 'types/documents';

type Color = string;

// TBD I used the same color from FE broker on behalf
// this look like a 🍕 but it make it really easy to use
const brokerColorMap: Record<DocState | 'default', string> = {
    DOCUMENT_EMPTY: '#212243',
    DOCUMENT_RECEIVED: '#212243',
    DOCUMENT_BROKER_APPROVED: '#ffcc00',
    DOCUMENT_BROKER_UNAPPROVED: '#f9423a',
    DOCUMENT_LENDER_ACCEPTED: '#139A43',
    DOCUMENT_DISABLED: '#e3e3e3',
    default: '#212243',
};

export const getDocumentStateColor = (state: DocState | undefined): Color => {
    return brokerColorMap[state || 'default'];
};

export const countDocumentsBySection = (documents: GroupedDocumentsEntity) => {
    let approvedDocs = 0;
    let totalDocs = 0;
    let rejectedDocs = 0;
    Object.values(documents).forEach((documentsList) => {
        Object.values(documentsList).forEach((documentsSubcategoryList) => {
            approvedDocs += documentsSubcategoryList.filter((document) =>
                [
                    'DOCUMENT_BROKER_APPROVED',
                    'DOCUMENT_LENDER_ACCEPTED',
                ].includes(document.state)
            ).length;

            totalDocs += documentsSubcategoryList.filter(
                (document) => !['DOCUMENT_DISABLED'].includes(document.state)
            ).length;

            rejectedDocs += documentsSubcategoryList.filter(
                (document) => document.state === 'DOCUMENT_BROKER_UNAPPROVED'
            ).length;
        });
    });

    return {
        approvedDocs,
        totalDocs,
        rejectedDocs,
    };
};

export const countSubmittedDocumentsBySection = (documents) =>
    documents.flat().filter((document) => document.state !== 'DOCUMENT_EMPTY')
        .length;

export const countErrorDocumentsBySection = (documents) =>
    documents
        .flat()
        .filter((document) => document.state === 'DOCUMENT_BROKER_UNAPPROVED')
        .length;

// Checks the latest unarchived file in the document type for system verification status
export const documentTypeIsSystemVerified = (document: ApplicantDocument) => {
    const { systemVerificationAvailable, files } = document;

    if (!systemVerificationAvailable) {
        return false;
    }

    if (!files?.length) {
        return false;
    }

    // If the latest unarchived file is verified, display the system verified chip.
    const latestDocument = files.reduce((prevFile, currentFile) => {
        // If the file is archived, skip it
        if (currentFile.excludeAudit) {
            return prevFile;
        }

        const fileCreateDate = new Date(currentFile.created);

        // If the file is newer than the previous file, return it
        if (!prevFile || fileCreateDate > new Date(prevFile.created)) {
            return { ...currentFile, fileCreateDate };
        }

        return prevFile;
    }, null);

    // Check if latest document is system verified
    const isSystemVerified = !!latestDocument?.systemVerificationStatus;

    return isSystemVerified;
};

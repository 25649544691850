import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';

import { useAccount, useClient } from 'providers/auth0';

type UseAnalytics = {
    track: (event: string, context?: Record<string, unknown>) => void;
    addError: (error: string, context?: Record<string, unknown>) => void;
};

export const useAnalytics = (): UseAnalytics => {
    const { pathname, query } = useRouter();
    const { user } = useAuth0();
    //  if we catch an error early,
    // we don't have the account and client yet
    // e.g. failing in UserProvider before we get data from BE
    const { client } = useClient();
    const { account } = useAccount();

    const track = (event: string, context?: Record<string, unknown>) => {
        datadogRum.addAction(event, {
            page: {
                pathname,
                query,
            },
            user: {
                id: account?.id,
                rid: account?.rid,
                email: account?.email,
                firstName: account?.firstName,
                lastName: account?.lastName,
                partner: account?.partner,
            },
            client: {
                id: client?.id,
                rid: client?.rid,
                email: client?.email,
                firstName: client?.firstName,
                lastName: client?.lastName,
                partner: client?.partner,
            },
            ...context,
        });
    };

    const addError = (error: unknown, context?: Record<string, unknown>) => {
        datadogRum.addError(error, {
            trackedUser: user,
            trackedAccount: account,
            trackedClient: client,
            trackedRid: client?.rid,
            ...context,
        });
    };

    return { track, addError };
};

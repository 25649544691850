import React, { forwardRef, useRef, useState, useEffect } from 'react';

import css from '@styled-system/css';
import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { gutter, toastStates } from './toast';

import type { TransitionState, AppearanceTypes, Placement } from './toast';
import type { BoxProps } from 'reflexbox/styled-components';
import type { ResponsiveValue, BorderProps } from 'styled-system';
import type { Assign } from 'types/components';

interface ToastElementOwnProps extends BoxProps, BorderProps {
    placement: Placement;
    transitionDuration?: number;
    transitionState?: TransitionState;
    appearance?: ResponsiveValue<Appearance>;
}

export type ToastElementProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    ToastElementOwnProps
>;

type Appearance = AppearanceTypes;

export const ToastElementStyled = styled<any>(Box)`
    display: flex;
    margin-bottom: 8px;
    min-height: 70px;
    transition: ${({ transitionDuration }) =>
        `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`};
    ${({ placement, transitionState }) =>
        toastStates(placement)[transitionState]}

    ${variant({
        scale: 'toasts.element',
        prop: 'appearance',
    })}
`;

type ToastElementType = BoxProps & ToastElementProps;

export const ToastElement = forwardRef(
    (
        {
            appearance = 'info',
            placement,
            transitionDuration,
            transitionState,
            ...rest
        }: ToastElementType,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const [height, setHeight] = useState<number | string>('auto');
        const elementRef = useRef<any>(null);

        useEffect(() => {
            if (transitionState === 'entered') {
                const el = elementRef?.current;
                setHeight(el.offsetHeight + gutter);
            }
            if (transitionState === 'exiting') {
                setHeight(0);
            }
        }, [transitionState]);

        return (
            <Box
                ref={elementRef}
                style={{ height }}
                css={css({
                    transition: 'height ${transitionDuration - 100}ms 10000ms',
                })}
            >
                <ToastElementStyled
                    data-testid="toast-message"
                    appearance={appearance}
                    placement={placement}
                    transitionDuration={transitionDuration}
                    transitionState={transitionState}
                    ref={ref}
                    {...rest}
                />
            </Box>
        );
    }
);

import React from 'react';
import { PropsWithChildren } from 'react';

import styled, { css } from 'styled-components';

import { gutter } from './toast';

const placements = {
    'top-left': css`
        top: 0;
        left: 0;
    `,
    'top-center': css`
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    `,
    'top-right': css`
        top: 0;
        right: 0;
    `,
    'bottom-left': css`
        bottom: 0;
        left: 0;
    `,
    'bottom-center': css`
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    `,
    'bottom-right': css`
        bottom: 0;
        right: 0;
    `,
} as const;

type Placement = keyof typeof placements;

type StyledToastContainerProps = {
    placement: Placement;
    hasToasts: boolean;
};

const StyledToastContainer = styled.div<StyledToastContainerProps>`
    box-sizing: border-box;
    max-height: 100%;
    overflow: hidden;
    padding: ${gutter}px;
    pointer-events: ${({ hasToasts }: any) => (hasToasts ? null : 'none')};
    position: fixed;
    z-index: 3000;
    ${({ placement }) => placements[placement]}
`;

export const ToastContainer = ({
    placement = 'top-right',
    hasToasts,
    children,
    ...rest
}: PropsWithChildren<StyledToastContainerProps>) => {
    return (
        <StyledToastContainer
            placement={placement}
            hasToasts={hasToasts}
            {...rest}
        >
            {children}
        </StyledToastContainer>
    );
};

import dompurify from 'dompurify';

import { booleanNormalizer } from './normalizer';

import type { AmountFrequency, Frequency } from 'types/index';
import type {
    ApplicantsAmountFrequency,
    ApplicantsAmountFrequencyWithRatio,
} from 'types/qualification';

type fn = (x: any) => any;

export const compose =
    (...fns: fn[]) =>
    (x: any) =>
        fns.reduceRight((y, f) => f(y), x);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SanitizeType = (value: string | number | boolean | undefined) => any;

export function allowedProps<T = Record<string, unknown>>(
    allowed: string[],
    obj: Partial<T> = {},
    sanitize?: Record<string, SanitizeType>
): T {
    const allowedProps = {};

    for (const prop of allowed) {
        allowedProps[prop] = sanitize?.[prop]
            ? sanitize?.[prop](obj?.[prop])
            : obj?.[prop];
    }

    return allowedProps as T;
}

export const allowValue = <T = any>(
    allowed: readonly any[],
    value: any,
    defaultValue = undefined
): T | undefined => {
    const isIncluded = allowed.includes(value);

    return isIncluded ? value : defaultValue;
};

// FREQUENCY AMOUNT
// ANNUALLY => amount
// SEMIANNUALLY => amount * 2 // once every 6 months
// MONTHLY => amount * 12 // once every months
// SEMIMONTHLY => amount * 24 // twice a month
// BIWEEKLY => amount * 26 // every 2 weeks
// WEEKLY => amount * 52 // every weeks
type YearAmountFrequencyFunc = (amount: number) => number;
const yearAmountFrequencyFormulas: Record<Frequency, YearAmountFrequencyFunc> =
    {
        ANNUALLY: (amount: number) => amount,
        SEMIANNUALLY: (amount: number) => amount * 2,
        QUARTERLY: (amount: number) => amount * 4,
        MONTHLY: (amount: number) => amount * 12,
        SEMIMONTHLY: (amount: number) => amount * 24,
        BIWEEKLY: (amount: number) => amount * 26,
        ACCELERATED_BIWEEKLY: (amount: number) => amount * 24,
        WEEKLY: (amount: number) => amount * 52,
        ACCELERATED_WEEKLY: (amount: number) => amount * 48,
        DAILY: (amount: number) => amount * 365,
    };
/**
 * Get the amount value for a year on the freqiency
 * @param {number} amount - Amount value for frequency
 * @param {PaymentFrequency} frequency - Frency [ANNUALLY, SEMIANNUALLY, MONTHLY, SEMIMONTHLY, BIWEEKLY, WEEKLY]
 * @returns {number} Amount in dollar for a year base on freqncy
 */
export const getYearAmountFrequency = (
    amount: number = 0,
    frequency: Frequency = 'ANNUALLY'
) => {
    const getValue =
        yearAmountFrequencyFormulas?.[frequency] ||
        yearAmountFrequencyFormulas.ANNUALLY;

    return getValue(amount);
};

const mortgageFrequencyFormulas: Record<Frequency, YearAmountFrequencyFunc> = {
    ANNUALLY: (amount: number) => amount,
    SEMIANNUALLY: (amount: number) => amount / 2,
    QUARTERLY: (amount: number) => amount / 4,
    MONTHLY: (amount: number) => amount / 12,
    SEMIMONTHLY: (amount: number) => amount / 24,
    BIWEEKLY: (amount: number) => amount / 26,
    ACCELERATED_BIWEEKLY: (amount: number) => amount / 24,
    WEEKLY: (amount: number) => amount / 52,
    ACCELERATED_WEEKLY: (amount: number) => amount / 48,
    DAILY: (amount: number) => amount / 365,
};

/**
 * Get mortgage amount based on the frequency
 * @param {number} amountPerMonth - Amount value per year
 * @param {MortgageFrequency} frequency - Frequency ['MONTHLY', 'SEMIMONTHLY','BIWEEKLY', 'ACCELERATED_BIWEEKLY', 'WEEKLY', 'ACCELERATED_WEEKLY']
 * @returns {number} Amount in dollar per frequency
 */
export const getAmountPerFrequency = (
    amountPerYear: number,
    frequency: Frequency
): number => {
    const getValue =
        mortgageFrequencyFormulas[frequency] ||
        mortgageFrequencyFormulas.MONTHLY;

    // we use the amount calculated per year to get the right amount / frequency
    return +getValue(amountPerYear).toFixed(2);
};

export const amountFrequencyTo = <T extends AmountFrequency>(
    amountFrequency: T,
    frequency: Frequency
): T => {
    if (amountFrequency?.frequency === frequency) {
        return amountFrequency;
    }

    const amount = amountToFrequency(amountFrequency, frequency);

    return {
        ...amountFrequency,
        amount,
        frequency,
    };
};

export const amountFrequencyWithRationTo = <
    T extends ApplicantsAmountFrequency &
        Pick<ApplicantsAmountFrequencyWithRatio, 'ratioAmount'>,
>(
    amountFrequency: T,
    frequency: Frequency
): T => {
    if (amountFrequency.frequency === frequency) {
        return amountFrequency;
    }

    const amount = amountToFrequency(amountFrequency, frequency);
    const ratioAmount = amountToFrequency(
        {
            amount: amountFrequency.ratioAmount,
            frequency: amountFrequency.frequency,
        },
        frequency
    );

    return {
        ...amountFrequency,
        amount,
        ratioAmount,
        frequency,
    };
};

/**
 *
 * @param {AmountFrequency} amountFrequency - AmountFrequency object
 * @param {Frequency} frequency
 * @returns {number} Amount in dollar base on frequency
 */
export const amountToFrequency = (
    amountFrequency: AmountFrequency,
    frequency: Frequency
): number => {
    if (amountFrequency?.frequency === frequency) {
        return amountFrequency?.amount || 0;
    }

    const yearAmount = getYearAmountFrequency(
        amountFrequency?.amount,
        amountFrequency?.frequency
    );

    return getAmountPerFrequency(yearAmount, frequency);
};

export const hasValueIn = <T = string>(allowed: T[], valid: T[]) =>
    allowed.some((item) => valid.includes(item));

export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);

    if (filteredRefs.length <= 1) return filteredRefs[0];

    return (inst) => {
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') {
                ref(inst);
            } else if (ref) {
                ref.current = inst;
            }
        }
    };
};

export const sanitizer = dompurify.sanitize;

export const isBool = (value: any) =>
    value === true || value === false || value === 'true' || value === 'false';

export const getDefaultValue = (
    value: string | number | boolean | string[] | number[] | boolean[]
) => {
    return isBool(value) ? booleanNormalizer(value) : value || '';
};

export const getMonthsFromYearsMonths = ({
    years = 0,
    months = 0,
}: {
    years: number;
    months: number;
}) => {
    const yrsInMonths = years * 12;

    return yrsInMonths + months;
};

export enum SortByOrder {
    ASC = 1,
    // eslint-disable-next-line no-magic-numbers
    DESC = -1,
}

/**
 * Sort array of object by key
 *
 * @param arr - array to sort
 * @param key - key to sort by
 * @param order - order to sort by
 * @returns sorted array
 */
export const sortByKey = <T = unknown>(
    arr: T[],
    key: string,
    order: SortByOrder = SortByOrder.ASC
) => {
    return [...arr].sort(
        (s1: unknown, s2: unknown) =>
            order * String(s1[key]).localeCompare(String(s2[key]))
    );
};

import React from 'react';

function getDefaultProps(width, height = 16, noFill = false) {
    return {
        'aria-hidden': true,
        height,
        width,
        viewBox: `0 0 ${width} ${height}`,
        style: {
            display: 'inline-block',
            verticalAlign: 'text-top',
            fill: noFill ? 'none' : 'currentColor',
        },
    };
}

export const AlertIcon = (props) => (
    <svg {...getDefaultProps(24, 24)} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2963 1.29277C13.8573 1.56056 14.3123 1.99483 14.5928 2.53041L22.6933 17.9949C23.4094 19.362 22.8289 21.0243 21.3967 21.7078C20.9941 21.9 20.5502 22 20.1001 22H3.89924C2.29803 22 1 20.761 1 19.2325C1 18.8029 1.10479 18.3792 1.30608 17.9949L9.40652 2.53041C10.1226 1.16335 11.8641 0.609235 13.2963 1.29277ZM11.1612 3.59512L3.29922 18.6043C3.2341 18.7286 3.2002 18.8657 3.2002 19.0047C3.2002 19.4991 3.62013 19.9 4.13815 19.9H19.862C20.0076 19.9 20.1513 19.8676 20.2815 19.8055C20.7448 19.5843 20.9326 19.0465 20.701 18.6043L12.839 3.59512C12.7483 3.42185 12.6011 3.28135 12.4196 3.19471C11.9562 2.97358 11.3928 3.15284 11.1612 3.59512ZM12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16ZM12 6.99999C12.5523 6.99999 13 7.39174 13 7.87499V13.125C13 13.6082 12.5523 14 12 14C11.4477 14 11 13.6082 11 13.125V7.87499C11 7.39174 11.4477 6.99999 12 6.99999Z"
        />
    </svg>
);
export const ChevronIcon = () => (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.70725 10.2933C7.09825 10.6842 7.09825 11.3162 6.70725 11.7072C6.31625 12.0982 5.68425 12.0982 5.29325 11.7072L0.29325 6.70725C-0.09775 6.31625 -0.09775 5.68425 0.29325 5.29325L5.29325 0.29325C5.68425 -0.09775 6.31625 -0.09775 6.70725 0.29325C7.09825 0.68425 7.09825 1.31625 6.70725 1.70725L2.41425 6.00025L6.70725 10.2933Z"
            fill="#212243"
        />
    </svg>
);
export const CheckIcon = (props) => (
    <svg {...getDefaultProps(24, 24, true)} {...props}>
        <g clipPath="url(#clip0)">
            <circle cx="12" cy="12.5" r="10" stroke="#43D011" strokeWidth="2" />
            <path
                d="M15 10.4999L11.3077 15.1153L9.00001 13.2692"
                stroke="#43D011"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
export const FlameIcon = (props) => (
    <svg {...getDefaultProps(24, 24)} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.41422 11.1066C0.633168 11.8877 0.633168 13.154 1.41422 13.935L10.6066 23.1274C11.3877 23.9085 12.654 23.9085 13.435 23.1274L22.6274 13.935C23.4085 13.154 23.4085 11.8877 22.6274 11.1066L13.435 1.91423C12.654 1.13318 11.3877 1.13318 10.6066 1.91423L1.41422 11.1066ZM11.2932 4.20712C11.6837 3.8166 12.3169 3.8166 12.7074 4.20712L20.2922 11.792C20.6831 12.1828 20.6827 12.8168 20.2913 13.2071L12.684 20.7939C12.2929 21.1839 11.6598 21.183 11.2698 20.7919L3.7055 13.2071C3.31588 12.8164 3.3163 12.184 3.70645 11.7939L11.2932 4.20712ZM12.0003 16.5C12.5526 16.5 13.0003 16.9477 13.0003 17.5C13.0003 18.0523 12.5526 18.5 12.0003 18.5C11.448 18.5 11.0003 18.0523 11.0003 17.5C11.0003 16.9477 11.448 16.5 12.0003 16.5ZM13.0003 7.49999C13.0003 6.9477 12.5526 6.49999 12.0003 6.49999C11.448 6.49999 11.0003 6.9477 11.0003 7.49999V13.5C11.0003 14.0523 11.448 14.5 12.0003 14.5C12.5526 14.5 13.0003 14.0523 13.0003 13.5V7.49999Z"
        />
    </svg>
);
export const InfoIcon = (props) => {
    return (
        <svg {...getDefaultProps(24, 24, true)} {...props}>
            <circle cx="12" cy="12" r="10" stroke="#3D66B0" strokeWidth="2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6Z"
                fill="#3D66B0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 10C12.5523 10 13 10.4477 13 11V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10Z"
                fill="#3D66B0"
            />
        </svg>
    );
};
export const CloseIcon = (props) => (
    <svg {...getDefaultProps(14)} {...props}>
        <path
            fillRule="evenodd"
            d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
        />
    </svg>
);

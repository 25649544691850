import React, { useState } from 'react';

import { Button, ButtonIcon, ButtonProps } from '@nestoca/ui';
import { BsChevronUp } from 'react-icons/bs';
import styled from 'styled-components';

import useIsomorphicLayoutEffect from 'utils/use-isomorphic-layout-effect';

const ScrollToTopButton = styled(Button)`
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 10;
`;

const MobileScrollToTopButton = styled(ButtonIcon)`
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 10;
`;

type Props = {
    text?: string;
    className?: string;
    style?: Record<string, unknown>;
    distance?: number;
    breakpoint?: number;
    icon?: JSX.Element;
    speed?: number;
    target?: number;
    variant?: ButtonProps['variant'];
};

export const ScrollTop = ({
    text,
    distance = 50,
    breakpoint = 991,
    speed = 250,
    target = 0,
    icon,
    variant = 'primary',
    ...rest
}: Props) => {
    const [isMobile, setIsmobile] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const frameUpdateRef = React.useRef<number>(0);

    const checkWindowSize = () => {
        setIsmobile(window.innerWidth < breakpoint);
    };

    const checkForScrollToTop = () => {
        if (
            document.body.scrollTop > distance ||
            document.documentElement.scrollTop > distance
        ) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const handleScroll = () => {
        checkForScrollToTop();
    };

    const scrollUp = () => {
        const { performance, requestAnimationFrame } = window;

        if (
            speed <= 0 ||
            typeof performance === 'undefined' ||
            typeof requestAnimationFrame === 'undefined'
        ) {
            return setScrollTop(target);
        }

        const start = performance.now();
        const initScrollTop = getScrollTop();
        const pxsToScrollBy = initScrollTop - target;

        function step(timestamp) {
            const delta = timestamp - start;
            const progress = Math.min(delta / speed, 1);

            setScrollTop(initScrollTop - Math.round(progress * pxsToScrollBy));

            if (progress < 1) {
                frameUpdateRef.current = requestAnimationFrame(step);
            }
        }

        frameUpdateRef.current = requestAnimationFrame(step);
    };

    const getScrollTop = () => {
        return (
            document.body.scrollTop ||
            (document.documentElement && document.documentElement.scrollTop) ||
            0
        );
    };

    const setScrollTop = (value) => {
        document.body.scrollTop = value;
        if (document.documentElement) {
            document.documentElement.scrollTop = value;
        }
    };

    useIsomorphicLayoutEffect(() => {
        checkWindowSize();
        checkForScrollToTop();
        window.addEventListener('resize', checkWindowSize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            cancelAnimationFrame(frameUpdateRef.current);
            window.removeEventListener('resize', checkWindowSize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (showScrollToTop && isMobile) || (showScrollToTop && !text) ? (
        <MobileScrollToTopButton
            {...rest}
            aria-label="scroll-to-top"
            isRound
            variant={variant}
            onClick={scrollUp}
        >
            {icon ? icon : <BsChevronUp title={null} size={28} />}
        </MobileScrollToTopButton>
    ) : (
        showScrollToTop && !text && (
            <ScrollToTopButton {...rest} variant={variant} onClick={scrollUp}>
                {text}
            </ScrollToTopButton>
        )
    );
};

import React from 'react';

import { Modal } from 'components/modal';
import { LogIssueModal } from 'components/sidebar/log-issue';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';

export type logIssueModalProps = {
    rid?: string;
};

export const GlobalModalWrapper = ({ children }) => {
    const { i18n } = useI18n();

    const {
        isVisible: isLogIssueModalVisible,
        close: closeLogIssueModal,
        props: logIssueModalProps,
    } = useModal('logIssue');

    return (
        <>
            {children}
            <Modal
                id="logIssue"
                visible={isLogIssueModalVisible}
                sx={{ width: '100%', maxWidth: 640 }}
                title={i18n._(
                    logIssueModalProps?.rid
                        ? 'logIssueModal.title'
                        : 'logIssueModal.titleNoRid'
                )}
                onClose={closeLogIssueModal}
            >
                <LogIssueModal {...logIssueModalProps} />
            </Modal>
        </>
    );
};

import React from 'react';

import qs from 'qs';
import { RecoilRoot } from 'recoil';

import { currentApplicationIdState } from 'store/applications';

const initializeState = ({ set }) => {
    if (typeof localStorage !== 'undefined') {
        const isServer = typeof window === 'undefined';
        const queries = qs.parse(!isServer ? window.location.search : '', {
            ignoreQueryPrefix: true,
        });

        if (
            localStorage &&
            queries?.applicationId &&
            +queries?.applicationId > 0
        ) {
            localStorage.setItem(
                'OfficeAuth:applicationId',
                queries?.applicationId as string
            );
        }

        const currentApplicationIdValue =
            (localStorage &&
                +localStorage.getItem('OfficeAuth:applicationId')) ||
            0;

        set(currentApplicationIdState, currentApplicationIdValue);
    }
};

const RecoilWrapper = ({ children }): JSX.Element => (
    <RecoilRoot initializeState={initializeState}>
        <>
            {/* Dev tools for Recoil */}
            {children}
        </>
    </RecoilRoot>
);

export default RecoilWrapper;

import { selectorFamily } from 'recoil';

import {
    extractRelevantHelocRateInfo,
    extractRelevantRateInfo,
} from 'components/rates/rates-utils';
import { client as apiClient } from 'libs/api';
import { HelocProductType } from 'types/heloc';

import { rateFiltersState, helocRateFiltersState } from './atom';

import type { Product } from 'types/rates';

export const getSelectedRate = selectorFamily({
    key: 'getSelectedRate',
    get: (productId: number) => async () => {
        try {
            const { data } = await apiClient.getSelectedRate(productId);
            const rateInfo = extractRelevantRateInfo(data);
            return rateInfo;
        } catch (error) {
            console.error('getSelectedRate error', error);
            throw error;
        }
    },
});

export const getSelectedHelocRate = selectorFamily({
    key: 'getSelectedHelocRate',
    get: (productId: number) => async () => {
        try {
            const { data } = await apiClient.getSelectedHelocRate(productId);
            const rateInfo = extractRelevantHelocRateInfo(data);
            return rateInfo;
        } catch (error) {
            console.error('getSelectedHelocRate error', error);
            throw error;
        }
    },
});

export const getSingleRate = selectorFamily({
    key: 'getSingleRate',
    get: (productId: number) => async () => {
        try {
            const { data } = await apiClient.getSelectedRate(productId);
            return data;
        } catch (error) {
            console.error('getSingleRate error', error);
            throw error;
        }
    },
});

const transformProduct = (product: Product | HelocProductType) => ({
    ...product,
    created: new Date(product.created),
    updated: new Date(product.updated),
});

export const rateQuery = selectorFamily({
    key: 'RateQuery',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const defaultResponse = {
                currentPage: 0,
                results: [],
                totalCount: 0,
                totalPages: 0,
            };

            try {
                const filter = get(rateFiltersState(applicationId));

                // No region code (province) gives a 422 backend error (OG-7505)
                if (!filter.regionCode) {
                    return defaultResponse;
                }

                const { data } = await apiClient.getApplicationRates(
                    applicationId,
                    {
                        ...filter,
                        context: undefined,
                        // Parse request (RateFiltersRequest) from string to array of numbers
                        // eslint-disable-next-line
                        // @ts-ignore
                        lenders: filter.lenders?.length
                            ? filter.lenders.join(',')
                            : undefined,
                        rateHold: filter.rateHold?.length
                            ? filter.rateHold.join(',')
                            : undefined,
                        investors: filter.investors?.length
                            ? filter.investors.join(',')
                            : undefined,
                        productFamilies: filter.productFamilies?.length
                            ? filter.productFamilies.join(',')
                            : undefined,
                    } as any
                );

                return {
                    ...data,
                    results: data.results.map(transformProduct) as Product[],
                };
            } catch (error) {
                return defaultResponse;
            }
        },
});

export const helocRateQuery = selectorFamily({
    key: 'helocRateQuery',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            try {
                const filter = get(helocRateFiltersState(applicationId));

                // Remove context from filters for api call
                const { context, ...requestFilters } = filter;

                const { data } = await apiClient.getHelocRates(applicationId, {
                    ...requestFilters,
                    // Parse request (RateFiltersRequest) from string to array of numbers
                    // eslint-disable-next-line
                    // @ts-ignore
                    lenders: filter.lenders?.length
                        ? filter.lenders.join(',')
                        : undefined,
                });

                return data.map(transformProduct) as HelocProductType[];
            } catch (error) {
                return [];
            }
        },
});

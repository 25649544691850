import { PropsWithChildren } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import { useRouter } from 'next/router';

import { Maintenance } from './maintenance';

type MaintenanceProviderProps = {
    isEnabled?: boolean;
};

export const MaintenanceProvider = ({
    isEnabled,
    children,
}: PropsWithChildren<MaintenanceProviderProps>) => {
    const [debugMode, setDebugMode] = useState(false);
    const { query, isReady } = useRouter();
    const queryDebugRef = useRef(false);

    useEffect(() => {
        if (queryDebugRef.current) return;

        if (isReady) {
            // env variable injection is not working at the moment
            // we don't have time to investigate and fix this
            // so we're putting it in the code for now - to be removed when this is fixed
            setDebugMode(query.debug === 'Ml3BFkT4Qhzc5HINCIx8yw');
            queryDebugRef.current = true;
        }
    }, [isReady, query.debug]);

    if (isEnabled && !debugMode) return <Maintenance />;

    return <>{children}</>;
};

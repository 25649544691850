import type { I18n } from '@lingui/core';

export const isBeforeDate = (i18n: I18n) => (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    const today = new Date().toISOString();

    return value < today ? undefined : i18n._('error.dateShouldBePast');
};

export const isTodayOrFutureDate = (i18n: I18n) => (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    const today = new Date().toISOString();

    return value >= today ? undefined : i18n._('error.dateShouldBeFuture');
};

export const isMin =
    (i18n: I18n, minNumber: number) => (value: string | number) =>
        +value < minNumber
            ? i18n._('error.valueGreaterThan', { value: minNumber })
            : undefined;

export const isMax =
    (i18n: I18n, maxNumber: number) =>
    (value: string | number): string | undefined =>
        +value > maxNumber
            ? i18n._('error.valueLowerThan', { value: maxNumber })
            : undefined;

export const isRequired = (i18n: I18n, required = true) => ({
    required: required ? i18n._('error.fieldRequired') : undefined,
});

export class ValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ValidationError';
    }
}

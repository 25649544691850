import { useEffect, useRef, useState } from 'react';

import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';

import type { LeaderElector, BroadcastChannelOptions } from 'broadcast-channel';

// For testing
// import { enforceOptions } from 'broadcast-channel';

// // enforce this config for all channels
// enforceOptions({
//   type: 'simulate'
// });
// // reset the enforcement
// enforceOptions(null);

export default function useChannel<T = any>(
    name: string,
    options?: BroadcastChannelOptions
): [BroadcastChannel<T> | undefined, boolean] {
    const channel = useRef<BroadcastChannel<T>>();
    const elector = useRef<LeaderElector>();
    const [isLeader, setIsLeader] = useState(false);

    useEffect(() => {
        if (!channel.current) {
            channel.current = new BroadcastChannel(name, options);
            elector.current = createLeaderElection(channel.current);
        }
    }, [name, options]);

    useEffect(() => {
        if (!isLeader && elector.current) {
            elector.current.awaitLeadership().then(() => {
                setIsLeader(true);
            });
        }
    }, [elector.current, isLeader, setIsLeader]);

    return [channel.current, isLeader];
}

import { useEffect, useState, useReducer } from 'react';
import { ReactNode } from 'react';

import { useRouter } from 'next/router';

import { AccountRidContext } from './account-rid-context';
import { accountRidReducer, setAccountRid } from './account-rid-reducer';

import type {
    AccountRidState,
    AccountRidAction,
    AccountRidContextState,
} from './account-rid-types';

const isBrowser = typeof window !== 'undefined';

const getDefaultState = (): AccountRidState => {
    // get accountRid from local storage
    const accountRid =
        (isBrowser && localStorage.getItem('OfficeAuth:accountRid')) ||
        undefined;

    return { accountRid };
};

export const AccountRidProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer<
        React.Reducer<AccountRidState, AccountRidAction>
    >(accountRidReducer, getDefaultState());

    const [contextValue, setContextValue] = useState<AccountRidContextState>({
        state,
        dispatch,
    });

    // Update context value and trigger re-render
    // This patterns avoids unnecessary deep renders
    // https://reactjs.org/docs/context.html#caveats
    useEffect(() => {
        setContextValue((contextValue: AccountRidContextState) => ({
            ...contextValue,
            state,
        }));
    }, [state]);

    const { query } = useRouter();

    useEffect(() => {
        // We have accountRid in URL query, let's use it
        if (query.accountRid && typeof query.accountRid === 'string') {
            dispatch(setAccountRid(query.accountRid));

            return;
        }

        // We don't have accountRid in URL query, let's check local storage
        const accountRid =
            isBrowser && localStorage.getItem('OfficeAuth:accountRid');

        if (!!accountRid) {
            dispatch(setAccountRid(accountRid));
        }
    }, [query.accountRid]);

    return (
        <AccountRidContext.Provider value={contextValue}>
            {children}
        </AccountRidContext.Provider>
    );
};

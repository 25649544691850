import qs from 'qs';
import {
    atom,
    selectorFamily,
    useSetRecoilState,
    useRecoilRefresher_UNSTABLE,
} from 'recoil';

import { client as apiClient } from 'libs/api';
import { allowedProps } from 'utils';
import { getApplicationMainType } from 'utils/application-type';
import { round } from 'utils/math';
import {
    allowedRateQueries,
    rateQueriesSanitize,
    allowedHelocRateQueries,
    helocRateQueriesSanitize,
} from 'utils/rate-filters';

import type { HelocRateFilters, RateFilters } from 'types/rates';
import type { AllowedLimit } from 'utils/rate-filters';

export const defaultRateFiltersSelector = selectorFamily({
    key: 'DefaultRateFiltersSelector',
    get:
        (applicationId: number) =>
        async ({ get }): Promise<RateFilters> => {
            try {
                get(rateFiltersRequestIDState);

                const { data } = await apiClient.getRatesFilters(applicationId);

                const { isRenewalApplication } = getApplicationMainType(
                    data.transactionType
                );

                const isServer = typeof window === 'undefined';
                const queries = qs.parse(
                    !isServer ? window.location.search : '',
                    {
                        ignoreQueryPrefix: true,
                    }
                );

                const page = queries.page || data.page || 1;
                const limit: AllowedLimit = queries.limit
                    ? (+(queries.limit as unknown) as AllowedLimit)
                    : data.limit || 50;

                // Merge returned BE response with what exists in the URL queries string
                // then keep only sanitized props
                return allowedProps(
                    allowedRateQueries,
                    {
                        context: 'TRANSACTION',
                        ...data,
                        downPaymentAmount: round(data?.downPaymentAmount, 0),
                        propertyValue: round(data?.propertyValue, 0),
                        mortgageBalance: round(data?.mortgageBalance, 0),
                        additionalFundAmount: round(
                            data?.additionalFundAmount,
                            0
                        ),
                        // originallyInsured is always sent from BE
                        // only when `RENEWAL` transaction type
                        // need to be sent in the /product?....&originallyInsured={true | false}
                        originallyInsured: isRenewalApplication
                            ? data?.originallyInsured
                            : undefined,
                        isTransferFeeAdded: isRenewalApplication
                            ? data?.isTransferFeeAdded
                            : undefined,
                        ...queries,
                        page: +page,
                        daysToClose: data.daysToClose,
                        rateHold: data.applicableRateHolds,
                        limit,
                    },
                    rateQueriesSanitize
                );
            } catch (error) {
                console.error('defaultRateFiltersSelector error', error);
                return {
                    context: 'TRANSACTION',
                    transactionType: 'NEW',
                    purpose: 'OWNER_OCCUPIED',
                    propertyValue: 250000,
                    amortization: 25,
                    daysToClose: null,
                    type: 'FIXED',
                    term: '5_YEAR',
                    regionCode: 'QC',
                    page: 1,
                    limit: 10,
                };
            }
        },
});

export const useRefreshDefaultRateFiltersSelector = (applicationId: number) =>
    useRecoilRefresher_UNSTABLE(defaultRateFiltersSelector(applicationId));

const rateFiltersRequestIDState = atom({
    key: 'RateFiltersRequestID',
    default: 0,
});

export const useRefreshRatesFilters = () => {
    const setRateFiltersRequestID = useSetRecoilState(
        rateFiltersRequestIDState
    );
    return () => {
        setRateFiltersRequestID((requestID) => requestID + 1);
    };
};

export const filtersSidebarState = atom({
    key: 'filtersSidebarState',
    default: {
        showSidebar: false,
    },
});

export const defaultHelocRateFiltersSelector = selectorFamily({
    key: 'defaultHelocRateFiltersSelector',
    get:
        (applicationId: number) =>
        async ({ get }): Promise<HelocRateFilters> => {
            try {
                get(rateFiltersRequestIDState);

                const { data } =
                    await apiClient.getHelocRatesFilters(applicationId);

                const isServer = typeof window === 'undefined';
                const queries = qs.parse(
                    !isServer ? window.location.search : '',
                    {
                        ignoreQueryPrefix: true,
                    }
                );

                // Merge returned BE response with what exists in the URL queries string
                // then keep only sanitized props

                return allowedProps(
                    allowedHelocRateQueries,
                    {
                        context: 'HELOC' as const,
                        ...data,
                        ...queries,
                        isStandalone: true,
                    },
                    helocRateQueriesSanitize
                );
            } catch (error) {
                console.error('defaultRateFiltersSelector error', error);
                return {
                    context: 'HELOC',
                    ltvCreditLimit: 0,
                    ltvCombined: 0,
                    creditLimit: 0,
                    gds: 0,
                    tds: 0,
                    purpose: 'OWNER_OCCUPIED',
                    regionCode: 'QC',
                    transactionType: 'NEW',
                };
            }
        },
});

export const useRefreshDefaultHelocRateFiltersSelector = (
    applicationId: number
) =>
    useRecoilRefresher_UNSTABLE(defaultHelocRateFiltersSelector(applicationId));

import { client as apiClient } from 'libs/api';

import type { AccountRidAction, AccountRidState } from './account-rid-types';

export const accountRidReducer = (
    state: AccountRidState,
    action: AccountRidAction
) => {
    // set accountRid
    if (action.type === 'SET_ACCOUNT_RID') {
        // let's clear the selected application id from local storage
        // if it is not the same accountRid in state and action
        // How this can happen?
        // 1. Broker is with accountRid A
        // 2. Go back to Sale Force select another client
        // 2. Broker is with accountRid B
        // Then the accountRid store in local storage (state) is different from the accountRid in action
        if (state.accountRid !== action.accountRid) {
            sessionStorage.removeItem('tenant');
            localStorage.removeItem('OfficeAuth:applicationId');
        }

        // set it to api client for x-on-behalf header
        apiClient.setXOnBehalf(action.accountRid);

        // save it to local storage
        localStorage.setItem('OfficeAuth:accountRid', action.accountRid);

        return { ...state, accountRid: action.accountRid };
    }

    // reset accountRid
    if (action.type === 'RESET_ACCOUNT_RID') {
        apiClient.resetXOnBehalf();

        // clear it from local storage
        localStorage.removeItem('OfficeAuth:accountRid');

        sessionStorage.removeItem('tenant');
        localStorage.removeItem('OfficeAuth:applicationId');

        return { ...state, accountRid: undefined };
    }

    // fallback to return state
    return { ...state };
};

export const setAccountRid = (accountRid: string): AccountRidAction => ({
    type: 'SET_ACCOUNT_RID',
    accountRid,
});

export const resetAccountRid = (): AccountRidAction => ({
    type: 'RESET_ACCOUNT_RID',
});

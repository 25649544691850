import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { saturate, desaturate, lighten } from 'polished';
import { compose } from 'ramda';

import { baseTheme } from './nesto';

import type { Theme as NestoThemeType } from './types';

const toneDisabled = compose(lighten(0.5), desaturate(0.5));

const brandColor = '#002669';

const colors = {
    brand: brandColor,
    brandTextColor: brandColor,
    cardTextColor: '#fff',
    portGore: '#212243',
    'accent-1': brandColor,
    'dark-1': '#1D1A1A',
    'gray-4': '#f8f8f8',
    'gray-3': '#d5d5d5',
    'gray-2': '#949599',
    'gray-1': '#343434',
    'light-2': '#f6f6f6',
    'neutral-3': '#0073CF',
    'status-warning': '#F8CD46',
    // See with JF to help accessibility, this is not a good practice
    focus: '#0073CF',
    text: {
        light: '#1D1A1A',
    },
    placeholder: '#9B9B9B',
    overlay: '#FFFFFFF5',
};

const myTheme: NestoThemeType = {
    whiteLabel: true,
    colors,
    logoPath: {
        en: `/co-branding/ig_wm.png`,
        fr: `/co-branding/ig_wm.png`,
    },
    global: {
        colors,
    },
    button: {
        primary: { background: { color: brandColor } },
        hover: {
            primary: {
                extend: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    boxShadow: `0px 0px 0px 2px ${brandColor}`,
                },
            },
        },
    },
    // Starting here are not GROMMET theme spec
    // Nesto discrepancies styles
    cells: {
        discrepancies: {
            backgroundColor: 'var(--color-red-300)',
            border: '1px solid',
            borderColor: 'var(--color-red-500)',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            color: colors.portGore,
        },
    },
    // Nesto buttons styles
    buttons: {
        primary: {
            backgroundColor: brandColor,
            color: 'white',
            borderColor: brandColor,
            ':hover:not(.disabled), :focus:not(.disabled)': {
                backgroundColor: saturate(0.2, brandColor),
                borderColor: saturate(0.2, brandColor),
            },
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                backgroundColor: toneDisabled(brandColor),
                borderColor: toneDisabled(brandColor),
            },
        },
    },
};

export const theme = deepMerge<
    NestoThemeType,
    [NestoThemeType, NestoThemeType]
>(grommet, baseTheme, myTheme);

import React, { forwardRef } from 'react';

import { Trans, TransRenderCallbackOrComponent } from '@lingui/react';
import { Box } from 'reflexbox/styled-components';

import type { TransProps } from '@lingui/react';
import type { BoxProps } from 'reflexbox/styled-components';

type TextProps = {
    tx?: string;
    values?: Record<string, unknown>;
    children?: React.ReactNode;
    color?: string;
    components?: Record<string, React.ElementType | any>;
    as?: React.ElementType;
} & Pick<TransProps, 'formats' | 'component' | 'render'> &
    Omit<JSX.IntrinsicElements['span'], 'color'> &
    BoxProps &
    TransRenderCallbackOrComponent;

const ONE = 1;

/**
 * @deprecated use <Typography> from '@nestoca/ui' instead
 */
export const Text = forwardRef<HTMLSpanElement, TextProps>(
    (
        {
            tx,
            values,
            fontSize = ONE,
            children,
            color,
            components,
            formats,
            component,
            render,
            as,
            ...props
        }: TextProps,
        ref: any
    ) => {
        const transRenderCallbackOrComponentProps = render || component;
        return (
            <Box
                ref={ref}
                as={as || 'span'}
                fontSize={fontSize}
                color={color}
                {...props}
            >
                {tx && (
                    <Trans
                        id={tx}
                        values={values}
                        components={components}
                        formats={formats}
                        {...transRenderCallbackOrComponentProps}
                    />
                )}
                {children}
            </Box>
        );
    }
);

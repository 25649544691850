import { I18n } from '@lingui/core';

import { SelectOptions } from 'types';
import { OtherPropertyType } from 'types/property';

export const PRODUCT_TRACKER_CODE = {
    STANDARD: 'STANDARD',
    ALT_A: 'ALT_A',
    RENTAL: 'RENTAL',
    '': '',
} as const;

export const SECURITY_TYPE = ['01', '02', '03', ''] as const;

export const PROPERTY_TYPE_UNIT_COUNT_MAPPING: Record<string, number> = {
    DUPLEX_DETACHED: 2,
    DUPLEX_SEMI_DETACHED: 2,
    TRI_PLEX_DETACHED: 3,
    TRI_PLEX_SEMI_DETACHED: 3,
    FOUR_PLEX_DETACHED: 4,
    FOUR_PLEX_SEMI_DETACHED: 4,
};

enum PropertyTypes {
    DETACHED = 'DETACHED',
    SEMI_DETACHED = 'SEMI_DETACHED',
    DUPLEX_DETACHED = 'DUPLEX_DETACHED',
    DUPLEX_SEMI_DETACHED = 'DUPLEX_SEMI_DETACHED',
    ROW_HOUSING = 'ROW_HOUSING',
    APARTMENT_LOW_RISE = 'APARTMENT_LOW_RISE',
    APARTMENT_HIGH_RISE = 'APARTMENT_HIGH_RISE',
    MOBILE = 'MOBILE',
    TRI_PLEX_DETACHED = 'TRI_PLEX_DETACHED',
    TRI_PLEX_SEMI_DETACHED = 'TRI_PLEX_SEMI_DETACHED',
    STACKED = 'STACKED',
    MODULAR_HOME_DETACHED = 'MODULAR_HOME_DETACHED',
    MODULAR_HOME_SEMI_DETACHED = 'MODULAR_HOME_SEMI_DETACHED',
    FOUR_PLEX_DETACHED = 'FOUR_PLEX_DETACHED',
    FOUR_PLEX_SEMI_DETACHED = 'FOUR_PLEX_SEMI_DETACHED',
    SeasonalHome = 'SeasonalHome',
    OTHER = 'OTHER',
}

export const PROPERTY_VALUE_MAP = {
    [PropertyTypes.DETACHED]: {
        id: PropertyTypes.DETACHED,
        labelKey: `propertyType.detached`,
    },
    [PropertyTypes.SEMI_DETACHED]: {
        id: PropertyTypes.SEMI_DETACHED,
        labelKey: `propertyType.semidetached`,
    },
    [PropertyTypes.DUPLEX_DETACHED]: {
        id: PropertyTypes.DUPLEX_DETACHED,
        labelKey: `propertyType.duplex_detached`,
    },
    [PropertyTypes.DUPLEX_SEMI_DETACHED]: {
        id: PropertyTypes.DUPLEX_SEMI_DETACHED,
        labelKey: `propertyType.duplex_semi_detached`,
    },
    [PropertyTypes.ROW_HOUSING]: {
        id: PropertyTypes.ROW_HOUSING,
        labelKey: `propertyType.row_housing`,
    },
    [PropertyTypes.APARTMENT_LOW_RISE]: {
        id: PropertyTypes.APARTMENT_LOW_RISE,
        labelKey: `propertyType.apartment_low_rise`,
    },
    [PropertyTypes.APARTMENT_HIGH_RISE]: {
        id: PropertyTypes.APARTMENT_HIGH_RISE,
        labelKey: `propertyType.apartment_hi_rise`,
    },
    [PropertyTypes.MOBILE]: {
        id: PropertyTypes.MOBILE,
        labelKey: `propertyType.mobile`,
    },
    [PropertyTypes.TRI_PLEX_DETACHED]: {
        id: PropertyTypes.TRI_PLEX_DETACHED,
        labelKey: `propertyType.tri_plex_detached`,
    },
    [PropertyTypes.TRI_PLEX_SEMI_DETACHED]: {
        id: PropertyTypes.TRI_PLEX_SEMI_DETACHED,
        labelKey: `propertyType.tri_plex_semi_detached`,
    },
    [PropertyTypes.STACKED]: {
        id: PropertyTypes.STACKED,
        labelKey: `propertyType.stacked`,
    },
    [PropertyTypes.MODULAR_HOME_DETACHED]: {
        id: PropertyTypes.MODULAR_HOME_DETACHED,
        labelKey: `propertyType.modular_home_detached`,
    },
    [PropertyTypes.MODULAR_HOME_SEMI_DETACHED]: {
        id: PropertyTypes.MODULAR_HOME_SEMI_DETACHED,
        labelKey: `propertyType.modular_home_semi_detached`,
    },
    [PropertyTypes.FOUR_PLEX_DETACHED]: {
        id: PropertyTypes.FOUR_PLEX_DETACHED,
        labelKey: `propertyType.four_plex_detached`,
    },
    [PropertyTypes.FOUR_PLEX_SEMI_DETACHED]: {
        id: PropertyTypes.FOUR_PLEX_SEMI_DETACHED,
        labelKey: `propertyType.four_plex_semi_detached`,
    },
    [PropertyTypes.SeasonalHome]: {
        id: PropertyTypes.SeasonalHome,
        labelKey: `propertyType.seasonal_home`,
    },
    [PropertyTypes.OTHER]: {
        id: PropertyTypes.OTHER,
        labelKey: `propertyType.other`,
    },
};

export const OTHER_PROPERTY_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<OtherPropertyType> => [
    { label: i18n._(`propertyType.house`), value: 'HOUSE' },
    { label: i18n._(`propertyType.condo`), value: 'CONDO' },
    {
        label: i18n._(`propertyType.secondaryHome`),
        value: 'SECONDARY_HOME_OR_COTTAGE',
    },
    { label: i18n._(`propertyType.duplex`), value: 'DUPLEX' },
    { label: i18n._(`propertyType.triplex`), value: 'TRIPLEX' },
    { label: i18n._(`propertyType.fourplex`), value: 'FOURPLEX' },
];

export const PROPERTY_PURPOSE = (i18n: I18n) => [
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._(`propertyPurpose.ownerOccupied`),
    },
    {
        value: 'ANY_RENTAL',
        label: i18n._(`propertyPurpose.anyRental`),
    },
    {
        value: 'RENTAL_1_UNIT',
        label: i18n._(`propertyPurpose.rental1Unit`),
    },
    {
        value: 'RENTAL_2-4_UNITS',
        label: i18n._(`propertyPurpose.rental2-4Units`),
    },
];

export const OTHER_PROPERTY_PURPOSE = (i18n: I18n) => [
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._(`afterTransaction.ownerOccupied`),
    },
    {
        value: 'OWNER_OCCUPIED_AND_RENTAL',
        label: i18n._(`afterTransaction.ownerOccupiedAndRental`),
    },
    {
        value: 'RENTAL',
        label: i18n._(`afterTransaction.rental`),
    },
];

import { useContext } from 'react';

import { useRouter } from 'next/router';

import { AccountRidContext } from './account-rid-context';
import {
    setAccountRid as setAccountRidAction,
    resetAccountRid as resetAccountRidAction,
} from './account-rid-reducer';

export const useAccountRid = () => {
    const router = useRouter();
    const { state, dispatch } = useContext(AccountRidContext);
    const { accountRid: stateRid } = state;
    const { accountRid: queryRid } = router.query;

    // We always prioritize query parameter over local storage
    // AccountRidProvider will always override with the query rid
    // This avoids a race condition where AccountRidProvider sets the new rid after the UserProvider starts redirecting to /missing-rid
    const accountRid =
        queryRid && !Array.isArray(queryRid) ? queryRid : stateRid;

    const setAccountRid = (newRid: string) => {
        dispatch(setAccountRidAction(newRid));
    };

    const resetAccountRid = () => {
        dispatch(resetAccountRidAction());
    };

    return { accountRid, setAccountRid, resetAccountRid };
};

import { useTenantSetting } from '@nestoca/multi-tenant';

import type { I18n } from '@lingui/core';
import type { SelectOption, SelectOptions } from 'types';

const Alterna = '/logos/lenders/Alterna.png';
const CMLS = '/logos/lenders/CMLS.png';
const Desjardins = '/logos/lenders/Desjardins.png';
const Duca = '/logos/lenders/duca.png';
const EquitableBank = '/logos/lenders/Equitable_bank.png';
const FNF = '/logos/lenders/First_National.png';
const ICICI = '/logos/lenders/ICICI.png';
const IG = '/logos/lenders/IG.png';
const Lendwise = '/logos/lenders/Lendwise.png';
const MCAP = '/logos/lenders/MCAP.png';
const Merix = '/logos/lenders/Merix.png';
const Nesto = '/logos/lenders/nesto.png';
const Scotiabank = '/logos/lenders/Scotia_bank.png';
const StreetCapital = '/logos/lenders/Street_capital.png';
const TD = '/logos/lenders/TD.png';
const UNI = '/logos/lenders/UNI.png';
const WealthOne = '/logos/lenders/Wealth_one.png';
const XMC = '/logos/lenders/XMC.png';

export const LenderId = {
    NESTO: 1,
    CMLS: 3,
    FIRST_NATIONAL: 4,
    ICICI_BANK: 6,
    LENDWISE: 7,
    MCAP: 8,
    MERIX: 9,
    SCOTIABANK: 12,
    STREET_CAPITAL: 13,
    TD: 14,
    EQUITABLE_BANK: 16,
    DESJARDINS: 26,
    DUCA: 27,
    ALTERNA: 53,
    WEALTH_ONE_BANK: 54,
    XMC: 60,
    UNI: 1000,
    EQUITABLE_BANK_CBS: 1001, // Co-Branded Special,
    IG: 11,
} as const;
export type LENDER_ID = (typeof LenderId)[keyof typeof LenderId];

const SUPPORTED_LENDERS = {
    // https://github.com/nestoca/products/blob/764cbea12c39138606146ba2f422998f23fb1e70/api/products.go#L36
    [LenderId.NESTO]: {
        // TBD this is a nesto product
        // string values should be revised too
        lender: 'NESTO',
        lenderId: LenderId.NESTO,
        namePath: 'lender.nesto',
        descriptionPath: 'lender.nesto.description',
        image: Nesto,
    },
    [LenderId.CMLS]: {
        lender: 'CMLS',
        lenderId: LenderId.CMLS,
        namePath: 'lender.cmlsFinancial',
        descriptionPath: 'lender.cmlsFinancial.description',
        image: CMLS,
    },
    [LenderId.FIRST_NATIONAL]: {
        lender: 'FIRST_NATIONAL',
        lenderId: LenderId.FIRST_NATIONAL,
        namePath: 'lender.firstNationalFinancial',
        descriptionPath: 'lender.firstNationalFinancial.description',
        image: FNF,
    },
    [LenderId.ICICI_BANK]: {
        lender: 'ICICI_BANK',
        lenderId: LenderId.ICICI_BANK,
        namePath: 'lender.icici',
        descriptionPath: 'lender.icici.description',
        image: ICICI,
    },
    [LenderId.LENDWISE]: {
        lender: 'LENDWISE',
        lenderId: LenderId.LENDWISE,
        namePath: 'lender.lendwise',
        descriptionPath: 'lender.lendwise.description',
        image: Lendwise,
    },
    [LenderId.MCAP]: {
        lender: 'MCAP',
        lenderId: LenderId.MCAP,
        namePath: 'lender.mcapFinancial',
        descriptionPath: 'lender.mcapFinancial.description',
        image: MCAP,
    },
    [LenderId.MERIX]: {
        lender: 'MERIX',
        lenderId: LenderId.MERIX,
        namePath: 'lender.merixFinancial',
        descriptionPath: 'lender.merixFinancial.description',
        image: Merix,
    },
    [LenderId.SCOTIABANK]: {
        lender: 'SCOTIABANK',
        lenderId: LenderId.SCOTIABANK,
        namePath: 'lender.scotiabank',
        descriptionPath: 'lender.scotiabank.description',
        image: Scotiabank,
    },
    [LenderId.STREET_CAPITAL]: {
        lender: 'STREET_CAPITAL',
        lenderId: LenderId.STREET_CAPITAL,
        namePath: 'lender.streetCapitalBank',
        descriptionPath: 'lender.streetCapitalBank.description',
        image: StreetCapital,
    },
    [LenderId.TD]: {
        lender: 'TD',
        lenderId: LenderId.TD,
        namePath: 'lender.td',
        descriptionPath: 'lender.td.description',
        image: TD,
    },
    [LenderId.EQUITABLE_BANK]: {
        lender: 'EQUITABLE_BANK',
        lenderId: LenderId.EQUITABLE_BANK,
        namePath: 'lender.equitableBank',
        descriptionPath: 'lender.equitableBank.description',
        image: EquitableBank,
    },
    [LenderId.DESJARDINS]: {
        lender: 'DESJARDINS',
        lenderId: LenderId.DESJARDINS,
        namePath: 'lender.desjardins',
        descriptionPath: 'lender.desjardins.description',
        image: Desjardins,
    },
    [LenderId.DUCA]: {
        lender: 'DUCA',
        lenderId: LenderId.DUCA,
        namePath: 'lender.duca',
        descriptionPath: 'lender.duca.description',
        image: Duca,
    },
    [LenderId.ALTERNA]: {
        lender: 'ALTERNA',
        lenderId: LenderId.ALTERNA,
        namePath: 'lender.alterna',
        descriptionPath: 'lender.alterna.description',
        image: Alterna,
    },
    [LenderId.WEALTH_ONE_BANK]: {
        lender: 'WEALTH_ONE_BANK',
        lenderId: LenderId.WEALTH_ONE_BANK,
        namePath: 'lender.wealthOneBank',
        descriptionPath: 'lender.wealthOneBank.description',
        image: WealthOne,
    },
    [LenderId.XMC]: {
        lender: 'XMC',
        lenderId: LenderId.XMC,
        namePath: 'lender.xmc',
        descriptionPath: 'lender.xmc.description',
        image: XMC,
    },
    [LenderId.UNI]: {
        lender: 'UNI',
        lenderId: LenderId.UNI,
        namePath: 'lender.uni',
        descriptionPath: 'lender.uni.description',
        image: UNI,
    },
    [LenderId.EQUITABLE_BANK_CBS]: {
        lender: 'EQUITABLE_BANK',
        lenderId: LenderId.EQUITABLE_BANK_CBS,
        namePath: 'lender.equitableBankCoBranded',
        descriptionPath: 'lender.equitableBank.description',
        image: EquitableBank,
    },
    [LenderId.IG]: {
        lender: 'IG',
        lenderId: LenderId.IG,
        namePath: 'lender.ig',
        descriptionPath: 'lender.ig.description',
        image: IG,
    },
};

// Includes all the supported lenders and a generic lender fallback
export const LENDER_INFO = {
    ...SUPPORTED_LENDERS,
    '*': {
        lender: 'OTHER',
        namePath: 'lender.generic',
        descriptionPath: 'lender.generic.description',
        image: Nesto,
    },
};

export const getLender = (lenderId: number) =>
    LENDER_INFO[lenderId] || LENDER_INFO['*'];

export const LENDER_INFO_OPTIONS_BY_ID = (
    i18n: I18n,
    options?: { removeGenericLender: boolean }
): SelectOptions<number> =>
    Object.keys(options?.removeGenericLender ? SUPPORTED_LENDERS : LENDER_INFO)
        .map(
            (lenderId) =>
                ({
                    value: +lenderId || '*',
                    label: i18n._(getLender(+lenderId).namePath),
                }) as SelectOption<number>
        )
        .sort((a, b) => String(a.label).localeCompare(String(b.label)));

export const useGetHelocRateFilterLenderOptions = (i18n: I18n) => {
    const {
        value: enableHeloc,
        settings: { helocRateFilterLenderOptions },
    } = useTenantSetting('enableHeloc');

    // If the enableHeloc is not enabled or the value is not set, return an empty array
    if (!enableHeloc || !helocRateFilterLenderOptions?.length) {
        return [];
    }

    return helocRateFilterLenderOptions.map((lenderId: number) => ({
        value: lenderId,
        label: i18n._(getLender(lenderId)?.namePath || ''),
    }));
};

// It is used for the client mortgage institution
export const LENDER_CLIENT_SELECT_OPTIONS = (i18n: I18n) => [
    {
        value: 'ALTERNA',
        label: i18n._('lender.alterna'),
    },
    {
        value: 'B2B_LAURENTIAN',
        label: i18n._('lender.b2b_laurentian'),
    },
    {
        value: 'BMO',
        label: i18n._('lender.bmo'),
    },
    {
        value: 'CIBC',
        label: i18n._('lender.cibc'),
    },
    {
        value: 'CMLS',
        label: i18n._('lender.cmls'),
    },
    {
        value: 'DESJARDINS',
        label: i18n._('lender.desjardins'),
    },
    {
        value: 'DUCA',
        label: i18n._('lender.duca'),
    },
    {
        value: 'EQUITABLE_BANK',
        label: i18n._('lender.equitable_bank'),
    },
    {
        value: 'FIRST_NATIONAL',
        label: i18n._('lender.first_national'),
    },
    {
        value: 'HOME_TRUST',
        label: i18n._('lender.homeTrust'),
    },
    {
        value: 'ICICI_BANK',
        label: i18n._('lender.iciciBank'),
    },
    {
        value: 'IG',
        label: i18n._('lender.ig'),
    },
    {
        value: 'INDUSTRIAL_ALLIANCE',
        label: i18n._('lender.industrialAlliance'),
    },
    {
        value: 'LAURENTIAN',
        label: i18n._('lender.laurentian'),
    },
    {
        value: 'LENDWISE',
        label: i18n._('lender.lendwise'),
    },
    {
        value: 'MANULIFE',
        label: i18n._('lender.manulife'),
    },
    {
        value: 'MCAP',
        label: i18n._('lender.mcap_display'),
    },
    {
        value: 'MEREDIAN',
        label: i18n._('lender.meredian'),
    },
    {
        value: 'MERIX',
        label: i18n._('lender.merix'),
    },
    {
        value: 'NATIONAL_BANK',
        label: i18n._('lender.nationalBank'),
    },
    {
        value: 'OPTIMUM_MORTGAGE',
        label: i18n._('lender.optimumMortgage'),
    },
    {
        value: 'PC_FINANCIALS',
        label: i18n._('lender.pcFinancials'),
    },
    {
        value: 'PRIVATE',
        label: i18n._('lender.private'),
    },
    {
        value: 'RADIUS_FINANCIAL',
        label: i18n._('lender.radiusFinancial'),
    },
    {
        value: 'RBC',
        label: i18n._('lender.rbc'),
    },
    {
        value: 'SCOTIABANK',
        label: i18n._('lender.scotia'),
    },
    {
        value: 'SOLUTIONS_BANKING',
        label: i18n._('lender.solutionsBanking'),
    },
    {
        value: 'STREET_CAPITAL',
        label: i18n._('lender.street_capital'),
    },
    {
        value: 'TANGERINE',
        label: i18n._('lender.tangerine'),
    },
    {
        value: 'TD',
        label: i18n._('lender.td'),
    },
    {
        value: 'OTHER',
        label: i18n._('Other'),
    },
];

// it is used for the assets institution
export const INSTITUTION_OPTIONS = (i18n: I18n) => [
    { label: i18n._('lender.quest_trade'), value: 'QUESTTRADE' },
    { label: i18n._('lender.wealth_simple'), value: 'WEALTH_SIMPLE' },
    { label: i18n._('lender.etrade'), value: 'ETRADE' },
    { label: i18n._('lender.itrade'), value: 'ITRADE' },
    { label: i18n._('lender.qtrade_investor'), value: 'QTRADE_INVESTOR' },
    { label: i18n._('lender.bmo'), value: 'BMO' },
    { label: i18n._('lender.cibc'), value: 'CIBC' },
    { label: i18n._('lender.desjardins'), value: 'DESJARDINS' },
    { label: i18n._('lender.hsbc'), value: 'HSBC' },
    { label: i18n._('lender.ig'), value: 'IG' },
    { label: i18n._('lender.laurentian'), value: 'LAURENTIAN' },
    { label: i18n._('lender.nbc'), value: 'NBC' },
    { label: i18n._('lender.rbc'), value: 'RBC' },
    { label: i18n._('lender.scotia'), value: 'SCOTIA_BANK' },
    { label: i18n._('lender.sunlife'), value: 'SUNLIFE' },
    { label: i18n._('lender.tangerine'), value: 'TANGERINE' },
    { label: i18n._('lender.td'), value: 'TD' },
    { label: i18n._('lender.eqb'), value: 'EQB' },
    { label: i18n._('other'), value: 'OTHER' },
];

export const BANKING_INSTITUTION_OPTIONS = (i18n: I18n) => [
    { label: i18n._('lender.bmo'), value: 'BMO' },
    { label: i18n._('lender.cibc'), value: 'CIBC' },
    { label: i18n._('lender.desjardins'), value: 'DESJARDINS' },
    { label: i18n._('lender.hsbc'), value: 'HSBC' },
    { label: i18n._('lender.ig'), value: 'IG' },
    { label: i18n._('lender.laurentian'), value: 'LAURENTIAN' },
    { label: i18n._('lender.nbc'), value: 'NBC' },
    { label: i18n._('lender.rbc'), value: 'RBC' },
    { label: i18n._('lender.scotia'), value: 'SCOTIA_BANK' },
    { label: i18n._('lender.sunlife'), value: 'SUNLIFE' },
    { label: i18n._('lender.tangerine'), value: 'TANGERINE' },
    { label: i18n._('lender.td'), value: 'TD' },
    { label: i18n._('lender.eqb'), value: 'EQB' },
    { label: i18n._('other'), value: 'OTHER' },
];

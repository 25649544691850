import React, { useEffect, useState } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useAccount } from 'providers/auth0';

export const LdIdentify = ({ children }: any) => {
    const [initializedUserId, setInitializedUserId] = useState(0);
    const ldClient = useLDClient();
    const { account } = useAccount();

    // Wait for ldClient to be finish before identify the client
    useEffect(() => {
        const refreshIdentidy =
            ldClient && account?.id && account.id !== initializedUserId;

        if (refreshIdentidy) {
            ldClient.identify({
                key: account.email,
                ...account,
            });

            setInitializedUserId(account.id);
        }
    }, [account, initializedUserId, ldClient]);

    return <>{children}</>;
};

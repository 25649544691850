import React from 'react';

import { Trans } from '@lingui/react';
import { Box, Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';

import type { Account } from 'providers/auth/types';
import type { ChannelOnBehalfProps } from 'types/channel';

export type NewClientLoggedModalProps = {
    oldClient: Account;
    newClient: ChannelOnBehalfProps;
};

export const NewClientLogged = ({ newClient }: NewClientLoggedModalProps) => {
    const handleReload = () => (window.location.href = '/');

    return (
        <Flex
            css={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20,
            }}
        >
            <Box>
                <Trans
                    id="loggedNewClient.modal.content"
                    values={newClient}
                    components={[<b key="0" />]}
                />
            </Box>
            <Box>
                <Button onClick={handleReload}>
                    <Trans id="loggedNewClient.modal.button" />
                </Button>
            </Box>
        </Flex>
    );
};

import { Suspense, useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import {
    TenantClient,
    TenantSlug,
    appWithTenant,
    useTenant,
} from '@nestoca/multi-tenant';
import { toastDefaultOptions } from '@nestoca/ui';
import css from '@styled-system/css';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { ToastContainer } from 'react-toastify';
import { RecoilEnv } from 'recoil';
import { createGlobalStyle } from 'styled-components';

import { Confirm } from 'components/confirm/confirm';
import { MonitoringProvider } from 'components/datadog/_datadog_rum';
import ErrorBoundary from 'components/error-boundary';
import { LdIdentify } from 'components/ld-identify';
import { PromptUpdater } from 'components/prompt-updater';
import RecoilWrapper from 'components/recoil-wrapper';
import { ScrollTop } from 'components/scroll-top';
import { ThemeProvider } from 'components/theme-provider';
import { ToastProvider } from 'components/toast';
import { WarnOnBehalf } from 'components/warn-on-behalf/warn-on-behalf';
import { client as apiClient } from 'libs/api';
import { AccountRidProvider } from 'providers/account-rid/account-rid-provider';
import { AuthProvider, UserProvider } from 'providers/auth0';
import { I18nProvider } from 'providers/i18n';
import { MaintenanceProvider } from 'providers/maintenance';
import { ModalsProvider } from 'providers/modals/modals-provider';
import { TenantProvider as TenantProviderLegacy } from 'providers/tenant';
import { nesto as themeNesto } from 'theme';

import { GlobalModalWrapper } from '../components/modals/global-modal-wrapper';

import type { InterpolationFunction } from 'styled-components';
import type { Theme } from 'theme/types';

import 'styles/global.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

import '@nestoca/ui/style';
import '@nestoca/ui/variables';

type Props = {
    Component: AppProps['Component'];
    pageProps?: { _tenant?: { tenant: TenantClient }; accountRid?: string };
};

const themeStyles: InterpolationFunction<{ theme: Theme }> = css(
    themeNesto.styles
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as unknown as any;

const GlobalStyle = createGlobalStyle`
        ${themeStyles}
    `;

// Import mocking utils.
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
    require('../__mocks__/msw/next');
}

function MyApp({ Component, pageProps }: Props) {
    const { enableMaintenanceMiddleOffice, enableMaintenance } =
        pageProps._tenant?.tenant?.featureFlags || {};

    const tenant = useTenant();

    const isDev = process.env.NODE_ENV === 'development';

    // If we are in dev mode, we want to disable the duplicate atom key checking
    RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = isDev ? false : true;

    if (tenant) {
        apiClient.setBackendBaseUrl(tenant.apiBaseUrl);
        datadogRum.setGlobalContextProperty('tenant', tenant);
    }

    // TODO remove this logic once we have a proper tenant provider
    // SEAL-1428 https://nestoca.atlassian.net/browse/SEAL-1428
    const [_tenant, setTenant] = useState(tenant?.slug);
    useEffect(() => {
        if (!_tenant) {
            /**
             * We don't have a tenant in the query params.
             * Let's check if we have one in the session storage. If we do, we'll use it.
             * If all this fails, we won't load any tenant-specific CSS.
             *  */
            const slug = sessionStorage.getItem('tenant') as TenantSlug;
            setTenant(slug);
        } else {
            /**
             * We have a tenant in the query params. Let's save it in the session storage.
             */
            sessionStorage.setItem('tenant', _tenant);
        }
    }, [_tenant]);

    return (
        <>
            <Script id="gtm" strategy="lazyOnload">
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', 'GTM-57D8SD9');
                `}
            </Script>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                {tenant && tenant?.slug !== 'nesto' && (
                    <link
                        rel="stylesheet"
                        id="portal-tenant"
                        href={`theme.${tenant.slug}.css`}
                    />
                )}
            </Head>
            <AccountRidProvider>
                <I18nProvider>
                    <MaintenanceProvider
                        isEnabled={enableMaintenance || enableMaintenanceMiddleOffice}
                    >
                        <AuthProvider accountRid={pageProps.accountRid}>
                            <UserProvider>
                                <LdIdentify>
                                    <RecoilWrapper>
                                        <ThemeProvider>
                                            <TenantProviderLegacy
                                                slug={_tenant}
                                                dispatch={setTenant}
                                            >
                                                <MonitoringProvider />
                                                <ToastContainer
                                                    {...toastDefaultOptions}
                                                />
                                                <ToastProvider
                                                    autoDismiss
                                                    autoDismissTimeout={10000}
                                                >
                                                    <ModalsProvider>
                                                        <GlobalModalWrapper>
                                                            <WarnOnBehalf>
                                                                <ErrorBoundary>
                                                                    <PromptUpdater />
                                                                    <Suspense
                                                                        fallback={
                                                                            null
                                                                        }
                                                                    >
                                                                        <NextNProgress
                                                                            color="var(--color-midnight-500)"
                                                                            height={
                                                                                3
                                                                            }
                                                                            // hide spinner for now
                                                                            options={{
                                                                                showSpinner:
                                                                                    false,
                                                                            }}
                                                                        />
                                                                        <Component
                                                                            {...pageProps}
                                                                        />
                                                                    </Suspense>
                                                                </ErrorBoundary>
                                                                <ScrollTop />
                                                                <Confirm />
                                                            </WarnOnBehalf>
                                                        </GlobalModalWrapper>
                                                    </ModalsProvider>
                                                </ToastProvider>
                                            </TenantProviderLegacy>
                                            <GlobalStyle />
                                        </ThemeProvider>
                                    </RecoilWrapper>
                                </LdIdentify>
                            </UserProvider>
                        </AuthProvider>
                    </MaintenanceProvider>
                </I18nProvider>
            </AccountRidProvider>
        </>
    );
}

// On page refresh look if we have the user session the use it as LaunchDarkly Init
// to grab the user specific flags
const ldUser =
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('OfficeAuth:ldUser') &&
    JSON.parse(localStorage.getItem('OfficeAuth:ldUser'));

const clientSideID =
    (typeof window !== 'undefined' && window.config.launchDarklyKey) ||
    '60649bcbd4ba940c11f4087c';

export default withLDProvider({
    clientSideID,
    user: ldUser || {
        anonymous: true,
    },
    options: {
        bootstrap: 'localStorage',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
})(appWithTenant(MyApp as any) as any);

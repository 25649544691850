import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect';

// https://usehooks.com/useLockBodyScroll/
export function useLockBodyScroll() {
    useIsomorphicLayoutEffect(() => {
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []); // Empty array ensures effect is only run on mount and unmount
}

import type { FC, PropsWithChildren } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useTenant } from '@nestoca/multi-tenant';
import Router, { useRouter } from 'next/router';

import type { AppState } from '@auth0/auth0-react';

interface AuthProviderProps {
    accountRid?: string;
}

const onRedirectCallback = async (appState?: AppState) => {
    // Auth0 will probably come here with a returnTo appState as `/login` if not defined fallback to `/login`
    // we use this because here we dont have access to the role of the user
    // so we can't redirect to the correct page for the user
    // we'll do that in the `page/login.tsx` file `useEffect` hook
    await Router.replace(appState?.returnTo || '/login');
};

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
    children,
    // See comment below
    // accountRid,
}) => {
    const { locale, query } = useRouter();
    const tenant = useTenant();

    const loginLocale =
        (query?.ui_locales as string | undefined) || locale || 'en';

    const isServer = typeof window === 'undefined';
    const isPlaywright = !isServer && window['playwright'];

    if (!tenant || !tenant?.auth0 || typeof window === 'undefined') {
        return <>{children}</>;
    }

    return (
        <Auth0Provider
            cacheLocation="localstorage"
            domain={tenant.auth0.domain}
            clientId={tenant.auth0.clientId}
            authorizationParams={{
                audience: tenant.auth0.audience || 'https://nesto.ca/api',
                organization: tenant.auth0.organization || undefined,
                redirect_uri: `${window.location.origin}`,
                // If we are in playwright, we disable SSO
                ...(isPlaywright && {
                    connection: 'Username-Password-Cypress',
                }),
                ui_locales: loginLocale,
                // leave this commented for now.
                // BE logic for middle-office doesn't required to authorize on behalf of a client
                // we're gonna use the `x-on-behalf-of` header for that
                // if the URL contains an accountRid,
                // we pass it to Auth0 for the login /authorize
                // accountRid,
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            useRefreshTokensFallback
        >
            {children}
        </Auth0Provider>
    );
};

import { PRODUCT_TRACKER_CODE, SECURITY_TYPE } from 'constants/property';

import type { Address } from './address';
import type { MortgageInsurer } from './application';
import type { AmountFrequency, YesNo } from './index';
import type { Lender } from './lenders';

export type SubjectProperty = {
    readonly id?: number;
    isFound: boolean;
    acceptanceDate: string; // TODO evolve to date
    additionalFundAmount: number;
    address?: Address;
    appraisalDate: string;
    appraisalFees: number;
    builderName?: string;
    coOwnershipFees: CoOwnershipFees;
    constructionType: ConstructionType;
    construction?: Construction;
    estimatedPropertyValue: number;
    estimatedPropertyValueMethod: EstimatedPropertyValueMethod | '';
    fundPurpose: FundPurpose;
    garage: Garage;
    heatingType: HeatingType;
    livingSpace?: AmountSurface;
    lotSize?: AmountSurface;
    mlsListingNumber: number;
    numberOfUnits: number;
    originalMortgageAmount: number;
    propertyStyle: PropertyStyle;
    propertyType: PropertyType;
    propertyTypeOverride: string;
    purchaseDate: string; // TODO evolve to date
    purchasePrice: number;
    purpose: Purpose;
    rentalIncome?: AmountFrequency;
    schoolAndMunicipalTaxes: SchoolAndMunicipalTaxes;
    sewageType: SewageType;
    taxAuthorities: TaxAuthority[];
    taxesIncluded?: boolean;
    tenure: PropertyTenure;
    waterType: WaterType;
    yearBuilt: number;
    mortgages: Mortgages[];
    heatingCost?: AmountFrequency;
    // not the same as heatingIncluded (located in coOwnershipFees)
    heatingCostIncluded?: boolean;
    insurer?: MortgageInsurer;
    insurancePolicyNumber: string;
    improvementsMadeToProperty: boolean;
    improvedPropertyValue?: number;
    improvementAmount?: number;
    holdbackAmount: number | null;
    environmentalHazard: boolean;
    previousSaleClosingDate?: string;
    previousSalePrice?: number;
    propertyListingType?: PropertyListingType | '';
    rentalIncomeIncluded?: boolean;
    rentalIncomeRatio?: number;
    cadastreNumber?: number;
    constructionCompleteDate?: string;
    previousMortgageInsurer?: string;
    previousMortgageInsurerPolicyNumber?: string;
    previousMortgagePurchaseDate?: string;
    previousMortgagePurchasePrice?: number;
    previousMortgageBalance?: number;
    // submission notes
    occupancy?: PurposeAfterTransaction;
    existingMortgageNumber?: number;
    firstMortgageOSBalance: number | null;
    firstMortgageAccountNumber: string | null;
    grandfathered: string | null;
    originalGDS: number | null;
    originalTDS: number | null;
    mortgageSecurityPriorityRanking: SecurityPriorityRanking;
    mortgageSecurityType: SecurityType;
    productTrackerCode: ProductTrackerCode;
};

export type ProductTrackerCode =
    (typeof PRODUCT_TRACKER_CODE)[keyof typeof PRODUCT_TRACKER_CODE];

export type SecurityType = (typeof SECURITY_TYPE)[number];

export type SecurityPriorityRanking = SecurityType;

type Construction = {
    advanceType?: AdvanceType;
    builderType?: BuilderType;
    propertyValueOnceCompleted?: number;
    costOfConstruction?: number;
    landValue?: number;
};

export type MortgageProperty = {
    balance: number;
    existingLoanNumber: string;
    interestRate: number;
    interestRateType: InterestRateType;
    lender: Lender;
    maturityDate: string;
    mortgageType: MortgageType;
    payment: MortgageAmountFrequency;
    termType: TermType;
};

export type OtherProperty = {
    readonly id?: number;
    hasMortgage: string | boolean;
    currentPurpose: Purpose;
    afterTransactionPurpose: PurposeAfterTransaction;
    currentSaleStatus: PropertySaleStatus;
    address: Address;
    type: OtherPropertyType | '';
    estimatedPropertyValue: number;
    condoFees: Partial<CoOwnershipFees>;
    taxes: AmountYear;
    heatingCost: AmountFrequency;
    rentalIncome: AmountFrequency;
    purchasePrice: number;
    mortgage: MortgageProperty;
    sellingDate: string;
    applicantId?: number;
    coOwnerApplicantIds?: number[];
    taxesIncluded?: boolean;
    rentalIncomeIncluded?: boolean;
    condoFeesIncluded?: boolean;
    heatingCostIncluded?: boolean;
    rentalIncomeRatio?: number;
    bridgeLoanAmount: number;
    bridgeLoanRate: number;
};

export type PropertyListingType = 'MLS' | 'FSBO';

// TODO Validate enum value here
export type Purpose =
    | 'OWNER_OCCUPIED'
    | 'OWNER_OCCUPIED_AND_RENTAL'
    | 'RENTAL'
    | 'SECONDARY_RESIDENCE';

export type PurposeAfterTransaction =
    | 'SOLD'
    | 'OWNER_OCCUPIED'
    | 'OWNER_OCCUPIED_AND_RENTAL'
    | 'RENTAL';

export type PropertySaleStatus =
    | 'NOT_READY_YET'
    | 'LISTED_FOR_SALE'
    | 'CONDITIONALLY_SOLD'
    | 'SOLD_FIRM';
// export type Purpose =
//     | 'OWNER_OCCUPIED'
//     | 'ANY_RENTAL'
//     | 'RENTAL_1_UNIT'
//     | 'RENTAL_2-4_UNITS';

export type Garage = {
    present: boolean;
    sizeType: GarageSizeType;
    type: GarageType;
};

export type GarageType = 'DETACHED' | 'ATTACHED';

export type GarageSizeType = 'SINGLE' | 'DOUBLE' | 'TRIPLE';

export type CoOwnershipFees = {
    heatingIncluded: boolean;
    ratio?: number;
} & AmountFrequency;

export type ConstructionType = 'NEW_BUILDING' | 'EXISTING_BUILDING';

export enum EstimatedPropertyValueMethod {
    APPRAISED = 'APPRAISED',
    ESTIMATED = 'ESTIMATED',
    PURCHASE_PRICE = 'PURCHASE_PRICE',
}

export type AmountYear = { amount: number; year: number };

export type AmountUnit = {
    amount: number;
    unit: string;
};

export type AmountSurface = {
    amount: number;
    unit: Surface;
};

export type Surface = 'SQ_FEET' | 'SQ_METERS';

export type MortgageAmountFrequency = {
    amount: number;
    frequency: MortgagePaymentFrequency;
};

export type SchoolAndMunicipalTaxes = {
    amount: number;
    year: number;
    paidOnYourBehalf: boolean;
    taxHoldBackAmount: number;
};

export const TAX_AUTHORITY_TYPES = {
    PROPERTY: 'PROPERTY',
    SCHOOL: 'SCHOOL',
} as const;

export type TaxAuthorityType =
    (typeof TAX_AUTHORITY_TYPES)[keyof typeof TAX_AUTHORITY_TYPES];

export type TaxAuthority = {
    id?: number;
    taxAuthorityId: number | null;
    rollNumber: string;
    annualTaxAmount: number;
    taxAuthorityType: TaxAuthorityType;
};

export type TaxAuthorityResponse = {
    id: number;
    name: string;
    type: TaxAuthorityType;
    adjustmentDate: string;
    province: string;
};

export type TaxAuthorityParams = {
    types: TaxAuthorityType;
    provinces: string;
};

export type HeatingType =
    | 'ELECTRIC'
    | 'FORCED_AIR_OIL_GAS_ELECTRIC'
    | 'HOT_WATER'
    | 'OTHER';

export type PropertyStyle =
    | 'ONE_STOREY'
    | 'BI_LEVEL'
    | 'TWO_STORY'
    | 'SPLIT_LEVEL'
    | 'STOREY_AND_A_HALF'
    | 'THREE_STOREY'
    | 'OTHER';

// github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L419
export type PropertyType =
    | 'DETACHED'
    | 'SEMI_DETACHED'
    | 'DUPLEX_DETACHED'
    | 'DUPLEX_SEMI_DETACHED'
    | 'ROW_HOUSING'
    | 'APARTMENT_LOW_RISE'
    | 'APARTMENT_HIGH_RISE'
    | 'MOBILE'
    | 'TRI_PLEX_DETACHED'
    | 'TRI_PLEX_SEMI_DETACHED'
    | 'STACKED'
    | 'MODULAR_HOME_DETACHED'
    | 'MODULAR_HOME_SEMI_DETACHED'
    | 'FOUR_PLEX_DETACHED'
    | 'FOUR_PLEX_SEMI_DETACHED'
    | 'SeasonalHome' // Not capitalized in the back-end
    | 'OTHER';

export type OtherPropertyType =
    | 'HOUSE'
    | 'CONDO'
    | 'SECONDARY_HOME_OR_COTTAGE'
    | 'DUPLEX'
    | 'TRIPLEX'
    | 'FOURPLEX';

// Prequal
// https://github.com/nestoca/applications/blob/c0ca5b3ace89033027430831e9e4f584614dd6e4/api/prequalifications.go#L39
export type PrequalPropertyType =
    | 'HOUSE'
    | 'HOME_OR_CONDO'
    | 'CONDO'
    | 'SECOND_HOME'
    | 'SECOND_HOME_OR_COTTAGE'
    | 'SECONDARY_HOME_OR_COTTAGE'
    // This duplicate is intentional, and exists because in the back-end
    // 'SECOND_HOME_OR_COTTAGE' is different than 'SECONDARY_HOME_OR_COTTAGE'
    // in some parts of the application
    | 'DUPLEX'
    | 'TRIPLEX'
    | 'FOURPLEX'
    | 'RENTAL'
    | 'FIVE_UNITS_PLUS'
    | 'OTHER'
    | 'COTTAGE';

export type SewageType = 'MUNICIPAL' | 'SEPTIC' | 'HOLDING_TANK';

export type WaterType = 'MUNICIPAL' | 'WELL';

export type PropertyTenure = 'FREE_HOLD' | 'CONDO' | 'LEASE_HOLD';

export type FundPurpose =
    | 'HOME_IMPROVEMENT'
    | 'DEBT_CONSOLIDATION'
    | 'INVESTMENT'
    | 'GIFT_TO_RELATIVE';

export type Mortgages = {
    id?: number;
    payment?: MortgageAmountFrequency;
    balance: number;
    maturityDate?: string; // TODO evolve to Date
    lender: Lender;
    interestRate: number;
    interestRateType: InterestRateType;
    termType: TermType;
    mortgageType: MortgageType;
    insuranceQuestionOriginallyInsured: YesNo;
    insuranceQuestionRefinanceOrRenewal: YesNo;
    insuranceQuestionAddFundsOrIncreasedMortgageAmount: YesNo;
    insuranceQuestionIncreasedAmortization: YesNo;
    insuranceQuestionChangesBefore: YesNo;
    currentAmortizationMonths: number;
    currentAmortizationYears: number;
    lastClosingDate?: string;
    originallyInsured: boolean;
    mortgagePayoutAmount?: number;
    mortgageBalanceDate?: string;
    remainingTermDays?: number;
    originalTermYears?: number | null;
    originalTermMonths?: number | null;
};

export type MortgagePaymentFrequency =
    | 'WEEKLY'
    | 'ACCELERATED_WEEKLY'
    | 'BIWEEKLY'
    | 'ACCELERATED_BIWEEKLY'
    | 'SEMIMONTHLY'
    | 'MONTHLY';

export type InterestRateType =
    | 'FIXED'
    | 'VARIABLE'
    | 'ADJUSTABLE'
    | 'CAPPED_VARIABLE'
    | 'OTHER';

export type TermType = 'OPEN' | 'CLOSED' | 'CONVERTABLE';

export type MortgageType = 'STANDARD' | 'HELOC';

export type PropertyIncludedFieldName =
    | 'taxesIncluded'
    | 'rentalIncomeIncluded'
    | 'heatingCostIncluded'
    | 'condoFeesIncluded';

export type AdvanceType = 'SINGLE ADVANCE' | 'MULTIPLE ADVANCE';

export type BuilderType =
    | 'BUILDER'
    | 'CONTRACTOR'
    | 'SELF_BUILD'
    | 'PRE_FABRICATED_HOMES';

import React, { forwardRef } from 'react';

import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { shadow, variant } from 'styled-system';

import type { BoxProps } from 'reflexbox/styled-components';
import type { ShadowProps, ResponsiveValue } from 'styled-system';
import type { Assign } from 'types/components';

interface CardOwnProps extends BoxProps, ShadowProps {
    variant?: ResponsiveValue<Variant>;
}

export type CardProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    CardOwnProps
>;

type Variant =
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'summary'
    | 'warning'
    | 'success';

export const CardStyled = styled(Box)`
    /* White / White (100%) */
    background: #ffffff;

    /* Medium Shadow */
    box-shadow: 0px 8px 32px rgba(26, 26, 26, 0.24);

    border-radius: 10px;

    padding: 16px;

    ${variant({
        scale: 'cards',
        prop: 'variant',
    })}
    ${shadow}
`;

export type CardType = BoxProps & CardProps;

export const Card = forwardRef(
    (props: CardType, ref: React.Ref<HTMLDivElement>) => (
        <CardStyled ref={ref} boxShadow="none" {...props} />
    )
);

import { isEqual } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { isEmptyString } from 'utils';

import type { Address, RegisteredAddress } from 'types/address';

/**
 * Format address
 * @param address Address
 */
export const formatAddress = (address?: Address): string => {
    if (!address) return '';

    const returns: string[] = [];
    let unit = '';

    if (address.unit?.length) {
        unit = `${address.unit}-`;
    }

    returns.push(
        `${unit}${address.streetNumber || ''} ${address.street || ''}`,
        address?.city || '',
        address?.postalCode || '',
        address?.stateCode || '',
        address?.countryCode || ''
    );

    // clean empty string and return joined with comma
    return returns
        .filter((item: string) => (item ? !isEmptyString(item.trim()) : false))
        .join(', ');
};

export const formatAddressNotFound = (address?: Address): string => {
    if (!address) return '';

    const returns: string[] = [];

    returns.push(address?.stateCode || '', address?.countryCode || '');

    // clean empty string and return joined with comma
    return returns
        .filter((item: string) => (item ? !isEmptyString(item.trim()) : false))
        .join(', ');
};

/**
 * Returns the value of the first address in the array where `isCurrentAddress` is true, and undefined
 * otherwise.
 * @param addresses
 */
export const getCurrentAddress = (
    addresses?: RegisteredAddress[]
): RegisteredAddress | undefined => {
    if (!Array.isArray(addresses)) return undefined;

    return (addresses || []).find(
        (address) => address.isCurrentAddress === true
    );
};

/**
 * Returns the value of the first address in the array where `isCurrentAddress` is true, and first address
 * otherwise.
 * @param addresses
 */
export const getDefaultAddress = (
    addresses?: RegisteredAddress[]
): RegisteredAddress | undefined => {
    if (!Array.isArray(addresses)) return undefined;

    const currentAddress = (addresses || []).find(
        (address) => address.isCurrentAddress === true
    );

    // if there is isCurrentAddress
    // we set the first one to default
    return currentAddress || addresses?.length >= 1 ? addresses[0] : undefined;
};

/**
 * Concat address
 * @param address Address
 */
export const concatAddress = (address: Address) => {
    if (address) {
        return Object.values(address).reduce(
            (acc: string, partialAddress: string) =>
                acc.length ? `${acc}${partialAddress} ` : partialAddress,
            ' '
        );
    } else return undefined;
};

/**
 * Empty applicant address fields
 * @param setValue set react-hook-form value
 */
export const emptyApplicantAddressFields = (
    setValue: UseFormReturn['setValue']
) => {
    const applicantAddress: Address = {
        city: '',
        countryCode: 'CA',
        postalCode: '',
        stateCode: '',
        street: '',
        streetNumber: '',
        unit: '',
    };

    setValue('address', applicantAddress);
};

/**
 * Determine if the applicant address is the same as the main applicant address
 * @param applicantAddress applicant address
 * @param mainApplicantAddress main applicant address
 */
export const hasSameAddressAsMainApplicant = (
    applicantAddress: Address,
    mainApplicantAddress: Address
) => isEqual(applicantAddress, mainApplicantAddress);

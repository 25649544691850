import React from 'react';

import SVG from 'components/SVG';

export const AlertIcon = ({ fillColor = 'currentColor', ...rest }: any) => (
    <SVG
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <rect width="24" height="24" opacity="0" />
        <path
            d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"
            fill={fillColor}
        />
        <circle cx="12" cy="16" r="1" fill={fillColor} />
        <path
            d="M12 7a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z"
            fill={fillColor}
        />
    </SVG>
);

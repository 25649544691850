import React, { useMemo, useState } from 'react';

import css from '@styled-system/css';
import { usePopper } from 'react-popper';
import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';

import { Card } from 'components/card/new-card';
import { useTheme } from 'utils/use-theme';

import type { PopperProps as ReactPopperProps } from 'react-popper';

type PopperVariant = 'default' | 'error' | 'dark';

const styling = {
    dark: {
        fontWeight: 'var(--font-weight-5)',
        textAlign: 'left',
        fontSize: '10px',
        maxWidth: 'var(--size-13)',
        lineHeight: '12px',
    },
    default: {
        fontWeight: '--font-weight-7',
        textAlign: 'center',
    },
};

const offset = {
    'bottom-start': [-20, 8],
    'bottom-end': [20, 8],
    default: [0, 8],
};

type PopperProps = {
    children: React.ReactNode;
    isOpen: boolean;
    referenceElement: any;
    placement?: ReactPopperProps<any>['placement'];
    variant?: PopperVariant;
    overridePlacement?: boolean;
};

export const Popper = ({
    children,
    isOpen,
    referenceElement,
    placement = 'auto',
    variant = 'default',
    overridePlacement = false,
}: PopperProps) => {
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const theme = useTheme();

    const getPlacement = useMemo(() => {
        if (overridePlacement) return placement;
        return variant === 'error' ? 'right' : placement;
    }, [overridePlacement, placement, variant]);

    const { styles, attributes, state } = usePopper(
        referenceElement,
        popperElement,
        {
            modifiers: [
                {
                    name: 'arrow',
                    options: {
                        element: arrowElement,
                        padding: 10,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: offset?.[placement] || offset.default,
                    },
                },
            ],
            placement: getPlacement,
        }
    );

    return (
        <>
            {isOpen && (
                <Card
                    ref={setPopperElement}
                    style={styles.popper}
                    // @ts-ignore
                    {...attributes.popper}
                    css={css({
                        zIndex: 1000,
                        backgroundColor: theme.popper[variant].backgroundColor,
                        boxShadow: ['md', '2xl'],
                        padding: 2,
                        color: theme.popper[variant].color,
                        borderRadius: 4,
                    })}
                >
                    <>
                        <Box
                            fontSize={0}
                            css={css(styling?.[variant] || styling.default)}
                        >
                            {children}
                        </Box>
                        <Arrow
                            ref={setArrowElement}
                            data-placement={state?.placement}
                            style={styles.arrow}
                            variant={variant}
                        />
                    </>
                </Card>
            )}
        </>
    );
};

const Arrow = styled.div<{ variant: PopperVariant }>`
    position: absolute;
    font-size: 5px;
    width: 3em;
    height: 3em;
    &[data-placement*='bottom'] {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        &::before {
            border-width: 0 1.5em 1em 1.5em;
            ${({ theme, variant }) =>
                `border-color: transparent transparent ${theme.popper[variant].backgroundColor} transparent;`}
        }
    }
    &[data-placement*='top'] {
        bottom: 0;
        left: 0;
        margin-bottom: -2.9em;
        &::before {
            border-width: 1em 1.5em 0 1.5em;
            border-color: #fff transparent transparent transparent;
        }
    }
    &[data-placement*='right'] {
        left: 0;
        margin-left: -1.9em;
        &::before {
            border-width: 1.5em 1em 1.5em 0;
            ${({ theme, variant }) =>
                `border-color: transparent  ${theme.popper[variant].backgroundColor} transparent transparent;`}
        }
    }
    &[data-placement*='left'] {
        right: 0;
        margin-right: -1.9em;
        &::before {
            border-width: 1.5em 0 1.5em 1em;
            border-color: transparent transparent transparent #fff;
            ${({ theme, variant }) =>
                `border-color: transparent  transparent transparent ${theme.popper[variant].backgroundColor};`}
        }
    }
    &[data-placement*='bottom-start'] {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        &::before {
            border-width: 0 1.5em 1em 1.5em;
            ${({ theme, variant }) =>
                `border-color: transparent transparent ${theme.popper[variant].backgroundColor} transparent;`}
        }
    }
    &[data-placement*='bottom-end'] {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        &::before {
            border-width: 0 1.5em 1em 1.5em;
            ${({ theme, variant }) =>
                `border-color: transparent transparent ${theme.popper[variant].backgroundColor} transparent;`}
        }
    }
    &::before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
    }
`;

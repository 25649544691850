import React, { forwardRef } from 'react';

import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { shrinkKeyframes } from './toast';

import type { AppearanceTypes } from './toast';
import type { BoxProps } from 'reflexbox/styled-components';
import type { ResponsiveValue, BorderProps } from 'styled-system';
import type { Assign } from 'types/components';

// NOTE: invoke animation when NOT `autoDismiss` with opacity of 0 to avoid a
// paint bug in FireFox.
// https://bugzilla.mozilla.org/show_bug.cgi?id=625289
export const Countdown = ({
    autoDismissTimeout,
    opacity,
    isRunning,
    shrinkKeyframes,
    ...rest
}: any) => (
    <Box
        className="react-toast-notifications__toast__countdown"
        css={`
            animation: ${shrinkKeyframes} ${autoDismissTimeout}ms linear;
            animation-play-state: ${isRunning ? 'running' : 'paused'};
            background-color: rgba(0, 0, 0, 0.1);
            bottom: 0;
            height: 0;
            left: 0;
            opacity: ${opacity};
            position: absolute;
            width: 100%;
        `}
        {...rest}
    />
);

interface ToastEdgeOwnProps extends BoxProps, BorderProps {
    autoDismiss: any;
    autoDismissTimeout: any;
    isRunning: boolean;
    appearance?: ResponsiveValue<Appearance>;
}

export type ToastEdgeProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    ToastEdgeOwnProps
>;

type Appearance = AppearanceTypes;

export const ToastEdgeStyled = styled<any>(Box)`
    background-color: #43d011;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #43d011;
    flex-shrink: 0;
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 4px;

    ${variant({
        scale: 'toasts.edge',
        prop: 'appearance',
    })}
`;

export const ToastEdge = forwardRef(
    (
        {
            appearance,
            autoDismiss,
            autoDismissTimeout,
            isRunning,
        }: ToastEdgeProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        return (
            <ToastEdgeStyled ref={ref} appearance={appearance}>
                <Countdown
                    shrinkKeyframes={shrinkKeyframes}
                    opacity={autoDismiss ? 1 : 0}
                    autoDismissTimeout={autoDismissTimeout}
                    isRunning={isRunning}
                />
            </ToastEdgeStyled>
        );
    }
);

import { ASSET_TYPE, INSTITUTION } from 'constants/appConstants';
import { LENDER_ID, LenderId } from 'constants/lenderConstants';
import { Lender } from 'types/lenders';

import { formatAddress } from './address';

import type { I18n } from '@lingui/core';
import type { AssetFinancial, AssetTypes } from 'types/asset';

export const applicationIdentifier = (application: any /* Application */) => {
    const hasAddress = !!application?.property.address.streetNumber.length;

    if (hasAddress) {
        return formatAddress(application?.property.address);
    }

    return application?.id ? `~${application?.id}~` : 'NA';
};

export const getInstitutionLabel = (
    i18n: I18n,
    documentEntity: AssetFinancial
) => {
    const found = INSTITUTION(i18n)?.[documentEntity.institution];

    if (documentEntity.institution !== 'OTHER' && found) {
        return found;
    } else if (found) {
        return documentEntity.institutionOther;
    }

    return '';
};

/**
 * Get the asset type translated name
 * @param {I18n} i18n
 * @param {AssetType} type Asset type
 * @returns {string} Asset type or empty string
 */
export const getAssetText = (i18n: I18n, type: AssetTypes) => {
    const found = ASSET_TYPE(i18n)[type];

    return found || '';
};

/**
 * Checks if the given item (asset, income, property, etc) exists in our DB.
 * Items with id == 0 or without an id, don't exist in the Middle Office and they should
 * be handled in a different way
 */
export const isMiddleOfficeItem = <T extends { id?: number }>(item: T) =>
    item.id > 0;

export const mapLenderToLenderId = (lender: Lender): number => {
    const lenderToLenderIDMap: Partial<Record<Lender, LENDER_ID>> = {
        ['ALTERNA']: LenderId.ALTERNA,
        ['CMLS']: LenderId.CMLS,
        ['DESJARDINS']: LenderId.DESJARDINS,
        ['DUCA']: LenderId.DUCA,
        ['EQUITABLE_BANK']: LenderId.EQUITABLE_BANK,
        ['FIRST_NATIONAL']: LenderId.FIRST_NATIONAL,
        ['ICICI_BANK']: LenderId.ICICI_BANK,
        ['IG']: LenderId.IG,
        ['LENDWISE']: LenderId.LENDWISE,
        ['MCAP']: LenderId.MCAP,
        ['MERIX']: LenderId.MERIX,
        ['SCOTIABANK']: LenderId.SCOTIABANK,
        ['STREET_CAPITAL']: LenderId.STREET_CAPITAL,
        ['TD']: LenderId.TD,
        ['UNI']: LenderId.UNI,
    };

    return lenderToLenderIDMap[lender];
};

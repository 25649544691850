import { useContext } from 'react';

import { UserContext, UserContextState } from './user-context';

export const useAccount = <T = UserContextState['account']>() => {
    const { account, setAccount, token, permissions } = useContext(UserContext);

    const role = account?.role;

    const typedAccount = account as T;

    return { account: typedAccount, setAccount, role, token, permissions };
};

import React, { useState } from 'react';

import { Trans } from '@lingui/react';
import { Box, Button, Flex, Typography } from '@nestoca/ui';

import { Modal } from 'components/modal';

import styles from './confirm.module.scss';
import { useConfirm } from './use-confirm';

export type ConfirmModalProps = {
    cancelLabel?: string;
    confirmLabel?: string;
    txt?: string;
    children?: React.ReactNode;
    onCancel?: () => Promise<void> | void;
    onConfirm?: () => Promise<void> | void;
};

export const Confirm = () => {
    const [isDeleting, setIsdeleting] = useState(false);
    const {
        isVisible: isConfirmModalVisible,
        close: closeConfirmModal,
        props: confirmModalProps,
    } = useConfirm();

    const handleOnCancel = () => {
        confirmModalProps?.onCancel && confirmModalProps?.onCancel();
        closeConfirmModal();
    };

    const handleOnConfirm = async () => {
        setIsdeleting(true);

        const confirmFn = confirmModalProps?.onConfirm;

        if (confirmFn) {
            await confirmFn();
        }

        setIsdeleting(false);
        closeConfirmModal();
    };

    return (
        <Modal
            id="confirm"
            visible={isConfirmModalVisible}
            zIndex={1100}
            sx={{
                width: ['100%'],
                maxWidth: 640,
            }}
        >
            <Flex
                className={styles.confirmation_content}
                direction="column"
                gap={5}
            >
                {confirmModalProps?.children ? (
                    confirmModalProps?.children
                ) : (
                    <Box>
                        {!confirmModalProps?.txt && (
                            <Typography size={0}>
                                <Trans id="confirm.defaultContent" />
                            </Typography>
                        )}
                        <Typography size={0}>
                            {confirmModalProps?.txt}
                        </Typography>
                    </Box>
                )}
                <Flex align="center" justify="end" gap={4}>
                    <Button
                        data-testid="modal-confirm-cancel"
                        variant="ghost"
                        onClick={handleOnCancel}
                        disabled={isDeleting}
                    >
                        {confirmModalProps?.cancelLabel}
                        {!confirmModalProps?.cancelLabel && (
                            <Trans id="cancel" />
                        )}
                    </Button>
                    <Button
                        data-testid="modal-confirm-confirm"
                        onClick={handleOnConfirm}
                        isLoading={isDeleting}
                        disabled={isDeleting}
                    >
                        {confirmModalProps?.confirmLabel}
                        {!confirmModalProps?.confirmLabel && (
                            <Trans id="confirm" />
                        )}
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
};

import { isEqual } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { ApplicationMortgage } from 'types/application';

/**
 * Empty all bridge loan solicitor fields
 * @param setValue set react-hook-form value
 */
export const emptyBridgeLoanSolicitorFields = (
    setValue: UseFormReturn['setValue']
) => {
    const solicitorValues: ApplicationMortgage['solicitor'] = {
        firmName: '',
        lawyerName: '',
        phoneNumber: '',
        email: '',
        address: {
            city: '',
            countryCode: '',
            postalCode: '',
            stateCode: '',
            street: '',
            streetNumber: '',
            unit: '',
        },
    };

    setValue('bridgeLoanSolicitor', solicitorValues);
};

/**
 * Determine if we're using the same solicitor for bridge loan
 * @param solicitor solicitor details
 * @param bridgeLoanSolicitor bridge loan solicitor details
 */
export const hasSameSolicitorForBridgeLoan = (
    solicitor: object | null,
    bridgeLoanSolicitor: object | null
) => {
    if (!solicitor && !bridgeLoanSolicitor) return false;

    return isEqual(solicitor, bridgeLoanSolicitor);
};

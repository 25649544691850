import { IncomeEmployment } from 'types/applicant';
import { Applicant } from 'types/applicant';

import { concatAddress } from './address';

export const getEmploymentAddressOptions = (employments: IncomeEmployment[]) =>
    employments.map((employment) => ({
        value: concatAddress(employment?.employer?.address),
        label: concatAddress(employment?.employer?.address),
    }));

export const getApplicantProvince = (applicant: Applicant) =>
    applicant.addresses.find((address) => address?.isCurrentAddress)?.address
        ?.stateCode;

/**
 * This function returns the applicant's full name
 * @param applicant Applicant
 * @returns The applicant's full name
 */
export const getApplicantFullName = (applicant: Applicant) => {
    const { firstName, lastName } = applicant;

    return `${firstName || ''} ${lastName || ''}`.trim();
};

import React, { useState, createContext, useReducer, useEffect } from 'react';

import { modalsReducer } from './modals-reducer';

export type ModalState<P = any> = {
    isVisible?: boolean;
    props?: P;
};

export type ModalsState<P = any> = Record<string, ModalState<P>>;

export type ModalsContextState = {
    state: ModalsState;
    dispatch: React.Dispatch<any /* TODO */>;
};

export type ModalActionSetVisibility = {
    type: 'VISIBILITY';
    key: string;
    isVisible: boolean;
};

export type ModalActionUpdate<P = any> = {
    type: 'UPDATE';
    key: string;
    data: ModalState<P>;
};

export type ModalAction = ModalActionSetVisibility | ModalActionUpdate;

const INITIAL_CONTEXT: ModalsContextState = {
    state: {},
    dispatch: () => {}, // eslint-disable-line
};

const INITIAL_STATE: ModalsState = {};

export const ModalsContext = createContext<ModalsContextState>(INITIAL_CONTEXT);

export const ModalsProvider = ({ children }: any) => {
    // Holds modals state
    const [state, dispatch] = useReducer<
        React.Reducer<ModalsState, ModalAction>
    >(modalsReducer, INITIAL_STATE);

    const [contextValue, setContextValue] = useState<ModalsContextState>({
        state,
        dispatch,
    });

    // Update context value and trigger re-render
    // This patterns avoids unnecessary deep renders
    // https://reactjs.org/docs/context.html#caveats
    useEffect(() => {
        setContextValue((contextValue: ModalsContextState) => ({
            ...contextValue,
            state,
        }));
    }, [state]);

    return (
        <ModalsContext.Provider value={contextValue}>
            {children}
        </ModalsContext.Provider>
    );
};

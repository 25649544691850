import React from 'react';

import { Flex, Typography } from '@nestoca/ui';

import { useI18n } from 'providers/i18n/use-i18n';

type Props = {
    text: string;
};

export const SuccessfulToastContent = ({ text }: Props) => {
    const { i18n } = useI18n();

    return (
        <Flex data-testid="toast-message" direction="column" gap={1}>
            <Typography size={1} textColor="var(--color-brand)">
                {i18n._('successful.toast.title')}
            </Typography>
            <Typography
                textColor="var(--color-gray-3)"
                style={{ fontSize: '12px' }}
            >
                {text}
            </Typography>
        </Flex>
    );
};

import React, { memo, useRef } from 'react';
import { useEffect } from 'react';
import { useId } from 'react';

import { Box, ButtonIcon, Flex, Heading } from '@nestoca/ui';
import css from '@styled-system/css';
import { CgClose } from 'react-icons/cg';
import * as ReactIs from 'react-is';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { Overlay } from 'components/modal/overlay';
import { useKeyPress } from 'utils/hooks';
import { useTheme } from 'utils/use-theme';

import { Portal } from './portal';

import type { SystemStyleObject } from '@styled-system/css';
import type { BoxProps } from 'reflexbox/styled-components';

type Variant = 'primary' | 'success' | 'error' | 'ghost';

type ModalProps = {
    className?: string;
    children?: React.ReactNode;
    id?: string;
    visible?: boolean;
    // DOC: title={<FooBar>Foo Bar</FooBar>} TS will warn if component but is still good to use
    header?: React.ReactNode;
    title?: React.ReactNode | string | JSX.Element;
    closable?: boolean;
    onOk?: (e: React.MouseEvent<HTMLElement>) => void;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
    onClose?: (e?: React.MouseEvent<HTMLElement>) => void;
    afterClose?: () => void;
    // TODO Keep here for future needs
    // overlayClosable?: boolean;
    // footer?: React.ReactNode;
    // okText?: React.ReactNode;
    // cancelText?: React.ReactNode;
    // okButtonProps?: ButtonProps;
    // cancelButtonProps?: ButtonProps;
    overlay?: boolean;
    keyboard?: boolean;
    closeIcon?: React.ReactNode;
    overlayProps?: BoxProps;
    zIndex?: number;
    variant?: Variant;
    closeOnEscape?: boolean;
    closeOnClickOutside?: boolean;
} & BoxProps;

type SxProps = {
    sx?: SystemStyleObject;
};

function omit(keys: string[], obj: any) {
    return keys.reduce((a, e) => {
        // Destructuring object and keep `rest` so we ignore the prop we want to omit

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [e]: omit, ...rest } = a || {};

        return rest;
    }, obj);
}

function getDisplayName(Component: React.ComponentType<any>) {
    return Component.displayName || Component.name || 'Component';
}

export const Modal = memo(
    ({
        className,
        overlay = true,
        maxWidth,
        sx,
        title,
        header,
        children,
        closable = true,
        onClose,
        visible = false,
        closeOnEscape = false,
        zIndex = 800,
        variant = 'primary',
        closeOnClickOutside = false,
        ...rest
    }: ModalProps & SxProps) => {
        const theme = useTheme();
        const modalRef = useRef<HTMLDivElement>(null);

        const mId = useId();
        const generatedId = ReactIs.isElement(children)
            ? `${getDisplayName(
                  (children as React.FunctionComponentElement<any>).type
              )}${mId}`
            : mId;

        const escPress = useKeyPress('Escape');

        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                onClose && onClose();
            }
        };

        useEffect(() => {
            if (visible && escPress && closeOnEscape) {
                onClose();
            }
        }, [escPress, visible]);

        useEffect(() => {
            if (closeOnClickOutside) {
                document.addEventListener('mousedown', handleClickOutside);
            }

            return () => {
                if (closeOnClickOutside) {
                    document.removeEventListener(
                        'mousedown',
                        handleClickOutside
                    );
                }
            };
        }, [closeOnClickOutside]);

        if (!visible) return null;

        const titleColor = theme.modals?.[variant]?.borderColor;

        const modalContentSx = { ...omit(['width', 'maxWidth'], sx) };

        return (
            <Portal>
                <Box
                    className="modal"
                    data-testid={rest?.id || generatedId}
                    css={css({
                        // @ts-ignore
                        position: 'fixed',
                        // @ts-ignore
                        zIndex: zIndex,
                        // @ts-ignore
                        inset: 0,
                        // @ts-ignore
                        overflowY: 'auto',
                        // @ts-ignore
                        lineHeight: '24px',
                        // eslint-disable-next-line
                        // @ts-ignore
                        '-webkitFontSmoothing': 'antialiased',
                        // @ts-ignore
                        '-webkitTextSizeAdjust': '100%',
                        // @ts-ignore
                        '-msTextSizeAdjust': '100%',
                        // @ts-ignore
                        '-mozOsxFontSmoothing': 'grayscale',
                    })}
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                >
                    <Flex
                        data-testid="modal-wrapper"
                        css={css({
                            justifyContent: 'center',
                            minHeight: '100vh',
                            alignItems: ['stretch', null, 'center'],
                        })}
                    >
                        {/* Overlay */}
                        {overlay && <Overlay />}
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <Box
                            data-testid="browser-trick"
                            as="span"
                            css={css({
                                display: ['none', null, 'inline-block'],
                                verticalAlign: ['middle', null, 'middle'],
                                height: ['100vh', null, '100vh'],
                            })}
                        >
                            &#8203;
                        </Box>
                        {/* Modal panel show/hide base on modal state */}
                        <ModalContentStyled
                            className={className}
                            ref={modalRef}
                            data-testid="modal-panel"
                            variant={variant}
                            {...rest}
                            css={css({
                                verticalAlign: 'middle',
                                marginY: [0, null, 4],
                                minWidth: ['unset', null, 640],
                                // @ts-ignore
                                maxWidth: sx?.maxWidth || maxWidth,
                                ...modalContentSx,
                            })}
                        >
                            {/* Closable */}
                            {closable && onClose && (
                                <ButtonIcon
                                    aria-label="modal-close"
                                    data-testid="modal-close"
                                    isRound
                                    size="medium"
                                    variant="secondary"
                                    icon={<CgClose title={null} />}
                                    onClick={onClose}
                                    style={{
                                        position: 'absolute',
                                        inset: 'var(--spacing-4) var(--spacing-4) auto auto',
                                        zIndex: 100,
                                    }}
                                />
                            )}
                            {/* Modal Header ? */}
                            {header && <ModalHeader>{header}</ModalHeader>}
                            {/* Modal title ? */}
                            {title && (
                                <Box
                                    data-testid="modal-header"
                                    style={{
                                        padding:
                                            'var(--spacing-7) var(--spacing-6) var(--spacing-0)',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {!ReactIs.isElement(title) && (
                                        <Heading
                                            id="modal-title"
                                            weight={7}
                                            height={4}
                                            textColor={titleColor}
                                            data-testid="modal-title"
                                            style={{
                                                margin: 'var(--spacing-0)',
                                            }}
                                        >
                                            {title}
                                        </Heading>
                                    )}
                                    {ReactIs.isElement(title) && title}
                                </Box>
                            )}
                            {/* Modal Body */}
                            <Box
                                className="modal-body"
                                data-testid="modal-body"
                                style={{
                                    padding:
                                        'var(--spacing-5) var(--spacing-6)',
                                }}
                            >
                                {children}
                            </Box>
                        </ModalContentStyled>
                    </Flex>
                </Box>
            </Portal>
        );
    }
);

const ModalHeader = memo(
    ({
        sx,
        children,
        ...rest
    }: BoxProps &
        SxProps & { color?: any; children?: React.ReactNode; id?: string }) => (
        <Box
            id="modal-header"
            {...rest}
            css={css({
                minHeight: 'auto',
                backgroundColor: ['white', null, 'transparent'],
                boxShadow: [
                    '0px 3px 5px rgba(255, 255, 255, 0.1)',
                    null,
                    'none',
                ],
                ...sx,
            })}
        >
            {children}
        </Box>
    )
);

const ModalContentStyled = styled(Box)(
    {
        position: 'relative',
        display: 'inline-block',

        backgroundColor: 'white',
        borderRadius: '0.5rem',
        textAlign: 'left',
        // Can have overflow hidden because it will cut the select dropdown
        // overflow: 'hidden',
        boxShadow: 'lg',
        transform:
            'translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)',
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '150ms',
        width: '100%',
    },
    variant({ prop: 'variant', scale: 'modals' })
);

import React, { useEffect } from 'react';

import { Modal } from 'components/modal';
import { useAccount, useClient } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import useChannel from 'utils/use-channel';

import { NewClientLogged } from './new-client-logged';

import type { NewClientLoggedModalProps } from './new-client-logged';
import type { Account, BrokerAccount } from 'providers/auth/types';
import type { ChannelOnBehalfProps } from 'types/channel';

export const WarnOnBehalf = ({ children }: any) => {
    const { i18n } = useI18n();
    const [channel] = useChannel<ChannelOnBehalfProps>('onBehalfUser');
    const { client } = useClient();
    const { role } = useAccount<BrokerAccount>();
    const modal = useModal<NewClientLoggedModalProps>('onBehalfOtherClient');

    if (channel) {
        channel.onmessage = (broadcast) => {
            if (
                client &&
                broadcast &&
                (client as Account).rid !== broadcast.rid
            ) {
                modal.open({
                    oldClient: client,
                    newClient: broadcast,
                });
            }
        };
    }

    useEffect(() => {
        if (channel && role === 'broker' && client?.id) {
            channel.postMessage({
                id: client.id,
                rid: client.rid,
                firstName: client.firstName,
                lastName: client.lastName,
            });
        }
    }, [channel, role, client]);

    return (
        <>
            {children}
            <Modal
                visible={modal.isVisible}
                sx={{ width: ['100%'], maxWidth: 640 }}
                title={i18n._(
                    'loggedNewClient.modal.title',
                    modal.props?.newClient
                )}
                variant="error"
            >
                <NewClientLogged {...modal.props} />
            </Modal>
        </>
    );
};

import { i18n } from '@lingui/core';
import { differenceInDays } from 'date-fns';

import { InvestorId } from 'types/application';
import { HelocProductType } from 'types/heloc';
import { type Product, type UIHelocProduct, type UIProduct } from 'types/rates';

export const extractRelevantRateInfo = (specificRate: Product): UIProduct => {
    const {
        gds,
        gdsMax,
        tds,
        tdsMax,
        creditScoreMax,
        creditScoreMin,
        ltvMin,
        ltvMax,
        ...rest
    } = specificRate;
    const minimumLTV = ltvMin / 100;
    const maximumLTV = ltvMax / 100;

    const ltvMinPercentage = formatPercentage(minimumLTV);
    const ltvMaxPercentage = formatPercentage(maximumLTV);
    return {
        ltv: `${ltvMinPercentage} - ${ltvMaxPercentage}`,
        beacon: `${creditScoreMin} - ${creditScoreMax}`,
        gds: `${gdsMax || gds} / ${tdsMax || tds}`,
        notes: 'N/A',
        ...rest,
    };
};

export const extractRelevantHelocRateInfo = (
    specificRate: HelocProductType
): UIHelocProduct => {
    const {
        gdsMax,
        tdsMax,
        ltvCreditLimitMax,
        ltvCombinedMax,
        creditScoreMax,
        creditScoreMin,
        standalone,
        ...rest
    } = specificRate;

    const ltvMaxPercentage = formatPercentage(
        standalone ? ltvCreditLimitMax / 100 : ltvCombinedMax / 100
    );
    return {
        ltv: `${formatPercentage(0)} - ${ltvMaxPercentage}`,
        beacon: `${creditScoreMin} - ${creditScoreMax}`,
        gds: `${gdsMax} / ${tdsMax}`,
        notes: 'N/A',
        ...rest,
    };
};

export const formatPercentage = (num) => {
    return Number(num).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2,
    });
};

export type RateHoldWarnings = {
    isNewApplication: boolean;
    daysToClose: number;
    rateExpirationDate: any;
};

export const getDaysUntilDate = (dateToCalulate: Date) => {
    return Math.abs(differenceInDays(new Date(), dateToCalulate));
};

export const getRateHoldExpiration = (rateExpirationDate: string) => {
    if (!rateExpirationDate) {
        return null;
    }
    const today = new Date();
    const expirationDate = new Date(rateExpirationDate);

    if (expirationDate < today) {
        return i18n._('rateHold.expired');
    }

    const daysRemaining = getDaysUntilDate(expirationDate);
    return i18n._('daysLeft', { days: daysRemaining });
};

const FIFTEEN_DAYS = 15;
const ONE_DAY = 1;

export const getIsBetweenOneAndFifteenDays = ({
    daysToClose,
    rateHold,
}: {
    daysToClose: number;
    rateHold: number;
}) => {
    const differenceInDays = Math.abs(daysToClose - rateHold);
    return differenceInDays >= ONE_DAY && differenceInDays <= FIFTEEN_DAYS;
};

export const getIsRateLockShorterThanClosingDate = ({
    isNewApplication,
    daysToClose,
    rateHold,
}: {
    isNewApplication: boolean;
    daysToClose: number;
    rateHold: number;
}) => {
    if (isNewApplication) return false;

    return daysToClose >= rateHold;
};

export const getIsRateLockRisky = ({
    isNewApplication,
    daysToClose,
    rateHold,
}: {
    isNewApplication: boolean;
    daysToClose: number;
    rateHold: number;
}) => {
    if (!isNewApplication) return false;

    return daysToClose >= rateHold;
};

type RateHoldWarning = { message: string };

export const checkForRateHoldWarnings = ({
    isNewApplication,
    daysToClose,
    rateExpirationDate,
}: RateHoldWarnings): RateHoldWarning | undefined => {
    const rateHold = getDaysUntilDate(rateExpirationDate);

    const isBetweenOneAndFifteenDays = getIsBetweenOneAndFifteenDays({
        daysToClose,
        rateHold,
    });

    const isRateLockShorterThanClosingDate =
        getIsRateLockShorterThanClosingDate({
            isNewApplication,
            daysToClose,
            rateHold,
        });

    const isRateLockRisky = getIsRateLockRisky({
        isNewApplication,
        daysToClose,
        rateHold,
    });

    switch (true) {
        case isRateLockRisky:
            return {
                message: 'submissionNotes.submit.error.rateLock.warning',
            };
        case isRateLockShorterThanClosingDate:
            return {
                message: 'submissionNotes.submit.error.rateLock.block',
            };
        case isBetweenOneAndFifteenDays:
            return {
                message: 'submissionNotes.submit.error.rateLock.close',
            };
        default:
            return undefined;
    }
};

export const sortRates = (rates) =>
    [...rates].sort((a, b) => {
        // First we filter by best rate
        if (a.bestRate !== b.bestRate) {
            return a.bestRate - b.bestRate;
        } else {
            // Then we filter by Nesto Gold
            if (
                a.investorId === InvestorId.NESTO_WAREHOUSE ||
                b.investorId === InvestorId.NESTO_WAREHOUSE
            ) {
                return a.investorId === 6 ? -1 : 1;
            } else {
                // Finally we filter by commission
                return a.commissionBPS - b.commissionBPS;
            }
        }
    });

export const splitRatesAfterThirdItem = (rates: Product[]) => {
    const firstThree = rates.slice(0, 3);
    const rest = rates.slice(3);
    return [firstThree, rest];
};

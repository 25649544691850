import React from 'react';

import Tippy, { TippyProps } from '@tippyjs/react';
import cn from 'classnames';

import styles from './tooltip.module.scss';
import { TooltipProps } from './types';
import 'tippy.js/dist/tippy.css';

export const Tooltip = ({
    children,
    className,
    content,
    type = 'light',
    ...rest
}: TooltipProps) => {
    const defaultOptions: TippyProps = {
        className: cn(className, styles.tooltip, styles[`tooltip--${type}`]),
        arrow: true,
        duration: 0,
        placement: 'top',
    };

    return (
        <Tippy content={content} {...defaultOptions} {...rest}>
            {children}
        </Tippy>
    );
};

import pino from 'pino';

// Leave this here for now, but we should probably use pino-multi-stream
// to send logs to datadog if the DD agent on k8s is not configured correctly
// https://github.com/ovhemert/pino-datadog/blob/a716e82353ec914bb8d0b84a5c96d04392a2b31f/docs/API.md
//
// import datadog from 'pino-datadog';
// import pinoms from 'pino-multi-stream';
// // create the datadog destination stream
// const writeStream = await datadog.createWriteStream()
// // create pino logger
// const logger = pinoms({ streams: [{ stream: writeStream }] })

// create pino logger
const logger = pino({
    browser: {
        // transmit: {
        //     level: process.env.LOG_LEVEL || 'info',
        //     send
        // },
    },
    // level can be one of these
    // LOG_LEVEL: "trace" | "debug" | "info" | "warn" | "error" | "fatal" | "silent"
    // Value: 10 | 20 | 30 | 40 | 50 | 60 | Infinity
    // @ref: https://getpino.io/#/docs/api?id=loggerlevel-string-gettersetter
    level: process.env.LOG_LEVEL || 'warn',
    base: {
        env: process.env.ENV || 'n/a',
        // env: process.env.NODE_ENV,
        // could be nice to have the commit sha here
        // revision: process.env.GITHUB_COMMIT_SHA,
    },
    // https://getpino.io/#/docs/help?id=log-levels-as-labels-instead-of-numbers
    // https://benlimmer.com/2020/10/23/make-pino-log-messages-appear-with-the-correct-status-in-datadog/
    formatters: {
        level(level: string) {
            return { level };
        },
    },
});

export default logger;

import type {
    ModalAction,
    ModalActionSetVisibility,
    ModalActionUpdate,
    ModalsState,
} from './modals-provider';

const setVisibility = (
    state: ModalsState,
    { key, isVisible }: ModalActionSetVisibility
) => ({
    ...state,
    [key]: {
        ...state?.[key],
        isVisible,
    },
});

const update = (state: ModalsState, { key, data }: ModalActionUpdate) => ({
    ...state,
    [key]: {
        ...state?.[key],
        ...data,
        props: {
            ...state?.[key]?.props,
            ...data?.props,
        },
    },
});

const INITIAL_STATE: ModalsState = {};

export const modalsReducer = (state = INITIAL_STATE, action: ModalAction) => {
    switch (action.type) {
        case 'VISIBILITY':
            return setVisibility(state, action);
        case 'UPDATE':
            return update(state, action);
        default:
            return state;
    }
};

import { i18n } from '@lingui/core';

import { Application, SectionError } from 'types/application';
import { Qualification } from 'types/qualification';

type AssetsAndDownpaymentHasErrorType = {
    application?: Application;
    qualifications: Qualification;
    locale: string;
};

export const assetsAndDownpaymentHasError = ({
    application,
    qualifications,
    locale,
}: AssetsAndDownpaymentHasErrorType) => {
    if (!application) {
        return;
    }

    const assetsDownpaymentsQualificationState =
        qualifications?.sectionStates?.downPayment;

    const errors: SectionError[] = [];

    if (
        assetsDownpaymentsQualificationState.invalid &&
        assetsDownpaymentsQualificationState.invalidFields
    ) {
        const humanReadableInvalidFields =
            assetsDownpaymentsQualificationState.invalidFields.map(
                (fieldName) => i18n._(fieldName, { locale })
            );

        errors.push({
            title: 'discrepancyBanner.title',
            body: 'discrepancyBanner.error',
            fields: assetsDownpaymentsQualificationState.invalidFields,
            values: {
                numberOfDiscrepancies: humanReadableInvalidFields.length,
                missingFields: humanReadableInvalidFields.join(', '),
            },
        });
    }

    return errors;
};

import React from 'react';

import css from '@styled-system/css';
import { Box } from 'reflexbox/styled-components';

import { useLockBodyScroll } from 'utils/use-lock-body-scroll';

type Props = {
    // TODO: keep here for future needs, we don't support that at the moment
    // overlayClosable?: boolean;
};

export const Overlay: React.FC<Props> = () => {
    useLockBodyScroll();

    return (
        <Box
            data-testid="modal-overlay"
            css={css({
                position: 'fixed',
                inset: 0,
                backgroundColor: 'overlay',
                transitionProperty: 'opacity',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: '150ms',
            })}
            aria-hidden="true"
        />
    );
};

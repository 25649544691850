import React, { useRef, useState } from 'react';

import { Trans } from '@lingui/react';
import cx from 'classnames';
import Link from 'next/link';
import { Flex } from 'reflexbox/styled-components';

import { Popper } from 'components/popper/popper';

import styles from './button-nav.module.scss';

import type { LinkProps } from 'next/link';

type ComponentProps = {
    disabled?: boolean;
    className?: string;
    active?: boolean;
    justifyEnd?: boolean;
    variant?: 'primary' | 'secondary';
    popper?: {
        isVisible: boolean;
        text: string;
    };
    onClick?: (event: any) => void;
} & LinkProps;

export const ButtonNav = ({
    href,
    as,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    locale,
    className,
    children,
    disabled,
    popper,
    active = false,
    variant = 'primary',
    justifyEnd = false,
    ...rest
}: React.PropsWithChildren<ComponentProps>) => {
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const referenceElement = useRef(null);

    return disabled ? (
        <span
            role="button"
            className={cx(
                {
                    [styles.buttonNav]: true,
                    [styles.disabled]: true,
                    [styles.active]: active,
                    [styles.primary]: variant === 'primary',
                    [styles.secondary]: variant === 'secondary',
                    [styles.justifyEnd]: justifyEnd,
                },
                className
            )}
            {...rest}
        >
            {children}
        </span>
    ) : (
        <Flex
            ref={referenceElement}
            onMouseOver={() => {
                setIsPopperOpen(true);
            }}
            onMouseLeave={() => {
                setIsPopperOpen(false);
            }}
        >
            <Link
                href={href}
                as={as}
                role="button"
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                passHref={passHref}
                prefetch={prefetch}
                locale={locale}
                legacyBehavior
            >
                <a
                    role="button"
                    className={cx(
                        {
                            [styles.buttonNav]: true,
                            [styles.active]: active,
                            [styles.primary]: variant === 'primary',
                            [styles.secondary]: variant === 'secondary',
                            [styles.justifyEnd]: justifyEnd,
                        },
                        className
                    )}
                    {...rest}
                >
                    {children}
                </a>
            </Link>
            {popper?.isVisible && (
                <Popper
                    isOpen={isPopperOpen}
                    referenceElement={referenceElement.current}
                >
                    <Trans id={popper.text} />
                </Popper>
            )}
        </Flex>
    );
};

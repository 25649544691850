import React, { forwardRef } from 'react';

import Link from 'next/link';
import * as ReactIs from 'react-is';
import { Flex, Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { shadow, variant } from 'styled-system';

import type { BoxProps } from 'reflexbox/styled-components';
import type { ShadowProps, ResponsiveValue } from 'styled-system';
import type { Assign } from 'types/components';

interface ButtonOwnProps extends BoxProps, ShadowProps {
    // As prop is defined into BoxProps
    // as?: React.ElementType;
    variant?: ResponsiveValue<Variant>;
    size?: ResponsiveValue<Size>;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
}

export type ButtonProps = Assign<
    React.ComponentPropsWithRef<'button'>,
    ButtonOwnProps
> & { href?: string; target?: string; rel?: string };

type Variant = 'primary' | 'secondary' | 'ghost' | 'plain';
type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export const ButtonStyled = styled(Box)`
    display: inline-flex;
    gap: var(--space-1);
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    border-color: transparent;
    border-width: 2px;

    font-weight: 700;

    padding: 4px 12px;

    transition: all 250ms ease;

    :hover:not(.disabled),
    :focus:not(.disabled) {
        outline: none;
        box-shadow: 0px 0px 2px #0066ff;
    }

    ${variant({
        scale: 'buttons',
        prop: 'variant',
    })}

    ${variant({
        scale: 'buttonSizes',
        prop: 'buttonSize',
    })}

    ${shadow}
`;

export type ButtonType = BoxProps & ButtonProps;

export const Button = forwardRef(
    (
        {
            variant = 'primary',
            size = 'medium',
            boxShadow,
            type = 'button',
            iconLeft,
            iconRight,
            className,
            disabled,
            ...rest
        }: ButtonType,
        ref: React.Ref<HTMLButtonElement>
    ) => (
        <ButtonStyled
            as="button"
            ref={ref}
            boxShadow={boxShadow}
            type={type}
            variant={variant}
            // did not use size because it is already an variant of Box component
            // that will set width and height to the value sent
            // https://rebassjs.org/reflexbox/#layout-props
            // width: small;
            // height: small;
            buttonSize={size}
            {...rest}
            disabled={disabled}
            className={`${disabled ? 'disabled' : ''} ${className || ''}`}
        >
            <>
                {iconLeft && <ChildContainer>{iconLeft}</ChildContainer>}
                {rest.children &&
                    ReactIs.isElement(rest.children) &&
                    rest.children}
                {rest.children && !ReactIs.isElement(rest.children) && (
                    <ChildContainer>{rest.children}</ChildContainer>
                )}
                {iconRight && <ChildContainer>{iconRight}</ChildContainer>}
            </>
        </ButtonStyled>
    )
);

export type ButtonLinkType = BoxProps & ButtonProps & { href?: string };

export const ButtonLink = forwardRef(
    (
        { children, href, disabled, ...rest }: ButtonLinkType,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        // when disabled we dont want the href link to be added
        return disabled ? (
            <Button
                ref={ref}
                as="a"
                variant="primary"
                disabled={disabled}
                {...rest}
            >
                {children}
            </Button>
        ) : (
            <Link href={href}>
                <Button
                    ref={ref}
                    variant="primary"
                    disabled={disabled}
                    {...rest}
                >
                    {children}
                </Button>
            </Link>
        );
    }
);

export type ButtonIconProps = { icon: React.ReactNode } & Omit<
    ButtonType,
    'iconLeft' | 'iconRight'
>;
export const ButtonIcon = forwardRef(
    ({ icon, ...rest }: ButtonIconProps, ref: React.Ref<HTMLButtonElement>) => (
        <Button ref={ref} {...rest}>
            {icon}
        </Button>
    )
);

const ChildContainer = (props: any) => {
    return (
        <Flex
            css={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'inline-flex',
            }}
            {...props}
        />
    );
};

import React from 'react';

import { Trans } from '@lingui/react';
import { Button, Card, Flex, Heading, Typography } from '@nestoca/ui';
import { BsExclamationTriangleFill } from 'react-icons/bs';

import styles from './error-info.module.scss';
import RidLink from './rid-link';

const SUPPORT_URL =
    'https://nestoca.atlassian.net/servicedesk/customer/portal/6/group/25/create/97';

const ErrorInfo = () => {
    return (
        <Flex className={styles['error-info']} align="center" justify="center">
            <Card className={styles.card}>
                <Flex className={styles.card__header} gap={4} as="header">
                    <BsExclamationTriangleFill
                        size={32}
                        color="var(--color-yellow-600)"
                    />
                    <Heading
                        weight={7}
                        height={3}
                        textColor="var(--color-brand)"
                    >
                        <Trans id="errorBoundary.title" />
                    </Heading>
                </Flex>
                <Flex
                    className={styles.card__content}
                    direction="column"
                    gap={5}
                >
                    <Typography
                        size={0}
                        height={3}
                        textColor="var(--color-brand)"
                        as="p"
                    >
                        <Trans
                            id="errorBoundary.description"
                            components={{
                                0: (
                                    <a
                                        className={
                                            styles['support-portal-link']
                                        }
                                        href={SUPPORT_URL}
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                ),
                                1: (
                                    <span
                                        className={styles['text--semibold']}
                                    />
                                ),
                            }}
                            values={{
                                supportPortalUrl: SUPPORT_URL,
                            }}
                        />
                    </Typography>
                    <RidLink />
                    <Button
                        size="medium"
                        onClick={() => window.location.reload()}
                    >
                        <Trans id="reload" />
                    </Button>
                </Flex>
            </Card>
        </Flex>
    );
};

export default ErrorInfo;

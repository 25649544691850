import { useLingui } from '@lingui/react';

import { log } from 'utils/logging';

import type { I18n } from '@lingui/core';

const isServer = typeof window === 'undefined';

export const setLangue = (i18n: I18n, language: string) => {
    try {
        !isServer && localStorage?.setItem('OfficeAuth:lang', language);

        i18n.activate(language);
    } catch (error) {
        log({ tag: 'setLangue error', error, data: i18n });
    }
};

export const useI18n = () => {
    const { i18n } = useLingui();
    const setLanguage = (language: string) => setLangue(i18n, language);

    return {
        i18n,
        setLanguage,
    };
};

import * as R from 'ramda';
import { atom, atomFamily, selectorFamily, useSetRecoilState } from 'recoil';

import { client as apiClient } from 'libs/api';
import { DocumentStep } from 'types/documents';
import { DocumentVisibility } from 'types/documents';
import { isEmpty } from 'utils';

import type {
    ApplicantDocument,
    DocumentCategory,
    DocumentCounts,
    DocumentType,
    GroupedDocuments,
    DocState,
    DocumentTypesData,
    DocumentFile,
} from 'types/documents';

export type DocumentQueryParams = {
    applicationId: number;
    applicantId: number;
    step: DocumentStep;
    docStates?: DocState[];
};

const documentTypeOther: [
    DocumentType,
    DocumentType,
    DocumentType,
    DocumentType,
] = [
    'OTHER_INCOME_DOCUMENT',
    'OTHER_IDENTIFICATION_DOCUMENT',
    'OTHER_PROPERTIES_DOCUMENT',
    'OTHER_FINANCIALS_DOCUMENT',
];

export const getDocuments = selectorFamily({
    key: 'GetDocumentsFamily',
    get:
        ({
            applicationId,
            applicantId,
            step,
        }: Omit<DocumentQueryParams, 'docStates'>) =>
        async () => {
            try {
                const { data } = await apiClient.getDocuments(
                    applicationId,
                    applicantId,
                    { step }
                );

                return (
                    data
                        .map((document) => ({
                            ...document,
                            // sorting files by updated date
                            // @ts-ignore
                            files: R.sortWith([R.descend(R.prop('updated'))])(
                                document?.files || []
                            ),
                        }))
                        // sorting documents by composite key OR put the "other" at the end of the list
                        .sort((a, b) => {
                            // put the "other" at the end of the list
                            if (documentTypeOther.includes(a.documentType))
                                return 1;
                            if (documentTypeOther.includes(b.documentType))
                                return -1;

                            // order by composite key
                            const idA = `${a.applicationId}-${a.applicantId}-${
                                a.documentType
                            }-${a.year ?? 0}-${a.entityId ?? 0}}`;

                            const idB = `${b.applicationId}-${b.applicantId}-${
                                b.documentType
                            }-${b.year ?? 0}-${b.entityId ?? 0}}`;

                            if (idA < idB) {
                                return -1;
                            }
                            if (idA > idB) {
                                return 1;
                            }

                            return 0;
                        }) as ApplicantDocument[]
                );
            } catch (error) {
                console.error('getDocuments error', error);
                return [];
            }
        },
});

export const getAllFilesInDocuments = selectorFamily({
    key: 'getAllFilesInDocuments',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const documents = get(
                applicantDocuments({
                    applicationId,
                    applicantId,
                    step: DocumentStep.All,
                })
            );

            let allFiles = [];

            documents.forEach((document) => {
                const files = document.files.map((file) => ({
                    ...file,
                    documentType: document.documentType,
                }));
                allFiles = [...allFiles, ...files];
            });
            return allFiles;
        },
});

export const getDocumentsCounts = selectorFamily({
    key: 'GetDocumentsFamily',
    get:
        ({
            applicationId,
            step,
        }: Pick<DocumentQueryParams, 'applicationId' | 'step'>) =>
        async ({ get }) => {
            get(applicationDocumentsCountsRequestIDState);

            try {
                const { data } = await apiClient.getDocumentsCounts(
                    applicationId,
                    {
                        step,
                    }
                );

                return data;
            } catch (error) {
                console.error('getDocumentsCounts error', error);
                // TODO maybe we should let the error bubble ?
                return {} as DocumentCounts;
            }
        },
});

/**
 * DocumentCompositeKey = string `{applicationId}/{applicantId}/{documentType}/{year || 0}/ {entityId || 0}`
 */
type DocumentCompositeKey = string;

export const getCompositeKeyFromDocument = ({
    applicationId,
    applicantId,
    documentType,
    year = 0,
    entityId = 0,
}: Pick<
    ApplicantDocument,
    'applicationId' | 'applicantId' | 'documentType' | 'year' | 'entityId'
>) =>
    `${applicationId}/${applicantId}/${documentType}/${year || 0}/${
        entityId || 0
    }`;

export const getDocumentsByCompositeKey = selectorFamily({
    key: 'GetDocumentsByCompositeKey',
    get:
        ({
            applicationId,
            applicantId,
            step,
            compositeKey,
        }: Omit<DocumentQueryParams, 'docStates'> & {
            compositeKey: DocumentCompositeKey;
        }) =>
        ({ get }) => {
            const documents = get(
                applicantDocuments({ applicationId, applicantId, step })
            );

            return documents.find(
                (document) =>
                    compositeKey === getCompositeKeyFromDocument(document)
            );
        },
});

export const getPrevDocument = selectorFamily({
    key: 'GetPrevDocument',
    get:
        ({
            applicationId,
            applicantId,
            step,
            compositeKey,
        }: Omit<DocumentQueryParams, 'docStates'> & {
            compositeKey: DocumentCompositeKey;
        }) =>
        ({ get }): ApplicantDocument | undefined => {
            const sortedDocuments = get(
                getSortedDocuments({ applicationId, applicantId, step })
            );

            const selectedDocumentVisibility: DocumentVisibility = get(
                selectedDocumentsSectionState
            );
            const filteredDocuments = sortedDocuments.filter(
                (document) => document.visibility === selectedDocumentVisibility
            );
            //find the your current id is exist in the array
            const itemIndex = filteredDocuments
                .map((document) => getCompositeKeyFromDocument(document))
                .indexOf(compositeKey);

            if (itemIndex != -1 && itemIndex != 0) {
                return filteredDocuments[itemIndex - 1];
            }

            return undefined;
        },
});

export const getNextDocument = selectorFamily({
    key: 'GetNextDocument',
    get:
        ({
            applicationId,
            applicantId,
            step,
            compositeKey,
        }: Omit<DocumentQueryParams, 'docStates'> & {
            compositeKey: DocumentCompositeKey;
        }) =>
        ({ get }): ApplicantDocument | undefined => {
            const sortedDocuments = get(
                getSortedDocuments({ applicationId, applicantId, step })
            );

            const selectedDocumentVisibility: DocumentVisibility = get(
                selectedDocumentsSectionState
            );
            const filteredDocuments = sortedDocuments.filter(
                (document) => document.visibility === selectedDocumentVisibility
            );

            //find the your current id is exist in the array
            const itemIndex = filteredDocuments
                .map((document) => getCompositeKeyFromDocument(document))
                .indexOf(compositeKey);

            if (itemIndex !== -1 && filteredDocuments.length - 1 > itemIndex) {
                return filteredDocuments[itemIndex + 1];
            }

            return undefined;
        },
});

export const getSortedDocuments = selectorFamily({
    key: 'GetSortedDocuments',
    get:
        ({
            applicationId,
            applicantId,
            step,
        }: Omit<DocumentQueryParams, 'docStates'>) =>
        ({ get }) => {
            const documents = get(
                applicantDocuments({ applicationId, applicantId, step })
            );

            return groupAsList(documents);
        },
});

// Grouping docs
const byProp = (prop: keyof ApplicantDocument | number | string) =>
    R.groupBy((document: ApplicantDocument) => document[prop]);

const byCategories = byProp('category');

const bySubCategories = byProp('subCategory');

const byEntityId = byProp('entityId');

const sortDocuments = R.sortWith<ApplicantDocument>([
    R.ascend(R.prop('category')),
    R.ascend(R.prop('subCategory')),
]);

// (groupDocuments: GroupedDocuments) => ApplicantDocument[]
const flattenGroup = R.pipe(
    // @ts-ignore
    R.values,
    R.map(R.values),
    R.flatten,
    R.map(R.values),
    R.flatten
) as (groupDocuments: GroupedDocuments) => ApplicantDocument[];

// These categories are not groupped by subcategories in lower level object
// so they are sent into `undefined` object key
const ungroupedSubCategoryForCategory: DocumentCategory[] = [
    'IDENTIFICATION',
    'FINANCIALS',
];

// Core logic to group document in `GroupedDocuments` from an array of `Document`
// Compose from right to left `compose(2, 1, 0)`
// `compose(group by sub-categories, groups each cat by entity ID, group by categories, sort)`
// @ts-ignore
const groupAndSortDocuments = R.compose(
    R.mapObjIndexed(
        (
            item: Record<number /* entity ID*/, ApplicantDocument[]>,
            category: DocumentCategory
        ) =>
            R.mapObjIndexed(
                (
                    documents: ApplicantDocument[]
                    // Not used but I like to leave it here for others to know this exists
                    // might be useful one day
                    // subCategory: DocumentSubCategory
                ) =>
                    ungroupedSubCategoryForCategory.includes(category)
                        ? // Using undefined as a key here because BE can return a category type of `OTHER`
                          { undefined: documents }
                        : bySubCategories(documents)
            )(item)
    ),
    R.map(byEntityId),
    byCategories,
    sortDocuments
) as (documents: ApplicantDocument[]) => GroupedDocuments;

/**
 * Group and sort documents then ungrouped them to flatten array to have the same order
 */
const groupAsList = (documents: ApplicantDocument[]) =>
    R.pipe(groupAndSortDocuments, flattenGroup)(documents);

// Group Document selector
export const getDocumentsGrouped = selectorFamily({
    key: 'GetDocumentsGrouped',
    get:
        ({
            applicationId,
            applicantId,
            step,
            docStates,
        }: DocumentQueryParams) =>
        ({ get }) => {
            get(applicantDocuments({ applicationId, applicantId, step }));
            const selectedDocumentVisibility: DocumentVisibility = get(
                selectedDocumentsSectionState
            );

            const documents = docStates?.length
                ? get(
                      getApplicantDocumentsByStates({
                          applicationId,
                          applicantId,
                          step,
                          docStates,
                      })
                  )
                : get(applicantDocuments({ applicationId, applicantId, step }));

            const filteredDocuments = documents.filter(
                (document) => document.visibility === selectedDocumentVisibility
            );

            return groupAndSortDocuments(filteredDocuments);
        },
});

// get all docs and sort into an object with categories as keys
export const groupByCategory = (documents: ApplicantDocument[]) => {
    const groupedDocuments = byCategories(documents);
    return groupedDocuments;
};

export const getAllDocumentsGrouped = selectorFamily({
    key: 'GetAllDocumentsGrouped',
    get:
        ({
            applicationId,
            applicantId,
            step,
            docStates,
        }: DocumentQueryParams) =>
        ({ get }) => {
            get(applicantDocuments({ applicationId, applicantId, step }));

            const documents = docStates?.length
                ? get(
                      getDocuments({
                          applicationId,
                          applicantId,
                          step,
                      })
                  )
                : get(applicantDocuments({ applicationId, applicantId, step }));

            return groupAndSortDocuments(documents);
        },
});

export const getApplicantDocsByCategory = selectorFamily({
    key: 'GetApplicantDocsByCategory',
    get:
        ({
            applicationId,
            applicantId,
            step,
            docStates,
        }: DocumentQueryParams) =>
        ({ get }) => {
            const documents = docStates?.length
                ? get(
                      getApplicantDocumentsByStates({
                          applicationId,
                          applicantId,
                          step,
                          docStates,
                      })
                  )
                : get(applicantDocuments({ applicationId, applicantId, step }));

            return groupByCategory(documents);
        },
});

export const getAllDocuments = selectorFamily({
    key: 'getAllDocuments',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const docStep = get(getSelectedDocumentType({ applicationId }));
            let allDocs = [];

            applicantsIds.forEach((applicantId) => {
                const applicantDocs = get(
                    applicantDocuments({
                        applicationId,
                        applicantId,
                        step: docStep,
                    })
                );
                allDocs = [...allDocs, ...applicantDocs];
            });
            return allDocs;
        },
});

export const getNoDocuments = selectorFamily({
    key: 'GetNoDocuments',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            get(noDocumentsRequestId);
            const docStates = get(getSelectedDocumentStates({ applicationId }));
            const docStep = get(getSelectedDocumentType({ applicationId }));
            let noDocuments = true;
            applicantsIds.forEach((applicantId) => {
                const documents = get(
                    getDocumentsGrouped({
                        applicationId,
                        applicantId,
                        docStates,
                        step: docStep,
                    })
                );

                if (!isEmpty(documents)) {
                    noDocuments = false;
                }
            });
            return noDocuments;
        },
});

const noDocumentsRequestId = atom({
    key: 'NoDocumentsRequestId',
    default: 0,
});

export const useRefreshNoDocuments = () => {
    const setRequestID = useSetRecoilState(noDocumentsRequestId);

    return () => {
        setRequestID((requestID) => requestID + 1);
    };
};

// End grouping docs
type ApplicantDocumentsByStatesProps = {
    applicationId: number;
    applicantId: number;
    step?: DocumentStep;
    docStates: DocState[];
};

export const getApplicantDocumentsByStates = selectorFamily({
    key: 'GetApplicantDocumentsByStates',
    get:
        ({
            applicationId,
            applicantId,
            step = DocumentStep.All,
            docStates,
        }: ApplicantDocumentsByStatesProps) =>
        ({ get }) => {
            const documents = get(
                applicantDocuments({ applicationId, applicantId, step })
            );

            return documents.filter(
                (document) => docStates?.includes(document.state)
            );
        },
});

type ApplicantDocumentsByTypes = {
    applicationId: number;
    applicantId: number;
    step?: DocumentStep;
    documentTypes: DocumentType[];
};
export const getApplicantDocumentByTypes = selectorFamily({
    key: 'GetApplicantDocumentByTypes',
    get:
        ({
            applicationId,
            applicantId,
            step = DocumentStep.All,
            documentTypes = [],
        }: ApplicantDocumentsByTypes) =>
        ({ get }) => {
            const documents = get(
                applicantDocuments({ applicationId, applicantId, step })
            );

            return documents.filter((document) =>
                documentTypes.includes(document.documentType)
            );
        },
});

export const applicantDocuments = atomFamily({
    key: 'ApplicantDocumentsState',
    default: getDocuments,
});

export const applicationDocumentsCounts = atomFamily({
    key: 'ApplicationDocumentsCountsState',
    default: getDocumentsCounts,
});

// Some React hook to refresh some state
export const useRefreshApplicantDocuments = ({
    applicationId,
    applicantId,
}: Pick<DocumentQueryParams, 'applicantId' | 'applicationId'>) => {
    const setApplicantDocumentsAll = useSetRecoilState(
        applicantDocuments({
            applicationId,
            applicantId,
            step: DocumentStep.All,
        })
    );
    const setApplicantDocumentsCritical = useSetRecoilState(
        applicantDocuments({
            applicationId,
            applicantId,
            step: DocumentStep.Critical,
        })
    );
    const setApplicantDocumentsAdditional = useSetRecoilState(
        applicantDocuments({
            applicationId,
            applicantId,
            step: DocumentStep.Additional,
        })
    );

    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });

    return async () => {
        const { data: dataAll } = await apiClient.getDocuments(
            applicationId,
            applicantId,
            { step: DocumentStep.All }
        );

        const dataCritical = dataAll.filter(
            ({ step }) => step === DocumentStep.Critical
        );

        const dataAdditional = dataAll.filter(
            ({ step }) => step === DocumentStep.Additional
        );

        const documentsSortedFilesAll = dataAll.map((document) => ({
            ...document,
            // @ts-ignore
            files: R.sortWith([R.descend(R.prop('updated'))])(
                document?.files || []
            ),
        }));

        const documentsSortedFilesCritical = dataCritical.map((document) => ({
            ...document,
            // @ts-ignore
            files: R.sortWith([R.descend(R.prop('updated'))])(
                document?.files || []
            ),
        }));

        const documentsSortedFilesAdditional = dataAdditional.map(
            (document) => ({
                ...document,
                // @ts-ignore
                files: R.sortWith([R.descend(R.prop('updated'))])(
                    document?.files || []
                ),
            })
        );

        setApplicantDocumentsAll(documentsSortedFilesAll);
        setApplicantDocumentsCritical(documentsSortedFilesCritical);
        setApplicantDocumentsAdditional(documentsSortedFilesAdditional);

        await refreshApplicationDocumentsCounts();

        return {
            all: documentsSortedFilesAll,
            critical: documentsSortedFilesCritical,
            additional: documentsSortedFilesAdditional,
        };
    };
};

const applicationDocumentsCountsRequestIDState = atom({
    key: 'ApplicationDocumentsCountsRequestID',
    default: 0,
});
export const useRefreshApplicationDocumentsCounts = ({
    applicationId,
}: Pick<DocumentQueryParams, 'applicationId'>) => {
    // This Could be an alternative if someday it create a flickering in the screen
    const setApplicantDocumentsCountsAll = useSetRecoilState(
        applicationDocumentsCounts({ applicationId, step: DocumentStep.All })
    );
    const setApplicantDocumentsCountsCritical = useSetRecoilState(
        applicationDocumentsCounts({
            applicationId,
            step: DocumentStep.Critical,
        })
    );
    const setApplicantDocumentsCountsAdditional = useSetRecoilState(
        applicationDocumentsCounts({
            applicationId,
            step: DocumentStep.Additional,
        })
    );

    return async () => {
        const [
            { data: dataAll },
            { data: dataCritical },
            { data: dataAdditional },
        ] = await Promise.all([
            apiClient.getDocumentsCounts(applicationId, {
                step: DocumentStep.All,
            }),
            apiClient.getDocumentsCounts(applicationId, {
                step: DocumentStep.Critical,
            }),
            apiClient.getDocumentsCounts(applicationId, {
                step: DocumentStep.Additional,
            }),
        ]);

        setApplicantDocumentsCountsAll(dataAll);
        setApplicantDocumentsCountsCritical(dataCritical);
        setApplicantDocumentsCountsAdditional(dataAdditional);

        return { dataAll, dataCritical, dataAdditional };
    };

    // This is the recommended way to do it from Recoil.
    // But this will make all useRecoilValue to go back into loading state and make all React.Suspense
    // go into fallback prop so the screen will be flickering
    // const setApplicationDocumentsCountsRequestID = useSetRecoilState(
    //     applicationDocumentsCountsRequestIDState
    // );
    // return () => {
    //     setApplicationDocumentsCountsRequestID((requestID) => requestID + 1);
    // };
};

// getDocumentTypes
// export const document;
type DocumentTypesParams = {
    applicationId: number;
    applicantId: number;
};
export const getDocumentsTypes = selectorFamily({
    key: 'GetDocumentsTypes',
    get:
        ({ applicationId, applicantId }: DocumentTypesParams) =>
        async ({ get }) => {
            const selectedDocumentSection = get(selectedDocumentsSectionState);
            const { data } = await apiClient.getDocumentTypes(
                applicationId,
                applicantId
            );

            const filteredDocumentTypes = Object.entries(data).filter(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ([_documentType, documentTypeData]: [
                    DocumentType,
                    DocumentTypesData,
                ]) => documentTypeData.visibility === selectedDocumentSection
            );

            return Object.fromEntries(filteredDocumentTypes) as {
                [k in DocumentType]: DocumentTypesData;
            };
        },
});

export const getHasDownloadableDocuments = selectorFamily({
    key: 'GetHasDownloadableDocuments',
    get:
        ({ applicationId, applicantId }: DocumentTypesParams) =>
        async ({ get }) => {
            const documents = get(
                applicantDocuments({ applicationId, applicantId, step: 0 })
            );

            return documents.some(
                (document: ApplicantDocument) =>
                    document?.files?.some(
                        (file: DocumentFile) => !file.excludeAudit
                    )
            );
        },
});

export const getDocumentType = selectorFamily({
    key: 'GetDocumentType',
    get:
        ({
            applicationId,
            applicantId,
            documentType,
        }: DocumentTypesParams & { documentType: DocumentType }) =>
        async ({ get }) => {
            const documentsTypes = get(
                applicantDocumentsTypes({
                    applicationId,
                    applicantId,
                })
            );

            return documentsTypes?.[documentType];
        },
});

export const applicantDocumentsTypes = atomFamily({
    key: 'documentsTypes',
    default: getDocumentsTypes,
});

type BoostEmailInfoParams = {
    applicationId: number;
    applicantId: number;
};
export const getApplicantBoostEmailInfos = selectorFamily({
    key: 'GetApplicantBoostEmailInfos',
    get:
        ({ applicationId, applicantId }: BoostEmailInfoParams) =>
        async () => {
            const { data } = await apiClient.getApplicantDocumentBoostEmail(
                applicationId,
                applicantId
            );

            const recentToOld = data.sort(function (a, b) {
                return Date.parse(b.Sent) - Date.parse(a.Sent);
            });

            return recentToOld;
        },
});

export const applicantBoostEmailInfos = atomFamily({
    key: 'ApplicantBoostEmailInfos',
    default: getApplicantBoostEmailInfos,
});

export const useRefreshApplicantBoostEmailInfos = ({
    applicationId,
    applicantId,
}: BoostEmailInfoParams) => {
    const setApplicantBoostEmailInfos = useSetRecoilState(
        applicantBoostEmailInfos({ applicationId, applicantId })
    );

    return async () => {
        const { data } = await apiClient.getApplicantDocumentBoostEmail(
            applicationId,
            applicantId
        );

        setApplicantBoostEmailInfos(data);

        return data;
    };
};

export const getSelectedDocumentStates = atomFamily<
    DocState[],
    { applicationId: number }
>({
    key: 'GetSelectedDocumentStates',
    default: undefined,
});

export const getSelectedDocumentType = atomFamily<
    DocumentStep,
    { applicationId: number }
>({
    key: 'GetSelectedDocumentType',
    default: DocumentStep.All,
});

export const selectedDocumentsSectionState = atom({
    key: 'selectedDocumentsSectionState',
    default: DocumentVisibility.EXTERNAL,
});

export const uwSelectedDocumentLeft = atom({
    key: 'uwSelectedDocumentLeft',
    default: undefined,
});

export const uwSelectedDocumentRight = atom({
    key: 'uwSelectedDocumentRight',
    default: undefined,
});

export const docCenterBulkUploadState = atom({
    key: 'docCenterBulkUploadState',
    default: [],
});

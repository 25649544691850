import { i18n } from '@lingui/core';

import { SectionError } from 'types/application';
import { HelocApplication } from 'types/heloc';
import { Qualification } from 'types/qualification';

type HelocDetailsHasErrorsParams = {
    qualification: Qualification;
    heloc: HelocApplication;
    locale: string;
};

export const helocDetailsHasErrors = ({
    qualification,
    heloc,
    locale,
}: HelocDetailsHasErrorsParams): SectionError[] => {
    const helocQualificationState = qualification?.sectionStates?.heloc;

    const helocAmountMatchesQualification =
        heloc?.helocAmount === qualification?.helocAmount;

    const errors: SectionError[] = [];

    if (
        helocQualificationState.invalid &&
        helocQualificationState.invalidFields
    ) {
        // Map field names to readable labels
        const humanReadableInvalidFields =
            helocQualificationState.invalidFields.map((fieldName) =>
                i18n._(fieldName, { locale })
            );

        errors.push({
            title: 'discrepancyBanner.title',
            body: 'discrepancyBanner.error',
            fields: helocQualificationState.invalidFields,
            values: {
                numberOfDiscrepancies: humanReadableInvalidFields.length,
                missingFields: humanReadableInvalidFields.join(', '),
            },
        });
    }

    // Check heloc amount discrepancy
    if (!helocAmountMatchesQualification) {
        errors.push({
            title: 'discrepancyBanner.title',
            body: 'helocDetails.discrepancy.error',
        });
    }

    return errors;
};

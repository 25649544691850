import { TenantClient } from '@nestoca/multi-tenant';

export const tracingUrlsUtils = (tenant: TenantClient): string[] => {
    const auth0Domain = tenant?.auth0?.domain;
    const publicDomain = tenant?.publicDomain;

    return (
        tenant?.domains?.reduce((acc: string[], { hostname }) => {
            if (
                hostname &&
                hostname !== auth0Domain &&
                hostname !== publicDomain
            ) {
                // sanitize http or https to https to avoid duplicate protocols
                const cleanHostname = hostname.replace(/^(https?:\/\/)/, '');

                acc.push(`https://${cleanHostname}`);
            }
            return acc;
        }, []) || []
    );
};

export const getTracingPropagator =
    (allowedTracingUrlsList: string[]) =>
    (requestUrl = '') => {
        const url = new URL(requestUrl);

        // return false if the pathname start with `/napi` or `/_next`
        // we don't want to trace those requests
        // `/_next` is the nextjs internal path for static files
        // `/napi` is the path for the next version checker every N minutes
        const excludedPaths = ['/napi', '/_next'];
        const isExcludedPath = excludedPaths.some((path) =>
            url.pathname.startsWith(path)
        );

        if (isExcludedPath) {
            return false;
        }

        const isAllowedOrigin = allowedTracingUrlsList.includes(url.origin);

        return isAllowedOrigin;
    };

import { i18n } from '@lingui/core';
import { en, fr } from 'make-plural/plurals';

import catalogs from 'locales/catalogs';

i18n.loadLocaleData('en', { plurals: en });
i18n.loadLocaleData('fr', { plurals: fr });
i18n.load({
    en: catalogs.en.messages,
    fr: catalogs.fr.messages,
});

export { i18n };

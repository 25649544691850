import { useEffect } from 'react';

import { useRecoilState } from 'recoil';

import {
    currentApplicationIdState,
    useRefreshApplicationValidation,
} from 'store/applications';

const APPLICATION_ID_ZERO = 0;

export const useSetCurrentApplication = (applicationId): void => {
    const [currentApplicationId, setCurrentApplicationIdState] = useRecoilState(
        currentApplicationIdState
    );

    const refreshApplicationValidation =
        useRefreshApplicationValidation(applicationId);

    useEffect(() => {
        if (
            applicationId > APPLICATION_ID_ZERO &&
            applicationId !== currentApplicationId
        ) {
            setCurrentApplicationIdState(applicationId);
            refreshApplicationValidation();
        }
    }, [setCurrentApplicationIdState]);
};

import React, { PropsWithChildren, useEffect } from 'react';

import { TenantSlug } from '@nestoca/multi-tenant';
import { useSetRecoilState } from 'recoil';

import { tenantState } from 'store/atom';

export const TenantContext =
    React.createContext<TenantProviderProps | null>(null);

interface TenantProviderProps {
    slug?: TenantSlug;
    dispatch: (slug: TenantSlug) => void;
}

export const TenantProvider = ({
    slug,
    dispatch,
    children,
}: PropsWithChildren<TenantProviderProps>) => {
    const setTenant = useSetRecoilState(tenantState);

    useEffect(() => {
        if (slug) setTenant(slug);
        if (!slug) {
            /**
             * If the provider is not given a tenant slug, it will try to get it from the session storage.
             */
            const slug = sessionStorage.getItem('tenant') as TenantSlug;
            slug && dispatch(slug);
        }
    }, [dispatch, setTenant, slug]);

    return (
        <TenantContext.Provider value={{ slug, dispatch }}>
            {children}
        </TenantContext.Provider>
    );
};

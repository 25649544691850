import React, { useMemo } from 'react';

import css from '@styled-system/css';
import { Grommet } from 'grommet';

import { useAccount } from 'providers/auth0';
import * as theme from 'theme';

import type { Theme as NestoTheme } from 'theme/types';

const getThemeFromPartner = (partner: string) => {
    // Allowed theme.XXX export from value partner in user type
    const brandedPartners = Object.keys(theme);
    const hasPartnerTheme = brandedPartners.includes(partner);

    return hasPartnerTheme ? partner : 'nesto';
};

export const ThemeProvider = ({ children }: any) => {
    const { account } = useAccount();

    const myTheme = useMemo<NestoTheme>(() => {
        const partner = getThemeFromPartner(account?.partner);

        return theme[partner] || theme.nesto;
    }, [account]);

    return (
        <Grommet
            theme={myTheme}
            css={css({
                overflow: 'unset',
            })}
            background="light-2"
        >
            {children}
        </Grommet>
    );
};

import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { saturate, desaturate, lighten } from 'polished';
import { compose } from 'ramda';

import type { Theme as NestoThemeType } from 'theme/types';

const toneDisabled = compose(lighten(0.5), desaturate(0.5));

const brandColor = '#212243';

// Default Breakpoints
const breakpoints = ['40em', '52em', '64em'];
// @media screen and (min-width: 40em)
// @media screen and (min-width: 52em)
// @media screen and (min-width: 64em)

// default fontSizes
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72];

// default space for margin and padding
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

const colors = {
    brand: brandColor,
    brandTextColor: brandColor,
    cardTextColor: '#fff',
    portGore: '#212243',
    white: '#FFFFFF',
    'accent-1': '#212243',
    'dark-1': '#212243',
    'gray-5': '#E3E3E3',
    'gray-4': '#F0F0F0',
    'gray-3': '#C9CACF',
    'gray-2': '#949599',
    'gray-1': '#343434',
    'light-2': '#f6f6f6',
    'neutral-3': '#3D66B0',
    highlight: 'rgba(66, 153, 225, 0.5)',
    overlay: 'rgba(13, 13, 26, 0.6)',
    'status-warning': '#F8CD46',
    'status-critical': '#FF4040',
    'status-error': '#FF4040',
    'status-ok': '#00C781',
    focus: 'transparent',
    text: {
        light: '#212243',
    },
    placeholder: '#9B9B9B',
    green: 'var(--color-green-500)',
};

export const shadows = {
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    // '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    '2xl': '0px 8px 32px rgba(26, 26, 26, 0.24)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: `0 0 0 3px ${colors.highlight}`,
    none: 'none',
};

const zIndices = {
    deep: -999999,
    default: 1,
    masked: 100,
    mask: 200,
    sticky: 300,
    header: 400,
    toast: 500,
    dropdown: 600,
    overlay: 700,
    spinner: 800,
    modal: 900,
    popup: 950,
};

export const baseTheme: NestoThemeType = {
    breakpoints,
    fontSizes,
    space,
    colors,
    zIndices,
    global: {
        breakpoints: {
            small: {
                value: 576,
            },
            medium: {
                value: 768,
            },
            large: {
                value: 992,
            },
        },
        font: {
            family: `Montserrat, sans-serif`,
        },
        colors,
        // eslint-disable-next-line
        // @ts-ignore
        body: {
            padding: 0,
            margin: 0,
        },
        drop: {
            // eslint-disable-next-line
            // @ts-ignore
            zIndex: 90,
        },

        control: {
            border: {
                width: '1px',
                radius: '6px',
                color: '#E3E3E3',
            },
        },
    },
    stepper: {
        active: colors.portGore,
        default: colors['neutral-3'],
    },
    popper: {
        default: {
            color: colors.text.light,
            backgroundColor: colors.white,
        },
        error: {
            color: colors.white,
            backgroundColor: colors['status-critical'],
        },
        dark: { color: colors.white, backgroundColor: colors.portGore },
    },
    button: {
        background: 'white',
        default: {
            background: { color: 'white' },
            extend: {
                // eslint-disable-next-line
                // @ts-ignore
                fontSize: '12px',
                // eslint-disable-next-line
                // @ts-ignore
                borderColor: 'dark-1',
            },
        },
        primary: {
            color: 'white',
            background: { color: brandColor },
            extend: {
                // eslint-disable-next-line
                // @ts-ignore
                fontSize: '12px',
                // eslint-disable-next-line
                // @ts-ignore
                color: '#fff',
                border: 'none',
                radius: '50px',
            },
        },
        secondary: {
            background: { color: 'gray-4' },
            border: { color: 'gray-4' },
            color: 'gray-2',
            // eslint-disable-next-line
            // @ts-ignore
            extend: ({ active, theme }) =>
                active
                    ? `
                font-size: 12px;
                position: relative;
                background-color: ${theme.global.colors['gray-1']};
                border-color: ${theme.global.colors['gray-1']};
                color: ${theme.global.colors.white};
                box-shadow: none;
                :after {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(51, 51, 51, 0);
                    border-top-color: ${theme.global.colors['gray-1']};
                    border-width: 8px;
                    margin-left: -8px;
                }`
                    : {
                          // eslint-disable-next-line
                          // @ts-ignore
                          fontSize: '12px',
                      },
        },

        hover: {
            primary: {
                extend: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    boxShadow: `0px 0px 0px 2px ${brandColor}`,
                },
            },
            secondary: {
                background: 'gray-1',
                border: { color: 'gray-1' },
                color: 'white',
                extend: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    boxShadow: 'none',
                },
            },
        },
        active: {
            secondary: {
                background: 'gray-1',
                border: { color: 'gray-1' },
                color: 'white',
                extend: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    boxShadow: 'none',
                },
            },
        },
    },
    accordion: {
        border: false,
    },
    formField: {
        label: {
            size: 'xsmall',
            color: 'text-weak',
            margin: {
                horizontal: 'none',
            },
        },
    },

    // Starting here are not GROMMET theme spec
    shadows,
    whiteLabel: false,
    cards: {
        primary: {
            backgroundColor: colors.white,
        },
        secondary: {
            backgroundColor: colors.portGore,
            color: 'white',
        },
        accent: {
            backgroundColor: '#3D66B0',
            color: 'white',
        },
        summary: {
            backgroundColor: colors.white,
            borderRadius: '0px 0px 10px 10px',
        },
        warning: {
            backgroundColor: colors.white,
            border: '2px solid',
            borderColor: colors['status-error'],
        },
        success: {
            backgroundColor: colors.white,
            border: '2px solid',
            borderColor: colors['status-ok'],
        },
    },

    cells: {
        primary: { borderRight: '1px solid', borderColor: 'transparent' },
        secondary: {
            borderRight: '1px solid',
            borderColor: colors['gray-4'],
        },
        lightGray: {
            backgroundColor: '#F6F6F6',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            padding: '0px 12px',
        },
        blue: {
            backgroundColor: '#3D66B0',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            color: colors.white,
        },
        dark: {
            backgroundColor: colors['dark-1'],
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            color: colors.white,
        },
        warning: {
            backgroundColor: 'var(--color-light-3)',
            border: '1px solid',
            borderColor: 'var(--color-light-3)',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            color: colors.portGore,
        },
        discrepancies: {
            backgroundColor: 'var(--color-red-300)',
            border: '1px solid',
            borderColor: 'var(--color-red-500)',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            color: colors.portGore,
        },
        error: {
            backgroundColor: 'var(--color-red-300)',
            border: '1px solid',
            borderColor: 'var(--color-red-500)',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            color: colors.portGore,
        },
    },

    textInputs: {
        default: {
            borderColor: 'var(--color-light-3)',
            backgroundColor: 'var(--color-white)',
        },
        warning: {
            borderColor: 'var(--color-light-3)',
            backgroundColor: 'var(--color-light-3)',
        },
        error: {
            borderColor: 'var(--color-red-500)',
            backgroundColor: 'var(--color-white)',
        },
    },

    dividers: {
        primary: {
            borderTop: '1px solid',
            borderColor: '#E3E3E3',
            margin: '6px 0 16px 0',
        },
        dotted: {
            borderTop: '1px dotted',
            borderColor: colors.white,
            margin: '10px 0',
        },
        dottedGray: {
            borderTop: '1px dotted',
            borderColor: '#E3E3E3',
            margin: '10px 0',
        },
        dashboard: {
            borderTop: '2px solid',
            borderColor: colors.white,
            margin: '14px 0 14px 0',
        },
    },

    badges: {
        primary: {
            backgroundColor: colors.portGore,
        },
        secondary: {
            backgroundColor: '#F8CD46',
            color: brandColor,
        },
        largeWarning: {
            backgroundColor: '#f9423a',
            color: 'white',
            height: '36px',
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        large: {
            backgroundColor: colors.portGore,
            color: 'white',
            height: '40px',
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        DOCUMENT_EMPTY: {
            backgroundColor: '#3D66B0', // '#b0b0b0',
            color: 'white',
        },
        DOCUMENT_RECEIVED: {
            backgroundColor: '#212243',
            color: 'white',
        },
        DOCUMENT_BROKER_APPROVED: {
            backgroundColor: '#ffcc00',
            color: '#212243',
        },
        DOCUMENT_BROKER_UNAPPROVED: {
            backgroundColor: '#f9423a',
            color: 'white',
        },
        DOCUMENT_LENDER_ACCEPTED: {
            backgroundColor: '#139A43',
            color: 'white',
        },
        DOCUMENT_DISABLED: {
            backgroundColor: '#e3e3e3',
            color: '#212243',
        },
        dashboard: {
            backgroundColor: '#ECF0F7',
            color: 'black',
        },
    },
    notesBadges: {
        general: {
            borderLeft: '5px solid',
            borderLeftColor: colors.placeholder,
            color: colors.placeholder,
        },
        advisor: {
            borderLeft: '5px solid',
            borderLeftColor: '#F8CD46',
            color: '#F8CD46',
        },
        underwriter: {
            borderLeft: '5px solid',
            borderLeftColor: '#F9423A',
            color: '#F9423A',
        },
    },
    titles: {
        sectionTitle: {
            backgroundColor: 'var(--color-blue-300)',
            color: colors.portGore,
            borderRadius: 5,
            padding: '4px 15px',
            marginBottom: 15,
        },
    },

    buttons: {
        primary: {
            backgroundColor: brandColor,
            color: 'white',
            borderColor: brandColor,
            ':hover:not(.disabled), :focus:not(.disabled)': {
                backgroundColor: saturate(0.2, brandColor),
                borderColor: saturate(0.2, brandColor),
            },
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                backgroundColor: toneDisabled(brandColor),
                borderColor: toneDisabled(brandColor),
            },
        },
        secondary: {
            backgroundColor: 'white',
            color: brandColor,
            borderColor: brandColor,
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                color: toneDisabled(brandColor),
                borderColor: toneDisabled(brandColor),
            },
        },
        ghost: {
            backgroundColor: 'white',
            color: brandColor,
            borderColor: 'transparent',
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                color: toneDisabled(brandColor),
            },
        },
        plain: {
            backgroundColor: 'transparent',
            color: brandColor,
            border: '2px solid transparent',
            outline: 'none',
            boxShadow: 'none',
            ':hover:not(.disabled), :focus:not(.disabled)': {
                outline: 'none',
                boxShadow: 'none',
            },
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                color: toneDisabled(brandColor),
            },
        },
    },

    buttonSizes: {
        xsmall: {
            fontSize: '10px',
            padding: '2px 0.5em',
            borderRadius: '20px',
        },
        small: {
            fontSize: '10px',
            padding: '2px 1em',
            borderRadius: '20px',
        },
        medium: {
            fontSize: '12px',
            padding: '0.5em 1em',
            borderRadius: '24px',
        },
        large: {
            fontSize: '14px',
            padding: '0.75em 1.25em',
            borderRadius: '28px',
        },
        xlarge: {
            fontSize: '16px',
            padding: '1em 1.5em',
            borderRadius: '32px',
        },
    },

    navItems: {
        // TODO rename this
        broker: {
            backgroundColor: '#E3E3E3',
            paddingTop: '16px',
            paddingBottom: '16px',
            ':not(.disabled):hover': {
                backgroundColor: saturate(0.2, brandColor),
                color: 'white',
            },
            '&[class*="active"]': {
                backgroundColor: brandColor,
                color: 'white',
            },
            ':before': {
                background: '#d1d1d1',
            },
            ':last-child:after': {
                background: '#d1d1d1',
            },
        },
    },

    // Toogle
    // Track and thumb and track-container will herits of .disabled, .checked css classes
    toggle: {
        track: {
            primary: {
                border: '1px solid var(--color-light-1)',
                backgroundColor: 'var(--color-light-1)',
                // Some exemple that could be applied to track and/or thumb
                // '.track-container:hover &.checked': {
                //     backgroundColor: '',
                // },
                // '&.disabled': {
                //     backgroundColor: '',
                // },
            },
            secondary: {
                border: '1px solid var(--color-blue-500)',
                backgroundColor: 'var(--color-blue-500)',

                '&.checked': {
                    border: '1px solid var(--color-light-1)',
                    backgroundColor: 'var(--color-light-1)',
                },
            },
        },
        thumb: {
            primary: {
                border: '1px solid var(--color-light-1)',
                backgroundColor: 'var(--color-white)',

                '&.checked': {
                    border: `1px solid ${brandColor}`,
                    backgroundColor: brandColor,
                },
            },
            secondary: {
                border: '1px solid var(--color-light-1)',
                backgroundColor: 'var(--color-light-1)',

                '&.checked': {
                    border: '1px solid var(--color-blue-500)',
                    backgroundColor: 'var(--color-blue-500)',
                },
            },
        },
    },

    toolbars: {
        primary: {
            backgroundColor: brandColor,
            color: 'white',
        },
    },

    modals: {
        ghost: {
            backgroundColor: 'transparenet',
            boxShadow: 'none',
            borderRadius: '0px',
        },
        primary: {
            backgroundColor: 'white',
            boxShadow: shadows.lg,
            borderRadius: '10px',
        },
        success: {
            backgroundColor: 'white',
            boxShadow: shadows.lg,
            borderRadius: '10px',
            border: '2px solid',
            borderColor: colors['status-ok'],
        },
        error: {
            backgroundColor: 'white',
            boxShadow: shadows.lg,
            borderRadius: '10px',
            border: '2px solid',
            borderColor: colors['status-error'],
        },
    },

    toasts: {
        element: {
            info: {
                backgroundColor: colors.white,
                color: colors.placeholder,
                boxShadow: '0 3px 8px rgba(0, 0, 0, 0.175)',
                borderRadius: '8px',
            },
            success: {
                backgroundColor: colors.white,
                color: colors.placeholder,
                boxShadow: '0 3px 8px rgba(0, 0, 0, 0.175)',
                borderRadius: '8px',
            },
            warning: {
                backgroundColor: colors.white,
                color: colors.placeholder,
                boxShadow: '0 3px 8px rgba(0, 0, 0, 0.175)',
                borderRadius: '8px',
            },
            error: {
                backgroundColor: colors.white,
                color: colors.placeholder,
                boxShadow: '0 3px 8px rgba(0, 0, 0, 0.175)',
                borderRadius: '8px',
            },
        },
        edge: {
            info: {
                backgroundColor: '#3D66B0',
                color: '#3D66B0',
            },
            success: {
                backgroundColor: '#43D011',
                color: '#43D011',
            },
            warning: {
                backgroundColor: '#F8CD46',
                color: '#F8CD46',
            },
            error: {
                backgroundColor: '#F9423A',
                color: '#F9423A',
            },
        },
    },

    // Need to manually refresh the page when changing styles object
    // style default html tag
    styles: {
        a: {
            color: brandColor,
        },
        button: {
            transition: 'all 250ms ease',
            backgroundColor: 'white',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxSizing: 'border-box',
            // font: 'inherit',
            fontSize: '10px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: brandColor,
            // lineHeight: '24',
            padding: '6px 1em',
            borderStyle: 'solid',
            borderRadius: '6px',
            borderColor: brandColor,
            outline: 'none',
            ':focus:not(:disabled)': {
                boxShadow: shadows.outline,
            },
            '::-moz-focus-inner': {
                border: 0,
            },
        },
        h5: {
            padding: 0,
            margin: 0,
            color: '#3D66B0',
            fontSize: 12,
            fontWeight: 600,
        },
        h4: {
            padding: 0,
            margin: 0,
            color: colors.portGore,
            fontSize: 14,
            fontWeight: 700,
        },
        h3: {
            padding: 0,
            margin: 0,
            color: colors.portGore,
            fontSize: 14,
            fontWeight: 600,
        },
        h2: {
            padding: 0,
            margin: '10px 0',
            color: colors.portGore,
            fontSize: 18,
            fontWeight: 600,
        },
        h1: {
            padding: 0,
            margin: '30px 0 0 0',
            color: colors.portGore,
            fontSize: 22,
            fontWeight: 600,
        },
    },
};

export const theme = deepMerge<NestoThemeType, NestoThemeType>(
    grommet,
    baseTheme
);

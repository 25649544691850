import type { Address, RegisteredAddress } from './address';
import type { AssetInstitution, AssetTypes, Asset } from './asset';
import type { AmountFrequency, ValueOf, YesNo } from './index';
import type { OtherProperty } from './property';
import type {
    SALUTATION,
    MARITAL_STATUS,
    PARTNER,
} from 'constants/appConstants';

/**
 * @ref https://github.com/nestoca/applications/blob/c0ca5b3ace89033027430831e9e4f584614dd6e4/api/applications_applicants.go#L17
 */
export type Applicant = ApplicantInfo & {
    addresses: RegisteredAddress[];
    allAssets: ApplicantAsset[];
    // TODO
    assets?: any;
    liabilities: Liabilities;
    creditReport?: CreditReport;
    income: {
        employments: IncomeEmployment[];
        others: ExistingIncomeOther[];
    };
    properties: OtherProperty[];
    sin: ApplicantSIN['sin'];
};

export type ApplicantInfo = {
    applicantId: number;
    identityId?: string;
    salutation: Salutation;
    dateOfBirth: string; // TODO evolve to date
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    maritalStatus: MaritalStatus;
    relationToMainApplicant: RelationToMainApplicant;
    firstTimeHomeBuyer: boolean;
    primaryBankingInstitution: AssetInstitution;
    primaryBankingInstitutionOther: string;
    creditScoreQuality: CreditScoreQuality;
    propertiesSpecified: boolean;
    otherIncomesSpecified: boolean;
    covid19ImpactDescription: string;
    covid19Impacted: boolean;
    hasConsumerProposalOrBankruptcyLast5yrs: YesNo | '';
    permissions: ApplicantPermission;
    applicantColor?: string;
    financialInstitutionBankID: string;
    financialInstitutionAccountNumber: string;
    financialInstitutionTransitNumber: string;
    numberOfDependents?: number;
    newToCanada: boolean;
    residencyStatus: ResidencyStatus;
    isDigital?: boolean;
    isNestoEmployee: boolean;
    divorcedOrSeparated?: boolean;
    hasOwnerOccupancy?: boolean | null;
    guarantor: boolean;
    mpiInsurerResponse: MPIInsurerResponse | null;
    privacyMode: boolean;
    sin: ApplicantSIN['sin'];
    partner: Partner | null;
    verbalConsent: boolean;
};

export type Partner = (typeof PARTNER)[keyof typeof PARTNER];

export type ApplicantSIN = {
    sin: string;
};

export type CreditReportIdentityReason = {
    description: string;
    code: string;
};

export type CreditReportIdentityProduct = {
    identityProductId: string;
    hasFlags: boolean;
    reasons: CreditReportIdentityReason[];
};

type RiskStrategyDecision =
    | 'PASSED_VERIFICATION'
    | 'MANUAL_VERIFICATION_REQUIRED'
    | 'MANUAL_VERIFICATION_RECOMMENDED';

export const CREDIT_BUREAU = {
    TRANSUNION: 'TRANSUNION',
    EQUIFAX: 'EQUIFAX',
} as const;

export type CreditBureauType = ValueOf<typeof CREDIT_BUREAU>;

export type PullType = 'HARD' | 'SOFT' | 'SOFT_SCORE_ONLY';

export type PulledBy = 'INTERNAL' | 'EXTERNAL' | 'USER';

export type PullCreditReport = {
    bureau?: CreditBureauType;
    pullType: Exclude<PullType, 'SOFT_SCORE_ONLY'>;
    language: string;
    disableImport: boolean;
    socialInsuranceNumber: string;
};

export type CreditReport = {
    score: number;
    bureau: string;
    created: string;
    expired: boolean;
    hit: boolean;
    amlSourceHit: boolean;
    hasEidFlags?: boolean;
    hasEid?: boolean;
    hasAmlFlags?: boolean;
    pullType: Exclude<PullType, 'SOFT_SCORE_ONLY'>;
    identity: {
        available: boolean;
        hasFlags: boolean;
        riskStrategyDecision?: RiskStrategyDecision;
        products: CreditReportIdentityProduct[] | null;
    };
    pulledBy: PulledBy;
};

export type CreditReportHistory = {
    accountId: number;
    amlSourceHit: boolean;
    bureau: string;
    created: string;
    eidValidated: boolean;
    hit: boolean;
    id: number;
    pullType: Exclude<PullType, 'SOFT_SCORE_ONLY'>;
    score: number;
};

export type ApplicantCreditReport = {
    firstName: string;
    lastName: string;
    applicantId: number;
    pullCreditDate: string;
};

export type ApplicantEID = {
    reasons?: Record<string, unknown>[];
} & ApplicantCreditReport;

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L390
 */
export const ApplicantPermissions = [
    'MAIN_APPLICANT',
    'CO_APPLICANT_DEFAULT',
] as const;
export type ApplicantPermission = (typeof ApplicantPermissions)[number];

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L136
 */
export type CreditScoreQuality =
    | 'UNKNOWN'
    | 'POOR'
    | 'FAIR'
    | 'GOOD'
    | 'EXCELLENT';

/**
 * @ref https://github.com/nestoca/applications/blob/c0ca5b3ace89033027430831e9e4f584614dd6e4/api/applications_applicant_incomes.go#L61
 */
export type IncomeEmploymentPayload = {
    employer: {
        name: string;
        address: Address;
        phone: string;
    };
    jobTitle: string;
    industry: EmploymentIndustry;
    isCurrent: boolean;
    hasGuaranteedHours: boolean | null;
    employmentType: EmploymentType;
    incomeType: EmploymentIncomeType;
    pensionType: PensionType;
    tenure: EmploymentTenure;
    employedYears: number;
    employedMonths: number;
    salary: IncomeEmploymentSalary;
    salaryPreviousYear: IncomeEmploymentSalary;
    selfEmployed: {
        companyType: string;
        operatingAs: SelfEmployedOperatingAs;
        grossRevenue: AmountFrequency;
        grossRevenuePreviousYear: AmountFrequency;
    };
    yearsInIndustry: number;
    monthsInIndustry: number;
    incomeOverride: AmountFrequency;
    incomeOverrideIncluded: boolean;
    // submission notes
    comments?: string;
    occupation?: string;
};

export type IncomeEmployment = IncomeEmploymentPayload & {
    readonly id: number;
};

export type IncomeOther = {
    comments?: string;
    description: string;
    type: OtherIncomeType;
    income: AmountFrequency;
    ratiosIncluded?: boolean;
};

export type ExistingIncomeOther = IncomeOther & {
    readonly id: number;
};

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L231
 */
export type OtherIncomeType =
    | 'INVESTMENT_INCOME'
    | 'INTEREST_INCOME'
    | 'CHILD_SUPPORT'
    | 'ALIMONY'
    | 'EMPLOYMENT_EXPENSE'
    | 'PENSION'
    | 'DISABILITY_INCOME'
    | 'OTHER';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L177
 */
export type EmploymentIndustry =
    | 'BANK_FINANCE'
    | 'MANUFACTURING'
    | 'GOVERNMENT'
    | 'HEALTH'
    | 'RETAIL'
    | 'HIGH_TECH'
    | 'EDUCATION'
    | 'LEISURE_ENTERTAINMENT'
    | 'SERVICES'
    | 'TRANSPORTATION'
    | 'NATURAL_RESOURCES'
    | 'CONSTRUCTION'
    | 'FARMING';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L164
 */
export type EmploymentType = 'FULL_TIME' | 'PART_TIME' | 'SEASONAL';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L210
 */
export type EmploymentIncomeType =
    | 'NONE'
    | 'SALARIED'
    | 'HOURLY'
    | 'HOURLY_COMMISSIONS'
    | 'COMMISSIONS'
    | 'SELF_EMPLOYED'
    | 'PENSION';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L276
 */
export type PensionType =
    | 'OLD_AGE_SECURITY'
    | 'CANADIAN_PENSION_PLAN'
    | 'EMPLOYER'
    | 'OTHER';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L250
 */
export type EmploymentTenure = 'PERMANENT' | 'ON_PROBATION' | 'ON_CONTRACT';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L263
 */
export type SelfEmployedOperatingAs =
    | 'CORPORATION'
    | 'PARTNERSHIP'
    | 'SOLE_PROPRIETOR';

export type IncomeEmploymentSalary = {
    base?: AmountFrequency;
    baseRatiosIncluded: boolean;
    bonus?: AmountFrequency;
    bonusRatiosIncluded: boolean;
    commission?: AmountFrequency;
    commissionRatiosIncluded: boolean;
    overtime?: AmountFrequency;
    overtimeRatiosIncluded: boolean;
};

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L25
 */
export type Salutation = (typeof SALUTATION)[number];

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L72
 */
export type MaritalStatus = (typeof MARITAL_STATUS)[number];
/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L93
 */
export type RelationToMainApplicant =
    | 'NON_APPLICABLE'
    | 'SPOUSE'
    | 'COMMON_LAW'
    | 'RELATED_FAMILY_MEMBER'
    | 'PARENT'
    | 'CHILD'
    | 'SIBLING'
    | 'GRAND_PARENT'
    | 'GRAND_CHILD'
    | 'OTHER';

export type ApplicantAsset = Asset;

/**
 * @ref https://github.com/nestoca/applications/blob/48c9088b3c671eaaf476ee66389e3fe9d2bb9f8d/api/applications_applicant_liabilities.go#L16-L35
 */

export type Liability = {
    readonly id: number;
    applicantId: number; // owner of the liability
    coOwnerApplicantIds: number[];
    type: LiabilityType; // (mortgage is a special case for existing properties and should be read only)
    currency: LiabilityCurrency;
    highCredit: number;
    balance: number;
    payment: AmountFrequency;
    paymentMultiplier?: LiabilityPaymentMultiplier;
    payoff: LiabilityPayOff;
    description: string; // max length 1024
    readonly fromCreditBureau: boolean;
    included: boolean;
    creditType: LiabilityCreditType;
};

export type LiabilityCurrency = 'CAD' | 'USD';

export type LiabilityFormData = Omit<
    Liability,
    'id' | 'fromCreditBureau' | 'applicantId'
>;

export type LiabilityPaymentMultiplier =
    | ''
    | '3_PERCENT'
    | '5_PERCENT'
    | 'BOC_25_YEARS';

export type LiabilityPayOff =
    | ''
    | 'PRIOR_TO_ADVANCE'
    | 'FROM_PROCEEDS'
    | 'FROM_SALE_PROCEEDS';

export enum LiabilityPayOffTypesEnum {
    FROM_PROCEEDS = 'FROM_PROCEEDS',
    PRIOR_TO_ADVANCE = 'PRIOR_TO_ADVANCE',
    FROM_SALE_PROCEEDS = 'FROM_SALE_PROCEEDS',
}

export type Liabilities = Liability[];

export type LiabilityType =
    | 'CREDIT_CARD'
    | 'PERSONAL_LOAN'
    | 'STUDENT_LOAN'
    | 'AUTO_LOAN'
    | 'AUTO_LEASE'
    | 'SECURED_LINE_OF_CREDIT'
    | 'UNSECURED_LINE_OF_CREDIT'
    | 'ALIMONY'
    | 'CHILD_SUPPORT'
    | 'OTHER'
    | 'INCOME_TAX'
    | 'LEASE'
    | 'MORTGAGE';

export enum LiabilityCreditTypeEnum {
    INSTALMENT = 'INSTALMENT',
    OPEN = 'OPEN',
    REVOLVING = 'REVOLVING',
    MORTGAGE = 'MORTGAGE',
}

export type LiabilityCreditType = keyof typeof LiabilityCreditTypeEnum;

export type OtherIncomeGroupeByType = Partial<
    Record<OtherIncomeType, ExistingIncomeOther[]>
>;

export type ApplicantOtherIncomeGrouped = Pick<
    ApplicantInfo,
    'applicantId' | 'firstName' | 'lastName' | 'applicantColor'
> & {
    otherIncomes: OtherIncomeGroupeByType;
};

export type OtherIncomeGroupedByApplicant = Record<
    number,
    ApplicantOtherIncomeGrouped
>;

export type AssetsGroupeByType = Partial<Record<AssetTypes, ApplicantAsset[]>>;

export type AssetsGrouped = Pick<
    ApplicantInfo,
    'applicantId' | 'firstName' | 'lastName' | 'applicantColor'
> & {
    assets: AssetsGroupeByType;
};

export type AssetsGroupedByApplicant = Record<number, AssetsGrouped>;

export type NumberOfApplicants = '1' | '2' | '3+';

export type IncomeIncludedFieldName =
    | 'baseRatiosIncluded'
    | 'bonusRatiosIncluded'
    | 'commissionRatiosIncluded'
    | 'overtimeRatiosIncluded'
    | 'incomeOverrideIncluded'
    | 'padApplicationId';

export type CreditCheckCode =
    | 'CREDIT_AGE_OCCURRENCE'
    | 'CREDIT_FULL_REPORT'
    | 'CREDIT_DATA_MATCH_ADDRESS'
    | 'CREDIT_DATA_MATCH_DOB'
    | 'CREDIT_DATA_MATCH_NAME'
    | 'CREDIT_FICO_SCORE'
    | 'CREDIT_BANK_NAV_INDEX'
    | 'CREDIT_TRADE_TYPES'
    | 'CREDIT_FRAUD_STATEMENTS'
    | 'CREDIT_FRAUD_SAFESCAN'
    | 'CREDIT_LEGAL_ITEMS'
    | 'CREDIT_BAD_DEBT'
    | 'CREDIT_JOINED_TRADES';

export type CreditCheckCategory =
    | 'FLAG'
    | 'APPLICATION_VERIFICATION'
    | 'PRODUCT_VERIFICATION'
    | 'CREDIT_REPORT_VERIFICATION';

export interface CreditDecision {
    applicantId: number;
    category: CreditCheckCategory;
    code: CreditCheckCode;
    description: string;
    checked: boolean;
}

export enum ResidencyStatus {
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
    NON_RESIDENT = 'NON_RESIDENT',
    UNKNOWN = 'UNKNOWN',
}

export enum MPIInsurerResponse {
    OPTED_IN = 'Opted-In',
    OPTED_OUT = 'Opted-Out',
    INELIGIBLE = 'Ineligible',
}

export type ApplicantEmployments = {
    applicantId: number;
    employments: IncomeEmployment[];
};

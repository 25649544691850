import React, { useCallback } from 'react';

import { Trans } from '@lingui/react';

import { useClient } from 'providers/auth0';
import { useModal } from 'providers/modals/use-modal';

import { ButtonNav } from '../button';
import { AlertIcon } from '../icons/alert-icon';

type Props = {
    isSidebarOpen: boolean;
    buttonStyle: string;
    popper?: {
        isVisible: boolean;
        text: string;
    };
};

export const LogIssue = ({ isSidebarOpen, buttonStyle, popper }: Props) => {
    const { client } = useClient();
    const { open: openModal } = useModal('logIssue');

    const onClick = useCallback(() => {
        navigator.clipboard.writeText(
            `${window.location.host}/login?accountRid=${client?.rid}`
        );
        openModal({ rid: client?.rid });
    }, [openModal, client?.rid]);

    return (
        <ButtonNav
            justifyEnd={!isSidebarOpen}
            href="#"
            data-testid="nav-log-issues"
            onClick={onClick}
            popper={popper}
        >
            <div className={buttonStyle}>
                <AlertIcon />
            </div>
            {isSidebarOpen && (
                <div>
                    <Trans id="logIssue" />
                </div>
            )}
        </ButtonNav>
    );
};

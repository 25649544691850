import { datadogRum } from '@datadog/browser-rum';

import type {
    Account,
    BrokerAccount,
    FinancialAdvisorAccount,
    SubpartnerAccount,
} from 'providers/auth/types';

const isServer = typeof window === 'undefined';

export const setUserIdentity = (
    account:
        | Account
        | BrokerAccount
        | SubpartnerAccount
        | FinancialAdvisorAccount
) => {
    if (!isServer && window.config?.env !== 'dev') {
        // This ts-ignore is needed because `datadogRum` is a global variable
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        datadogRum.setUser({
            email: account.email,
            firstName: account.firstName,
            lastName: account.lastName,
            name: `${account.firstName} ${account.lastName}`,
        });
    }
};

export const setClientContext = (account: Account) => {
    if (!isServer && window.config?.env !== 'dev') {
        datadogRum.addRumGlobalContext('client', {
            email: account.email,
            firstName: account.firstName,
            lastName: account.lastName,
            name: `${account.firstName} ${account.lastName}`,
        });
    }
};

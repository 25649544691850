import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { saturate, desaturate, lighten } from 'polished';
import { compose } from 'ramda';

import { baseTheme } from './nesto';

import type { Theme as NestoThemeType } from './types';

const toneDisabled = compose(lighten(0.5), desaturate(0.5));

const brandColor = '#002D5E';
const secondaryBrandColor = '#002D5E';

const colors = {
    brand: secondaryBrandColor,
    brandTextColor: brandColor,
    cardTextColor: '#fff',
    'accent-1': brandColor,
    'dark-1': '#1d1d1d',
    'gray-4': '#F0F0F0',
    'gray-3': '#C9CACF',
    'gray-2': '#949599',
    'gray-1': '#343434',
    'light-2': '#f6f6f6',
    'neutral-3': secondaryBrandColor,
    focus: secondaryBrandColor,
    text: {
        light: '#343434',
    },
    placeholder: '#9B9B9B',
    overlay: '#FFFFFFF5',
};

const myTheme: NestoThemeType = {
    whiteLabel: false,
    colors,
    logoPath: {
        en: `/co-branding/optimize.svg`,
        fr: `/co-branding/optimize.svg`,
    },
    global: {
        colors,
    },
    button: {
        primary: { background: { color: secondaryBrandColor } },
        hover: {
            primary: {
                extend: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    boxShadow: `0px 0px 0px 2px ${secondaryBrandColor}`,
                },
            },
        },
    },
    // Starting here are not GROMMET theme spec
    // Nesto buttons styles
    buttons: {
        primary: {
            backgroundColor: brandColor,
            color: 'white',
            borderColor: brandColor,
            ':hover:not(.disabled), :focus:not(.disabled)': {
                backgroundColor: saturate(0.2, brandColor),
                borderColor: saturate(0.2, brandColor),
            },
            ':disabled, &.disabled': {
                cursor: 'not-allowed',
                backgroundColor: toneDisabled(brandColor),
                borderColor: toneDisabled(brandColor),
            },
        },
    },
};

export const theme = deepMerge<
    NestoThemeType,
    [NestoThemeType, NestoThemeType]
>(grommet, baseTheme, myTheme);

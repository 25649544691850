type Log = {
    error: string;
    tag?: string | string[];
    data?: Record<string, any>;
};

export const log = ({ error, tag = 'error', data }: Log): void => {
    if (['test', 'ci'].includes(window.config.env)) return;

    !!window.rg4js && window.config.env === 'production'
        ? window.rg4js('send', {
              error,
              tags: Array.isArray(tag) ? tag : [tag],
              customData: [{ ...data }],
          })
        : // tslint:disable-next-line:no-console
          console.log({ error, tag, data });
};

import React, { PropsWithChildren } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useTenant } from '@nestoca/multi-tenant';

import { getTracingPropagator, tracingUrlsUtils } from '../../utils/util';

export const MonitoringProvider = ({ children }: PropsWithChildren) => {
    const isServer = typeof window === 'undefined';
    const tenant = useTenant();

    const allowedTracingUrlsList = tracingUrlsUtils(tenant);

    if (!isServer && window.config?.env !== 'dev') {
        datadogRum.init({
            applicationId: 'b6128fa3-08ab-4208-87e4-e5f9e1edaf69',
            clientToken: 'pubf9bff705abc81ae457e7186f38203ff9',
            site: 'datadoghq.com',
            service: 'middle-office',
            env: window.config.env,
            version: process.env.VERSION,
            sampleRate: 100,
            replaySampleRate: 100,
            trackInteractions: true,
            allowedTracingUrls: [getTracingPropagator(allowedTracingUrlsList)],
            trackResources: true,
            trackUserInteractions: true,
        });

        // @ts-ignore
        // eslint-disable-next-line
        DD_RUM.startSessionReplayRecording();
    }
    return <>{children}</>;
};

import { APPLICATION_TYPE_MAP, ApplicationMainType } from 'types/application';
import { ApplicationType, MainApplicationType } from 'types/application';

export const getBaseApplicationType = (
    type: ApplicationType,
    applicationMap: Record<MainApplicationType, ReadonlyArray<ApplicationType>>
): MainApplicationType => {
    switch (true) {
        case applicationMap.NEW.includes(type):
            return 'NEW';
        case applicationMap.RENEWAL.includes(type):
            return 'RENEWAL';
        case applicationMap.REFINANCE.includes(type):
            return 'REFINANCE';
    }
};

export const getApplicationMainType = (
    type: ApplicationType
): ApplicationMainType => {
    return {
        mainType: getBaseApplicationType(type, APPLICATION_TYPE_MAP),
        isNewApplication: APPLICATION_TYPE_MAP.NEW.includes(type),
        isRefinanceApplication: APPLICATION_TYPE_MAP.REFINANCE.includes(type),
        isRenewalApplication: APPLICATION_TYPE_MAP.RENEWAL.includes(type),
    };
};

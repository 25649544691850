import type { ReactNode } from 'react';
import React from 'react';

import ErrorInfo from './error-info';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // eslint-disable-next-line no-console
        console.error('Uncaught error', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorInfo />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;

import { useMemo } from 'react';

import { i18n } from '@lingui/core';
import { useTenantSetting } from '@nestoca/multi-tenant';
import { constSelector, useRecoilValue } from 'recoil';

import { getApplication } from 'store/applications';
import { getQualificationState } from 'store/qualification';
import { Application, APPLICATION_TYPE, SectionError } from 'types/application';
import { Qualification } from 'types/qualification';
import { getApplicationMainType } from 'utils/application-type';

import * as utils from './utils';

export const applicationHasExistingMortgageNumber = ({
    application,
}: {
    application?: Application;
}) => {
    if (!application) {
        return true;
    }

    const { isNewApplication } = getApplicationMainType(application.type);

    // only applicable for refi or renewal FOR NOW (ADDING PORT TYPE LATER)
    if (isNewApplication && application?.type !== 'PORT') {
        return true;
    }

    if (
        application?.property?.mortgages[0]?.lender !== 'IG' &&
        !isNewApplication
    ) {
        return true;
    }

    // return boolean of existing mortgage number
    return !!application?.property?.existingMortgageNumber;
};

export const applicationHasPurchaseWithImprovementDiscrepancy = ({
    application,
    qualification,
}: {
    application?: Application;
    qualification?: Qualification;
}) => {
    if (
        !application ||
        application?.type !== APPLICATION_TYPE.PURCHASE_WITH_IMPROVEMENT
    ) {
        return false;
    }

    const subjectProperty = application?.property;

    if (!subjectProperty) {
        return false;
    }

    if (!qualification) {
        return false;
    }

    const purchasePrice = subjectProperty.purchasePrice;
    const improvementCost = subjectProperty.improvementAmount || 0;

    if (purchasePrice + improvementCost !== qualification?.propertyValue) {
        return true;
    }

    return false;
};

export const useSubjectPropertyErrors = (applicationId: number) => {
    const application = useRecoilValue(
        applicationId ? getApplication(applicationId) : constSelector(null)
    );

    const qualification = useRecoilValue(getQualificationState(applicationId));

    const { value: isExistingMortgageNumberEnabled } = useTenantSetting(
        'existingMortgageNumberEnabled'
    );

    const locale = i18n.locale;

    const sectionErrors = useMemo(() => {
        if (!application) {
            return [];
        }

        return subjectPropertyHasErrors({
            application,
            isExistingMortgageNumberEnabled,
            qualification,
            locale,
        });
    }, [application, isExistingMortgageNumberEnabled, locale, qualification]);

    return sectionErrors;
};

type SubjectPropertyHasErrorsParams = {
    application: Application;
    isExistingMortgageNumberEnabled: boolean;
    qualification: Qualification;
    locale: string;
};

export const subjectPropertyHasErrors = ({
    application,
    isExistingMortgageNumberEnabled,
    qualification,
    locale,
}: SubjectPropertyHasErrorsParams): SectionError[] => {
    const property = application?.property;

    if (!property) {
        return [];
    }

    const { isNewApplication } = getApplicationMainType(application.type);

    const hasExistingMortgageNumber =
        utils.applicationHasExistingMortgageNumber({
            application,
        });

    const hasPurchaseWithImprovementDiscrepancy =
        utils.applicationHasPurchaseWithImprovementDiscrepancy({
            application,
            qualification,
        });

    const isPurchaseWithImprovement =
        application.type === APPLICATION_TYPE.PURCHASE_WITH_IMPROVEMENT;

    const subjectPropertyQualificationState =
        qualification?.sectionStates?.property;

    const improvementAmountMatchesQualification =
        property?.improvementAmount === qualification?.improvementAmount;

    const subjectPropertyFieldNameMap: Record<string, string> = {
        purchasePrice: isNewApplication
            ? 'purchasePrice'
            : 'originalPurchasePrice',
        additionalFundAmount: 'additionalAmount',
        mortgageBalance: 'mortgageBalance',
        improvementAmount: 'improvementAmount',
        costOfConstruction: 'construction.costOfConstruction',
        landValue: 'construction.valueOfLand',
        estimatedPropertyValue: 'appraisalValue',
    };

    const errors: SectionError[] = [];

    // Get qualification discrepancies
    if (
        subjectPropertyQualificationState.invalid &&
        subjectPropertyQualificationState.invalidFields
    ) {
        // Map field names to readable labels
        const humanReadableInvalidFields =
            subjectPropertyQualificationState.invalidFields.map((fieldName) => {
                const readableFieldName =
                    subjectPropertyFieldNameMap[fieldName];

                return readableFieldName
                    ? i18n._(readableFieldName, { locale })
                    : fieldName;
            });

        errors.push({
            title: 'discrepancyBanner.title',
            body: 'discrepancyBanner.error',
            fields: subjectPropertyQualificationState.invalidFields,
            values: {
                numberOfDiscrepancies: humanReadableInvalidFields.length,
                missingFields: humanReadableInvalidFields.join(', '),
            },
        });
    }

    // Check improvement amount discrepancy
    if (isPurchaseWithImprovement && !improvementAmountMatchesQualification) {
        errors.push({
            title: 'discrepancyBanner.title',
            body: 'mortgageDetails.improvementAmountDiscrepancy.error',
        });
    }

    // Check has purchase with improvement discrepancy
    if (isPurchaseWithImprovement && hasPurchaseWithImprovementDiscrepancy) {
        errors.push({
            title: 'discrepancyBanner.title',
            body: 'mortgageDetails.improvementAmountDiscrepancyQualification.error',
        });
    }

    // The discrepancies from `hasExistingMortgageNumber` are not applicable to nesto (OG-7135)
    if (isExistingMortgageNumberEnabled) {
        // Check has exisiting mortgage number discrepancy
        if (!hasExistingMortgageNumber) {
            errors.push({
                title: 'discrepancyBanner.title',
                body: 'subjectProperty.missingExistingMortgageNumber.error',
            });
        }
    }

    return errors;
};

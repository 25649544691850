import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import {
    getApplication,
} from 'store/applications';
import { useSetCurrentApplication } from 'utils/use-set-current-application';

const cachedScripts: string[] = [];
export function useScript(src: string) {
    // Keeping track of script loaded and error state
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });

    useEffect(() => {
        // If cachedScripts array already includes src that means another instance ...
        // ... of this hook already loaded this script, so no need to load again.
        if (cachedScripts.includes(src)) {
            setState({
                loaded: true,
                error: false,
            });
        } else {
            cachedScripts.push(src);

            // Create script
            const script = document.createElement('script');
            script.src = src;
            script.async = true;

            // Script event listener callbacks for load and error
            const onScriptLoad = () => {
                setState({
                    loaded: true,
                    error: false,
                });
            };

            const onScriptError = () => {
                // Remove from cachedScripts we can try loading again
                const index = cachedScripts.indexOf(src);
                if (index >= 0) cachedScripts.splice(index, 1);
                script.remove();

                setState({
                    loaded: true,
                    error: true,
                });
            };

            script.addEventListener('load', onScriptLoad);
            script.addEventListener('error', onScriptError);

            // Add script to document body
            document.body.appendChild(script);

            // Remove event listeners on cleanup
            return () => {
                script.removeEventListener('load', onScriptLoad);
                script.removeEventListener('error', onScriptError);
            };
        }
    }, [src]); // Only re-run effect if script src changes

    return [state.loaded, state.error];
}

const cachedCss: string[] = [];
export function useCss(href: string) {
    // Keeping track of css loaded and error state
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });

    useEffect(() => {
        // If cachedCss array already includes href that means another instance ...
        // ... of this hook already loaded this css, so no need to load again.
        if (cachedCss.includes(href)) {
            setState({
                loaded: true,
                error: false,
            });
        } else {
            cachedCss.push(href);

            // Create css
            const css = document.createElement('link');
            css.href = href;
            css.rel = 'stylesheet';

            // Css event listener callbacks for load and error
            const onCssLoad = () => {
                setState({
                    loaded: true,
                    error: false,
                });
            };

            const onCssError = () => {
                // Remove from cachedCss we can try loading again
                const index = cachedCss.indexOf(href);
                if (index >= 0) cachedCss.splice(index, 1);
                css.remove();

                setState({
                    loaded: true,
                    error: true,
                });
            };

            css.addEventListener('load', onCssLoad);
            css.addEventListener('error', onCssError);

            // Add css to document body
            document.body.appendChild(css);

            // Remove event listeners on cleanup
            return () => {
                css.removeEventListener('load', onCssLoad);
                css.removeEventListener('error', onCssError);
            };
        }
    }, [href]); // Only re-run effect if css href changes

    return [state.loaded, state.error];
}

export const useKeyPress = (targetKey) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // If pressed key is our target key then set to true
    const downHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    };

    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };

    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
        // eslint-disable-next-line
    }, []);

    return keyPressed;
};

/**
 * We use this hook to fix issues related to a race condition between two selectors
 * @see https://nestoca.atlassian.net/browse/OG-939
 */
export const usePreloadApplication = (applicationId: number) => {
    useSetCurrentApplication(applicationId);
    useRecoilValue(getApplication(applicationId));
};

export const useKeyboardNavigation = (
    index,
    sections,
    sectionSelected,
    onNavigationFunction
) => {
    const highestIndex = sections.length - 1;
    const lowestIndex = 0;
    useEffect(() => {
        const keydownAction = (event: KeyboardEvent) => {
            // event.metaKey - Command key on Macs
            // event.ctrlKey - Control key on Linux or Windows
            // When a user does ctrl/cmd + left / right arrow - navigation between sections occurs

            if (
                (event.metaKey || event.ctrlKey) &&
                event.code === 'ArrowRight' &&
                index < highestIndex
            ) {
                event.preventDefault();
                const nextSection = sections[index + 1]?.name;
                onNavigationFunction(nextSection);
            }
            if (
                (event.metaKey || event.ctrlKey) &&
                event.code === 'ArrowLeft' &&
                index > lowestIndex
            ) {
                event.preventDefault();
                const previousSection = sections[index - 1]?.name;
                onNavigationFunction(previousSection);
            }
        };
        document.addEventListener('keydown', keydownAction);
        return () => {
            document.removeEventListener('keydown', keydownAction);
        };
    }, [sectionSelected]);
};

import React, { useCallback, useMemo } from 'react';

import { ButtonIcon, Flex, Typography } from '@nestoca/ui';
import { BsFiles } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { SuccessfulToastContent } from 'components/successful-toast-content';
import { Tooltip } from 'components/tooltip';
import { useClient } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';

const RidLink = () => {
    const { client } = useClient();
    const { i18n } = useI18n();

    const ridLink = useMemo(
        () => `${window.location.host}/login?accountRid=${client?.rid}`,
        [client?.rid]
    );

    const onClick = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(ridLink);

            toast.success(
                <SuccessfulToastContent
                    text={i18n._('errorBoundary.copySuccessful')}
                />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } catch {
            toast.error(
                <Typography size={0} height={0}>
                    {i18n._('errorBoundary.copyFailed')}
                </Typography>,
                {
                    theme: 'colored',
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    }, [i18n, ridLink]);

    return (
        <Flex align="center" gap={3}>
            <a
                href="#"
                css={{
                    fontSize: 'var(--font-size-1)',
                    fontWeight: 'var(--font-weight-6)',
                    color: 'var(--color-blue-600)',
                    overflowWrap: 'break-word',
                }}
                onClick={onClick}
            >
                {ridLink}
            </a>
            <Tooltip content={i18n._('copyLink')}>
                <ButtonIcon
                    aria-label="copy-link"
                    size="medium"
                    variant="ghost"
                    icon={<BsFiles size={20} color="var(--color-blue-600)" />}
                    onClick={onClick}
                />
            </Tooltip>
        </Flex>
    );
};

export default RidLink;

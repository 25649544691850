import Big from 'big.js';

import { isUndefined } from 'utils';

import type { RoundingMode } from 'big.js';

// @ts-ignore
Big.strict = false;

export const subtract = (x: number, y: number) => {
    if (isUndefined(x) || isUndefined(y)) {
        return 0;
    }

    const value = new Big(x).minus(y);

    return +value.toString();
};

/**
 *
 * @param n
 * @param decimal Decimal places, 0 to 1e+6 inclusive
 * @param roundingMode The rounding mode, one of the RoundingMode enumeration values
 * @returns number
 */
export const round = (
    n: number,
    decimal?: number,
    roundingMode?: RoundingMode
) => {
    if (isUndefined(n)) {
        return 0;
    }

    const x = new Big(n).round(decimal, roundingMode);

    return +x.toString();
};

// Due to the floating point precision issue in JS, we use Big.js library to handle these cases
// JS:
// ex 2.3 * 100 = 229.99999999999997
// ex 2.2 * 100 = 220.00000000000003
export const truncateToTwoDecimals = (number: number) => new Big(number).mul(100).round(0, 0).div(100).toNumber();
export const multiplyWithPrecision = (x: number = 0, y: number = 0) => new Big(x).times(y).toNumber();

import { useCallback, useContext, useEffect, useMemo } from 'react';

import { ModalsContext } from './modals-provider';

import type {
    ModalActionSetVisibility,
    ModalActionUpdate,
    ModalState,
} from './modals-provider';

export type TModalPropsDefault = Record<string, unknown> | undefined;

export const useModal = <P = TModalPropsDefault>(
    key: string,
    defaultKeyState?: ModalState<P>
) => {
    const { state, dispatch } = useContext(ModalsContext);

    useEffect(() => {
        if (!defaultKeyState) return;

        const hasKey = Boolean(state?.[key]);
        if (hasKey) return;

        dispatch({
            type: 'UPDATE',
            key,
            data: { ...defaultKeyState },
        } as ModalActionUpdate<P>);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const isVisible = useMemo(
        () => state?.[key]?.isVisible || false,
        [state, key]
    );
    const props = useMemo<P>(
        () => state?.[key]?.props || defaultKeyState?.props || {},
        [state, key, defaultKeyState?.props]
    );

    const open = useCallback(
        (props?: Partial<P>) => {
            let payload: ModalActionUpdate<P> = {
                type: 'UPDATE',
                key,
                data: { isVisible: true },
            };
            if (props) {
                payload = {
                    ...payload,
                    data: {
                        ...payload.data,
                        props: {
                            ...props,
                        } as P,
                    },
                };
            }

            dispatch(payload);
        },
        [key] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const close = useCallback(
        () =>
            dispatch({
                type: 'VISIBILITY',
                key,
                isVisible: false,
            } as ModalActionSetVisibility),
        [key] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const toggle = useCallback(
        (props?: Partial<P>) => {
            let payload: ModalActionUpdate<P> = {
                type: 'UPDATE',
                key,
                data: { isVisible: !isVisible },
            };
            if (props) {
                payload = {
                    ...payload,
                    data: {
                        ...payload.data,
                        props: props as P,
                    },
                };
            }
            dispatch(payload);
        },
        [key] //eslint-disable-line react-hooks/exhaustive-deps
    );

    return {
        isVisible,
        open,
        close,
        toggle,
        props,
    };
};

import { atomFamily, selectorFamily, useSetRecoilState } from 'recoil';

import { client as apiClient } from 'libs/api';
import { HelocApplication } from 'types/heloc';

import { getApplicationById } from './applications';

const helocApplicationState = selectorFamily({
    key: 'ProductSelectionState',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            try {
                const application = get(getApplicationById(applicationId));
                if (!application?.heloc) return {} as HelocApplication;
                const { data } = await apiClient.getHelocApplication(
                    applicationId
                );
                return data;
            } catch (error) {
                // TODO use log to raygun function when https://github.com/nestoca/back-office/pull/364 will be merge
                console.info('Error: No heloc information', error);
                return {} as HelocApplication;
            }
        },
});

export const getHelocApplicationState = atomFamily({
    key: 'GetHelocApplicationState',
    default: helocApplicationState,
});

export const getSelectedHelocProduct = selectorFamily({
    key: 'GetSelectedHelocProduct',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const helocApplication = get(
                getHelocApplicationState(applicationId)
            );

            return helocApplication?.product;
        },
});

export const useRefreshHelocApplication = (applicationId: number) => {
    const setHelocApplication = useSetRecoilState(
        getHelocApplicationState(applicationId)
    );

    return async () => {
        try {
            const { data } = await apiClient.getHelocApplication(applicationId);

            setHelocApplication(data);
            return data;
        } catch {
            setHelocApplication({} as HelocApplication);
            return {} as HelocApplication;
        }
    };
};

import React from 'react';

import { Typography } from '@nestoca/ui';

import { logIssueModalProps } from 'components/modals/global-modal-wrapper';
import { useI18n } from 'providers/i18n/use-i18n';

export const LogIssueModal = ({ rid }: logIssueModalProps) => {
    const { i18n } = useI18n();

    return (
        <Typography size={0}>
            {i18n._(
                rid
                    ? 'logIssueModal.description'
                    : 'logIssueModal.descriptionWithoutRid'
            )}
        </Typography>
    );
};

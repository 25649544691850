import { createContext } from 'react';

import type { User } from '@auth0/auth0-react';
import type {
    Account,
    BrokerAccount,
    SubpartnerAccount,
    FinancialAdvisorAccount,
    BorrowerAccount,
} from 'providers/auth/types';

type UserContextStateAccount =
    | Account
    | BrokerAccount // adds metadata support for Auth Rules
    | SubpartnerAccount // adds metadata support for Auth Rules
    | FinancialAdvisorAccount; // adds metadata support for Auth Rules;

type UserContextStateClient = BorrowerAccount;

export interface UserContextState {
    // Auth0
    user?: User;
    permissions?: string[];
    token: string;
    // nesto logged in user account
    account?: UserContextStateAccount;
    setAccount: React.Dispatch<React.SetStateAction<UserContextStateAccount>>;
    // nesto on behalf of client
    client?: UserContextStateClient;
    setClient: React.Dispatch<React.SetStateAction<UserContextStateClient>>;
}

export const UserContext = createContext<UserContextState>({
    // Auth0
    user: undefined,
    permissions: [],
    token: undefined,
    // nesto logged in user account
    account: undefined,
    setAccount: () => {},
    // nesto on behalf of client
    client: undefined,
    setClient: () => {},
});

import { Flex, Heading, Typography } from '@nestoca/ui';

import { useI18n } from 'providers/i18n/use-i18n';

export const Maintenance = () => {
    const { i18n } = useI18n();

    return (
        <main>
            <Flex
                direction="column"
                justify="center"
                align="center"
                style={{
                    height: '100vh',
                    padding: '0 1rem',
                }}
            >
                <Heading size={3} as="h2">
                    {i18n._('maintenance.title')}
                </Heading>
                <Typography>{i18n._('maintenance.content')}</Typography>
            </Flex>
        </main>
    );
};

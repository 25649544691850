import React from 'react';

import { Box } from 'reflexbox/styled-components';

import type { BoxProps } from 'reflexbox/styled-components';

type Props = {
    size?: number;
};

const SVG = ({ size = 24, width, height, ...rest }: Props & BoxProps) => (
    <Box
        as="svg"
        // eslint-disable-next-line
        // @ts-ignore
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? size + ''}
        height={height ?? size + ''}
        viewBox="0 0 24 24"
        fill="currentcolor"
        {...rest}
    />
);

export default SVG;

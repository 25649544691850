import { createContext } from 'react';

import { noOp } from 'utils';

import type { AccountRidContextState } from './account-rid-types';

export const AccountRidContext = createContext<AccountRidContextState>({
    state: { accountRid: undefined },
    dispatch: noOp,
});

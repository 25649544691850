import React from 'react';

import { I18nProvider as LinguiProvider } from '@lingui/react';
import { en, fr } from 'make-plural/plurals';
import qs from 'qs';

import { i18n } from 'libs/i18n';
import catalogs from 'locales/catalogs';

// Language import

import { setLangue } from './use-i18n';

const defaultLocale = 'en';

i18n.loadLocaleData('en', { plurals: en });
i18n.loadLocaleData('fr', { plurals: fr });
i18n.load({
    en: catalogs.en.messages,
    fr: catalogs.fr.messages,
});

const isServer = typeof window === 'undefined';

const lang =
    (!isServer && localStorage?.getItem('OfficeAuth:lang')) || defaultLocale;

const queries = qs.parse(!isServer ? window.location.search : '', {
    ignoreQueryPrefix: true,
});

setLangue(i18n, (queries?.lang as string) || lang);

export const I18nProvider = ({ children }: any) => (
    <LinguiProvider i18n={i18n}>{children}</LinguiProvider>
);

import { useState } from 'react';

import { Banner, HStack, Button, Typography } from '@nestoca/ui';

import { useInterval } from 'utils/use-interval';

// eslint-disable-next-line no-magic-numbers
const ONE_MINUTE = 60 * 1000;

export const PromptUpdater = () => {
    const [isOpen, setIsOpen] = useState(false);

    useInterval(async () => {
        try {
            const resonse = await fetch('/napi/version');

            const { buildId } = await resonse.json();

            if (
                buildId &&
                process.env.BUILD_ID &&
                buildId !== process.env.BUILD_ID
            ) {
                // There's a new version deployed that we need to load
                setIsOpen(true);
            }
        } catch (error) {
            // We don't care about errors here, we just want to check if there's a
            // new version
            return;
        }
    }, ONE_MINUTE);

    const onConfirmReload = () => {
        window.location.reload();
    };

    return isOpen ? (
        <Banner>
            <HStack justify="between" gap={6}>
                <Typography>
                    Hey, a new version is available! Please click below to
                    update.
                </Typography>

                <HStack>
                    <Button variant="secondary" onClick={onConfirmReload}>
                        Reload and update
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => setIsOpen(false)}
                    >
                        Cancel
                    </Button>
                </HStack>
            </HStack>
        </Banner>
    ) : null;
};
